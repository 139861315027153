import React, { useState } from "react";
import { Row } from "antd";
import { IDropdownFilterProps, PayrollPeriodParams } from "./type";
import text from "text";
import _ from "lodash";
import { Employees, LineManagerList, PayElementList, ProcessPeriodList } from "models/otherInput.data";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";

export default (props: IDropdownFilterProps) => {
  const {
    dataList,
    getSelectedLineManager,
    getSelectedInputType,
    getSelectedProcess,
    getSelectedAssociate,
    isLineManagerEnabled,
    selectedValues,
  } = props;

  const [isLineManangerSelected, setIsLineManagerSelected] = useState(true);
  const [selectedLineManager, setSelectedLineManager] = useState("");

  const handleLineManagerSelect = (data: any) => {
    getSelectedLineManager(data ? data.lineManagerCode : "");
    setIsLineManagerSelected(data ? false : true);
    setSelectedLineManager(data ? data.lineManagerCode : "");
  };

  const handleInputTypeSelectValue = (data: any) => {
    getSelectedInputType(data.payElementCode);
  };

  const handleProcessSelect = (data: any) => {
    const params: PayrollPeriodParams = {
      periodCode: data.processPeriodDescription,
      periodNumber: Number(data.processPeriodNumber),
      calendarCode: data.payrollCalendarCode,
    };
    getSelectedProcess(params);
  };

  const getEmployeeList = () => {
    const index = selectedLineManager !== ""
      ? _.findIndex(
          dataList?.LineManagerList,
          item => item.lineManagerCode === selectedLineManager,
        )
      : _.findIndex(
          dataList?.LineManagerList,
          item => item.EmployeeList?.length !== 0
        );
    if (index !== -1) return dataList?.LineManagerList[index].EmployeeList;
  };

  const handleAssociateManagerSelect = (data: Employees[]) => {
    const associateList = getList(data, "employeeCode");
    getSelectedAssociate(associateList);
  };

  function getList<T>(objectList: T[] | undefined, key: string) {
    /**
     * takes object list, ex => objectList = [{name: "name1"}, {name: "name2"}]
     * takes key, ex => key = "name"
     * returns list, ex => returnValue = ["name1", "name2"]
     */
    const returnList: string[] = [];
    //@ts-ignore
    objectList?.forEach((item: T) => returnList.push(item[key] ? item[key] : item));
    return returnList;
  }

  return (
    <Row
      type="flex"
      className="py-4 py-md-2"
      align="middle"
      justify="space-between">
      {!isLineManagerEnabled && (
        <SearchableDropdown<LineManagerList>
          list={dataList?.LineManagerList ? dataList?.LineManagerList : []}
          onSelect={(i) => handleLineManagerSelect(i)}
          label={text.CHOOSE_LINE_MANAGER}
          optionKeyExtractor={(item) => item.lineManagerCode}
          optionLableExtractor={(item) => item.lineManagerName}
          value={selectedValues.lineManagerName}
          allowClear
        />
      )}
      <SearchableDropdown<PayElementList>
        list={dataList?.PayElementList ? dataList?.PayElementList : []}
        onSelect={handleInputTypeSelectValue}
        label={text.INPUT_TYPE}
        optionKeyExtractor={(item) => item.payElementCode}
        optionLableExtractor={(item) => item.payElementDescription}
        value={selectedValues.payElementCode}
      />
      <SearchableDropdown<ProcessPeriodList>
        list={dataList?.ProcessPeriodList ? dataList?.ProcessPeriodList : []}
        onSelect={handleProcessSelect}
        label={text.PROCESS_PERIOD}
        optionKeyExtractor={(item) => item.processPeriodCode}
        optionLableExtractor={(item) => item.processPeriodDescription}
        value={selectedValues.processPeriodCode}
      />
      <SearchableDropdown<Employees>
        list={getEmployeeList()}
        onSelect={(i) => handleAssociateManagerSelect(i as Employees[])}
        label={text.ASSOCIATE_NAME}
        optionKeyExtractor={(item) => item.employeeCode}
        optionLableExtractor={(item) => item.employeeName}
        value={getList<string>(selectedValues.associateList, "employeeCode")}
        isMultiple
        allowClear
        isDisabled={isLineManangerSelected && !isLineManagerEnabled}
      />
    </Row>
  );
};
