import React, { useState } from "react";
import { ILineManagerFilterProps } from "./type";
import { Row, Col, DatePicker, Button } from "antd";
import text from "text";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import { ExistingLineManagerList, NewLineManagerList } from "models/lineManager.data";
import moment, { Moment } from "moment";
import { dateCodes } from "date";

export default function LineManagerFilter(
  props: ILineManagerFilterProps,
) {
  const {
    list,
    onExistingLineManagerChange,
    onNewLineManagerChange,
    onEffectiveFromDateChange,
    selectedValues,
    onSubmit,
    isLoading,
    selectedRecordLength,
  } = props;

  const [existingLineManager, setExisitingLineManager] = useState(selectedValues.existingLineManager);

  const selectEffectiveFromDate = (value: Moment | null, dateString: string) => {
    onEffectiveFromDateChange(value?.format(dateCodes.TO_ISO_8601) || "");
  };

  const disableDates = (date: Moment | null) => {
    if (date && date.isBefore(moment(), "month"))
      return true;
    return false;
  };

  const handleExistingLineManagerChange = (i: ExistingLineManagerList) => {
    setExisitingLineManager(i.existingLineManagerCode || "");
    onExistingLineManagerChange(i as ExistingLineManagerList);
  };

  const getNewLineManagerList = () => {
    return list?.newLineManagerList?.filter(data => data.newLineManagerCode !== existingLineManager);
  };

  return (
    <Row type="flex" className="my-2">
      <Col span={24}>
        <Row type="flex" justify="space-between" align="middle">
          <SearchableDropdown<ExistingLineManagerList>
            label={text.EXISTING_LINE_MANAGER}
            list={list?.existingLineManagerList || []}
            onSelect={(i) => handleExistingLineManagerChange(i as ExistingLineManagerList)}
            optionLableExtractor={(item) => item.existingLineManagerCode}
            optionKeyExtractor={(item) => item.existingLineManagerName}
            value={selectedValues.existingLineManager}
          />
          <SearchableDropdown<NewLineManagerList>
            label={text.TRANSFER_TO}
            list={getNewLineManagerList() || []}
            onSelect={(i) => onNewLineManagerChange(i as NewLineManagerList)}
            optionLableExtractor={(item) => item.newLineManagerCode}
            optionKeyExtractor={(item) => item.newLineManagerName}
            value={selectedValues.newLineManager}
            isDisabled={!selectedValues.existingLineManager}
          />
          <Row type="flex" align="middle">
            <div>{text.EFFECTIVE_FROM} :</div>
            <DatePicker
              getCalendarContainer={(t: any) => t.parentNode as HTMLElement}
              value={selectedValues.effectiveFromDate ? moment(selectedValues.effectiveFromDate) : undefined}
              style={{width: 200}}
              className="ml-2"
              onChange={selectEffectiveFromDate}
              disabled={!selectedValues.existingLineManager}
              allowClear={false}
              format={"DD-MM-YYYY"}
              disabledDate={disableDates}
            />
        </Row>
        <Button
          className={
            !(selectedRecordLength > 0)
              ? "px-5 fw-bold"
              : "c-white px-5 fw-bold bg-info"
          }
          onClick={onSubmit}
          disabled={!(selectedRecordLength > 0)}
          loading={isLoading}
        >
          {text.UPDATE}
        </Button>
      </Row>
    </Col>
    </Row>
  );
}
