import { OnboardingMetaDataDetails } from "models/analytics.data";
import text from "text";
import { ModalTableData } from "screens/analytics/type";
import moment from "moment";
import { dateCodes } from "date";
import Associate from "screens/associate/Associate";
import { ModuleType } from "screens/home/store/home/constants";

export function column(tableColumnData: OnboardingMetaDataDetails) {
  const dataColumns: ModalTableData[] = [];
  const sortedTableHeading = tableColumnData?.data.apiFieldList.sort((a, b) => {
    return a.apiParameterSequence - b.apiParameterSequence;
  });
  sortedTableHeading?.map(item => {
    dataColumns.push({
      title: item.apiParameterCaption,
      key: item.apiParameterName,
      dataIndex: item.apiParameterName,
      render: (value: string | number) => {
        if (item.parameterDataType === "date") {
          value = moment(value).format(dateCodes.DATE_MONTH_YEAR);
        }
        return value || text.NOT_APPLICABLE;
      },
      parser: (value: string | number) => {
        if (item.parameterDataType === "date") {
          return moment(value).format(dateCodes.DATE_MONTH_YEAR);
        }
        return value as string;
      },
    });
  });
  return dataColumns;
}

export function showTitle(title: string, activities: { [key: string]: boolean }) {
  switch(title){
    case "Associate":
      return activities[ModuleType.EMPLOYEE_HEAD_COUNT_ANALYTICS] || activities[ModuleType.ASSOCIATE_EXIT_ANALYTICS] || activities[ModuleType.EMPLOYEE_ADDITIONS_ANALYTICS] ||activities[ModuleType.ASSOCIATE_CONTRACT_EXPIRY];
    case "Onboarding":
      return activities[ModuleType.ONBOARDING_EXPECTED_TO_JOIN] || activities[ModuleType.ONBOARDING_INITIATED] || activities[ModuleType.ONBOARDING_COMPLETED] || activities[ModuleType.ONBOARDING_JOINED] || activities[ModuleType.BGV];
    case text.PAYROLL_DETAILS:
      return activities[ModuleType.ATTENDANCE_RECEIPT_ANALYTICS] || activities[ModuleType.EMPLOYEE_PAYROLL_PROCESSING_ANALYTICS] || activities[ModuleType.NETPAY_ANALYTICS] || activities[ModuleType.EMPLOYEE_PAYOUT_ANALYTICS] || activities[ModuleType.INVOICE_ANALYTICS];
    case text.CONTRACT_DETAILS:
      return activities[ModuleType.CLIENT_CONTRACT] || activities[ModuleType.ASSOCIATE_CONTRACT_ANALYTICS] || activities[ModuleType.CLIENT_CLRA_STATUS];
    default:
       return true;
  }
}