import { Button, Result, Row, Tabs } from "antd";
import React from "react";
import { IPreviewDoc } from "./type";
import CustomPdf from "components/CustomPdf/CustomPdf";
import text from "text";
import { getPDFURI } from "common/Utils.tsx/Download";
import { ModalPopUp } from "components/Modal/ModalPopUp";
import { ModalType } from "components/Modal/type";
import { useState } from "react";
import { InvoiceDocumentList } from "models/invoices.data";
import { previewImage } from "AssetHelper";
import { renderDownloadModal } from "./helper";

function PreviewDocument(props: IPreviewDoc) {
  const { documentList } = props;
  const { TabPane } = Tabs;

  const [isDownloading, setIsDownloading] = useState(false);

  const downloadFile = async (document: InvoiceDocumentList) => {
    try {
      setIsDownloading(true);
      await getPDFURI(
        document.documentFileName || "",
        document.customTextOutputDL1 || "",
        ).then(response => {
          if (response !== 200) {
            ModalPopUp({
              type: ModalType.error,
              title: text.ALERT.toUpperCase(),
              description: text.NOT_ABLE_TO_DOWNLOAD,
            });
          }
        });
    } catch (err) {
    } finally {
      setIsDownloading(false);
    }
  };

  const renderTabContent = (item: InvoiceDocumentList, index: number) => {
    return (
      <TabPane
        tab={item.documentFileName || text.NOT_APPLICABLE}
        key={`${index}`}
        style={{textAlign: "center"}}
      >
        {item.documentFileName && item.documentFileName.includes(".pdf") ?
        <CustomPdf
            file={item.documentFileName || ""}
            relativePath={item.customTextOutputDL1 || ""}
        /> :
        <Result
          title={text.NO_PREVIEW_IS_AVAILABLE}
          icon={<img src={previewImage} />}
          extra={
            <Button
              type="primary"
              onClick={() => downloadFile(item)}
            >
              {text.CLICK_HERE_DOWNLOAD}
            </Button>
          }
        />
        }
      </TabPane>
    );
  };

  return (
    <>
    {renderDownloadModal(isDownloading)}
    <Row style={{height: "500px"}}>
      <Tabs defaultActiveKey="0">
        {documentList.map((item: InvoiceDocumentList, index: number) => {
          if (item.documentFileName)
            return renderTabContent(item, index);
        })}
      </Tabs>
    </Row>
    </>
  );
}

export default PreviewDocument;
