import {
  QualificationDetails,
  EmployeeFamilyMemberDetails,
  PersonalInformationDetails,
  getRequestProfileDetails,
  WorkExperienceDetails,
  ContactsDetails,
  UserDetails,
} from "../../../../models/profile.data";
import actionCreatorFactory from "typescript-fsa";
import { Error } from "store/Models";

const actionCreator = actionCreatorFactory("PROFILE");

export class ProfileActions {
  public static getQualifications = actionCreator.async<
    getRequestProfileDetails,
    QualificationDetails,
    Error
  >("GET_QUALIFICATIONS");

  public static getFamilyMembers = actionCreator.async<
    getRequestProfileDetails,
    EmployeeFamilyMemberDetails,
    Error
  >("GET_FAMILY_MEMBERS");

  public static getPersonalInformation = actionCreator.async<
    getRequestProfileDetails,
    PersonalInformationDetails,
    Error
  >("GET_PERSONAL_INFORMATION");

  public static getWorkExperience = actionCreator.async<
    getRequestProfileDetails,
    WorkExperienceDetails,
    Error
  >("GET_WORK_EXPERIENCE");

  public static getContacts = actionCreator.async<
    getRequestProfileDetails,
    ContactsDetails,
    Error
  >("GET_CONTACTS");

  public static getUser = actionCreator.async<void, UserDetails, Error>(
    "GET_USER",
  );
}
