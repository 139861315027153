import { put, takeEvery, takeLatest } from "redux-saga/effects";
import { AuthAction, AuthActions, AuthActionTypes } from "./AuthActions";
import AuthService from "../../service/AuthService";
import { AuthRequest, AuthResponse } from "../model/Auth.data";

function* login(payload: AuthRequest): IterableIterator<any> {
  try {
    const response: undefined | AuthResponse = yield AuthService.login(payload);
    if (response) {
      localStorage.setItem("authToken", (response as AuthResponse).token);
      localStorage.setItem("name", (response as AuthResponse).name);
      localStorage.setItem("username", (response as AuthResponse).username);
    }

    yield put(AuthActions.loginSuccess(response as any));
  } catch (error) {
    yield put(AuthActions.loginError(error));
  }
}

function* isValidUser() {
  try {
    // TODO: Right now , validating the user directly.
    yield put(
      AuthActions.checkUserValidity.done({
        params: undefined,
        result: undefined,
      }),
    );
    // const localToken: string | null = localStorage.getItem("authToken");
    // if (localToken && localToken.length > 0) {
    //   yield put(AuthActions.checkUserValidity.done({ params: undefined, result: undefined }));
    // } else {
    //   throw new Error("No user details found");
    // }
  } catch (error) {
    yield put(AuthActions.checkUserValidity.failed(error));
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("authToken");
    localStorage.removeItem("name");
    localStorage.removeItem("username");
    yield put(
      AuthActions.logoutUserAction.done({
        params: undefined,
        result: undefined,
      }),
    );
  } catch (error) {
    yield put(AuthActions.logoutUserAction.failed(error));
  }
}

function* authWatcher(): IterableIterator<any> {
  yield takeEvery(AuthActionTypes.LOGIN_REQUEST, (action: AuthAction) =>
    login(action.payload as AuthRequest),
  );
  yield takeLatest(AuthActions.checkUserValidity.started.type, isValidUser);
  yield takeLatest(AuthActions.logoutUserAction.started.type, logoutUser);
}

export default authWatcher;
