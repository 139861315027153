import {
  analyticsBGVImage,
  analyticsJoinedImage,
  analyticsOnboardingCompletedImage,
  analyticsOnboardingInitiatedImage,
  analyticsTotalExpectedToJoinImage,
  analyticsContractExpiredImage,
  analyticsCLRAImage,
  attendanceImage,
  invoiceImage,
  payrollProcessedImage,
  netpayImage,
  resignedImage,
  addedImage,
} from "../../AssetHelper";
import text from "text";
import { AnalyticsSectionDetails } from "./type";
import { EndPoints } from "store/model/ApiConfig.data";
import { ModuleType } from "screens/home/store/home/constants";
export const CLMGR_INTERNAL_ROLE = "CLMGR";

export enum AnalyticsApiCodeTypes {
  ANALYTICS_JOINED = "ONB_ANLY_CAND_JOIN_DET",
  ANALYTICS_ONBOARDING_INITIATED = "ONB_ANLY_ONB_INIT_DET",
  ANALYTICS_ONBOARDING_COMPLETED = "ONB_ANLY_ONB_COMP_DET",
  ANALYTICS_ONBOARDING_EXPECTED_TO_JOIN = "ONB_ANLY_NEW_JOIN_EXPC_DET",
  ANALYTICS_ONBOARDING_BGV_INPROGRESS = "ONB_ANLY_BGV_PROG_DET",
  ANALYTICS_ONBOARDING_BGV_COMPLETED = "ONB_ANLY_BGV_COMP_DET",
  ANALYTICS_CLIENT_CONTRACT_EXPIRY = "CLI_ANLY_CLIENT_CONTRACT_DET",
  ANALYTICS_CLIENT_CONTRACT_EXPIRED_NEXT_MONTH = "CLI_ANLY_ASSOC_CONT_EXP_DET",
  ANALYTICS_CLIENT_CLRA = "CLI_ANLY_CLRA_LIC_DET",
  ANALYTICS_PAYROLL_PROCESSED = "PYRL_ANLY_PAYROL_PROC_DET",
  ANALYTICS_PAYROLL_PAYOUTS = "PYRL_ANLY_EMP_PAYOUT_DET",
  ANALYTICS_PAYROLL_INVOICE = "INV_ANLY_INV_GEN_DET",
  ANALYTICS_PAYROLL_NETPAY = "INV_ANLY_NETPAY_GEN_DET",
  ANALYTICS_PAYROLL_ATTENDANCE = "TMSHT_ANLY_ATT_RECP_DET",
  ANALYTICS_ASSOCIATE_TOTAL_HEAD_COUNT = "EMP_ANLY_HEAD_COUNT_DET",
  ANALYTICS_ASSOCIATE_NEW_ADDITION = "EMP_ANLY_EMP_ADD_DET",
  ANALYTICS_ASSOCIATE_CONTRACT_EXPIRED = "EMP_ANLY_CON_EXP_DET",
  ANALYTICS_ASSOCIATE_RESIGNED = "SEP_ANLY_RESEXI_DET",
}

export const analyticsSectionDetails: AnalyticsSectionDetails[] = [
  {
    sectionTitle: text.ASSOCIATE_DETAILS,
    metrics: [
      {
        heading: text.TOTAL_HEAD_COUNTS,
        icon: attendanceImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ANALYTICS_ASSOCIATE_TOTAL_HEAD_COUNT_SUMMARY,
        tableEndpoint:
          EndPoints.GET_ANALYTICS_ASSOCIATE_TOTAL_HEAD_COUNT_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_ASSOCIATE_TOTAL_HEAD_COUNT,
        cardName: ModuleType.EMPLOYEE_HEAD_COUNT_ANALYTICS,
      },
      {
        heading: text.RESIGNED_EXITED,
        icon: resignedImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ANALYTICS_ASSOCIATE_RESIGNED_SUMMARY,
        tableEndpoint: EndPoints.GET_ANALYTICS_ASSOCIATE_RESIGNED_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_ASSOCIATE_RESIGNED,
        cardName: ModuleType.ASSOCIATE_EXIT_ANALYTICS,
      },
      {
        heading: text.ADDED,
        icon: addedImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ANALYTICS_ASSOCIATE_NEW_ADDITION_SUMMARY,
        tableEndpoint: EndPoints.GET_ANALYTICS_ASSOCIATE_NEW_ADDITION_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_ASSOCIATE_NEW_ADDITION,
        cardName: ModuleType.EMPLOYEE_ADDITIONS_ANALYTICS,
      },
      {
        heading: text.CONTRACT_EXPIRED,
        icon: analyticsContractExpiredImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ANALYTICS_ASSOCIATE_CONTRACT_EXPIRED_SUMMARY,
        tableEndpoint:
          EndPoints.GET_ANALYTICS_ASSOCIATE_CONTRACT_EXPIRED_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_ASSOCIATE_CONTRACT_EXPIRED,
        cardName: ModuleType.ASSOCIATE_CONTRACT_EXPIRY,
      },
    ],
  },
  {
    sectionTitle: text.ONBOARDING_DETAILS,
    metrics: [
      {
        heading: text.TOTAL_EXPECTED_TO_JOIN,
        icon: analyticsTotalExpectedToJoinImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ONBOARDING_ANALYTICS_EXPECTED_TO_JOIN_SUMMARY,
        tableEndpoint:
          EndPoints.GET_ONBOARDING_ANALYTICS_EXPECTED_TO_JOIN_TABLE_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_ONBOARDING_EXPECTED_TO_JOIN,
        cardName: ModuleType.ONBOARDING_EXPECTED_TO_JOIN,
      },
      {
        heading: text.ONBOARDING_INITIATED,
        icon: analyticsOnboardingInitiatedImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ONBOARDING_ANALYTICS_INITIATED_SUMMARY,
        tableEndpoint:
          EndPoints.GET_ONBOARDING_ANALYTICS_INITIATED_TABLE_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_ONBOARDING_INITIATED,
        cardName: ModuleType.ONBOARDING_INITIATED,
      },
      {
        heading: text.ONBOARDING_COMPLETED,
        icon: analyticsOnboardingCompletedImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ONBOARDING_ANALYTICS_COMPLETED_SUMMARY,
        tableEndpoint:
          EndPoints.GET_ONBOARDING_ANALYTICS_COMPLETED_TABLE_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_ONBOARDING_COMPLETED,
        cardName: ModuleType.ONBOARDING_COMPLETED,
      },
      {
        heading: text.JOINED,
        icon: analyticsJoinedImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ONBOARDING_ANALYTICS_JOINED_SUMMARY,
        tableEndpoint: EndPoints.GET_ONBOARDING_ANALYTICS_JOINED_TABLE_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_JOINED,
        cardName: ModuleType.ONBOARDING_JOINED,
      },
      {
        heading: text.BGV,
        icon: analyticsBGVImage,
        isMultiple: true,
        cardName: ModuleType.BGV,
        items: [
          {
            subTitle: text.IN_PROGRESS,
            endpoint: EndPoints.GET_ONBOARDING_ANALYTICS_PENDING_SUMMARY,
            tableEndpoint:
              EndPoints.GET_ONBOARDING_ANALYTICS_PENDING_CANDIDATE_DETAILS,
            apiCode: AnalyticsApiCodeTypes.ANALYTICS_ONBOARDING_BGV_INPROGRESS,
          },
          {
            subTitle: text.COMPLETED,
            endpoint: EndPoints.GET_ONBOARDING_ANALYTICS_AUTHORISED_SUMMARY,
            tableEndpoint:
              EndPoints.GET_ONBOARDING_ANALYTICS_AUTHORISED_CANDIDATE_DETAILS,
            apiCode: AnalyticsApiCodeTypes.ANALYTICS_ONBOARDING_BGV_COMPLETED,
          },
        ],
      },
    ],
  },
  {
    sectionTitle: text.PAYROLL_DETAILS,
    metrics: [
      {
        heading: text.ATTENDANCE_RECEIVED,
        icon: attendanceImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ANALYTICS_ATTENDANCE_SUMMARY,
        tableEndpoint: EndPoints.GET_ANALYTICS_ATTENDANCE_TABLE_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_PAYROLL_ATTENDANCE,
        cardName: ModuleType.ATTENDANCE_RECEIPT_ANALYTICS,
      },
      {
        heading: text.PAYROLL_PROCESSED,
        icon:payrollProcessedImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ANALYTICS_PAYROLL_PROCESSED_SUMMARY,
        tableEndpoint: EndPoints.GET_ANALYTICS_PAYROLL_PROCESSED_TABLE_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_PAYROLL_ATTENDANCE,
        cardName: ModuleType.EMPLOYEE_PAYROLL_PROCESSING_ANALYTICS
      },
      {
        heading: text.NET_PAY_GENERATED,
        icon: netpayImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ANALYTICS_NET_PAY_SUMMARY,
        tableEndpoint: EndPoints.GET_ANALYTICS_NET_PAY_TABLE_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_PAYROLL_NETPAY,
        cardName: ModuleType.NETPAY_ANALYTICS,
      },
      {
        heading: text.PAYOUTS_COMPLETED,
        icon: invoiceImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ANALYTICS_PAYOUT_SUMMARY,
        tableEndpoint: EndPoints.GET_ANALYTICS_PAYOUT_TABLE_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_PAYROLL_PAYOUTS,
        cardName: ModuleType.EMPLOYEE_PAYOUT_ANALYTICS,
      },
      {
        heading: text.INVOICE_GENERATED,
        icon: analyticsContractExpiredImage,
        isMultiple: false,
        endpoint: EndPoints.GET_ANALYTICS_INVOICE_SUMMARY,
        tableEndpoint: EndPoints.GET_ANALYTICS_INVOICE_TABLE_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_PAYROLL_INVOICE,
        cardName: ModuleType.INVOICE_ANALYTICS,
      },
    ],
  },
  {
    sectionTitle: text.CONTRACT_DETAILS,
    metrics: [
      {
        heading: text.CLIENT_CONTRACT,
        icon: analyticsContractExpiredImage,
        isMultiple: false,
        endpoint: EndPoints.GET_CLIENT_ANALYTICS_CONTRACT_EXPIRED_SUMMARY,
        tableEndpoint:
          EndPoints.GET_CLIENT_ANALYTICS_CONTRACT_EXPIRED_TABLE_DETAILS,
        apiCode: AnalyticsApiCodeTypes.ANALYTICS_CLIENT_CONTRACT_EXPIRY,
        desc: text.GETTING_EXPIRED,
        cardName: ModuleType.CLIENT_CONTRACT,
      },
      {
        heading: text.ASSOCIATE_CONTRACT,
        icon: analyticsContractExpiredImage,
        isMultiple: false,
        endpoint:
          EndPoints.GET_CLIENT_ANALYTICS_CONTRACT_EXPIRED_NEXT_MONTH_SUMMARY,
        tableEndpoint:
          EndPoints.GET_CLIENT_ANALYTICS_CONTRACT_EXPIRED_NEXT_MONTH_TABLE_DETAILS,
        apiCode:
          AnalyticsApiCodeTypes.ANALYTICS_CLIENT_CONTRACT_EXPIRED_NEXT_MONTH,
        desc: text.GETTING_EXPIRED,
        cardName: ModuleType.ASSOCIATE_CONTRACT_ANALYTICS,
      },
      {
        heading: text.CLIENT_CLRA_STATUS,
        icon: analyticsCLRAImage,
        isMultiple: true,
        cardName: ModuleType.CLIENT_CLRA_STATUS,
        items: [
          {
            subTitle: text.VALID,
            endpoint: EndPoints.GET_CLIENT_ANALYTICS_CLRA_NOT_AVAILABLE_SUMMARY,
            tableEndpoint:
              EndPoints.GET_CLIENT_ANALYTICS_CLRA_NOT_AVAILABLE_TABLE_DETAILS,
            apiCode: AnalyticsApiCodeTypes.ANALYTICS_CLIENT_CLRA,
            clraStatusCode: text.VAL,
          },
          {
            subTitle: text.INVALID,
            endpoint: EndPoints.GET_CLIENT_ANALYTICS_CLRA_NOT_AVAILABLE_SUMMARY,
            tableEndpoint:
              EndPoints.GET_CLIENT_ANALYTICS_CLRA_NOT_AVAILABLE_TABLE_DETAILS,
            apiCode: AnalyticsApiCodeTypes.ANALYTICS_CLIENT_CLRA,
            clraStatusCode: text.INVAL,
          },
        ],
      },
    ],
  }
];
