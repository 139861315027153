import { put, takeLatest } from "redux-saga/effects";
import { DashboardActions } from "./actions";

import dashboardService from "./service";
import { SummaryResponse } from "models/dashboard.data";

function* getLeaveSummary(action: any) {
  const { payload } = action;
  try {
    const response: SummaryResponse = yield dashboardService.getLeavesSummary(
      payload,
    );

    yield put(
      DashboardActions.getLeaveSummary.done({
        params: payload,
        result: response,
      }),
    );
  } catch (e) {
    yield put(
      DashboardActions.getLeaveSummary.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* getTimesheetSummary(action: any) {
  const { payload } = action;
  try {
    const response: SummaryResponse = yield dashboardService.getTimesheetSummary(
      payload,
    );

    yield put(
      DashboardActions.getTimesheetSummary.done({
        params: payload,
        result: response,
      }),
    );
  } catch (e) {
    yield put(
      DashboardActions.getTimesheetSummary.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* getReimbursementSummary(action: any) {
  const { payload } = action;
  try {
    const response: SummaryResponse = yield dashboardService.getReimbursementSummary(
      payload,
    );
    yield put(
      DashboardActions.getReimbursementSummary.done({
        params: payload,
        result: response,
      }),
    );
  } catch (e) {
    yield put(
      DashboardActions.getReimbursementSummary.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* DashboardManagementSaga() {
  yield takeLatest(DashboardActions.getLeaveSummary.started, getLeaveSummary);
  yield takeLatest(
    DashboardActions.getTimesheetSummary.started,
    getTimesheetSummary,
  );
  yield takeLatest(
    DashboardActions.getReimbursementSummary.started,
    getReimbursementSummary,
  );
}

export default DashboardManagementSaga;
