import React, { useState } from 'react'
import { InvoiceDocumentList } from 'models/invoices.data';
import { DocumentListProps } from './type';
import { Button, Row } from 'antd';
import { PDFImage } from 'AssetHelper';
import text from 'text';
import { getPDFURI } from 'common/Utils.tsx/Download';
import { ModalPopUp } from 'components/Modal/ModalPopUp';
import { ModalType } from 'components/Modal/type';
import { renderDownloadModal } from './helper';

function DocumentList(props: DocumentListProps) {

  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const downloadFile = async (document: InvoiceDocumentList) => {
    try {
      if(!document.documentFileName) {
        ModalPopUp({
          type: ModalType.error,
          title: text.ALERT.toUpperCase(),
          description: text.NO_FILE,
        });
        return;
      }
      setShowLoadingModal(true);
      await getPDFURI(
        document.documentFileName as string,
        document.customTextOutputDL1 as string,
      ).then(response => {
        if (response !== 200) {
          ModalPopUp({
            type: ModalType.error,
            title: text.ALERT.toUpperCase(),
            description: text.NOT_ABLE_TO_DOWNLOAD,
          });
        }
      });
    } catch (err) {
    } finally {
      setShowLoadingModal(false);
    }
  };

  return (
    <>
      {renderDownloadModal(showLoadingModal)}
      <Row type="flex">
        {props.data.length ? props.data.map((item, index) => (
          <Row
            key={index}
            style={{wordBreak:"break-word"}}
            onClick={() => downloadFile(item)}
            className="c-info mr-3 fw-100 cursor-pointer"
          >
            <img src={PDFImage} className="h-5 mr-1" />
            {item.documentFileName || text.NOT_APPLICABLE}
          </Row>
        )) : (
          <span className="c-primary">{text.COUDENT_LOAD_IMAGES}</span>
        )}
      </Row>
    </>
  );
}

export default DocumentList;
