import React from "react";
import { Modal } from "antd";
import { ModalType } from "./type";

export const ModalPopUp = (props: {
  type: string;
  title?: string;
  description: any;
  onSubmit?: (value: string, data:any) => void;
  value?: string;
  data?:any;
  showIcon?: boolean
}) => {
  const { type, title, description, onSubmit, value, data } = props;
  switch (type) {
    case ModalType.info:
      Modal.info({
        icon: props.showIcon,
        title,
        content: <div>{description}</div>,
        onOk() {
          if (onSubmit && value !== undefined && data !== undefined)
            onSubmit(value, data);
        },
      });
      break;
    case ModalType.success:
      Modal.success({
        title,
        content: <div>{description}</div>,
        onOk() {
          if (onSubmit && value !== undefined && data !== undefined)
            onSubmit(value, data);
        },
      });
      break;
    case ModalType.error:
      Modal.error({
        title,
        content: <div>{description}</div>,
        onOk() {
          if (onSubmit && value !== undefined && data !== undefined)
            onSubmit(value, data);
        },
      });
      break;
    case ModalType.warning:
      Modal.warning({
        title,
        content: <div>{description}</div>,
        onOk() {
          if (onSubmit && value !== undefined && data !== undefined)
            onSubmit(value, data);
        },
      });
      break;
    case ModalType.confirm:
      Modal.confirm({
        title,
        content: <div>{description}</div>,
        onOk() {
          if (onSubmit && value !== undefined && data !== undefined)
            onSubmit(value, data);
        },
      });
      break;
    default:
      Modal.info({
        title,
        content: <div>{description}</div>,
        onOk() {
          if (onSubmit && value !== undefined && data !== undefined)
            onSubmit(value, data);
        },
      });
  }
};
