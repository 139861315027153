import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import {
  PersonalInformationDetails,
  getRequestProfileDetails,
} from "models/profile.data";

export class PersonalInformationService {
  public getPersonalInformation(
    requestParams: getRequestProfileDetails,
  ): Promise<PersonalInformationDetails> | PersonalInformationDetails {
    const service: ApiService = new ApiService(
      EndPoints.profilePersonalInformation,
    );
    return service.get({
      dynamicQueryParams: [{ employeeCode: requestParams.employeeCode }],
    });
  }
}

const personalInformationService = new PersonalInformationService();
export default personalInformationService;
