import { reducerWithInitialState } from "typescript-fsa-reducers";

import { ContextActions } from "./actions";
import {
  UserContextData,
  ContextType,
  UserDetails,
} from "../../../../models/userGontext.data";
import { RelativePathResponse } from "models/relativePath.data";
import { defaultAllowedActivities } from "./constants";

const initialState: ContextType = {
  loading: false,
  error: null,

  contextDetails: {
    data: undefined,
    message: "",
  },
  userDetails: {
    data: [],
    message: "",
  },
  relativePath: {
    data: [],
    message: "",
  },
  allowedActivities: { ...defaultAllowedActivities },
};

const contextReducer = reducerWithInitialState(initialState)
  .cases(
    [
      ContextActions.getContextDetails.started,
      ContextActions.getUserDetails.started,
      ContextActions.fetchCandidateRelativePathList.started,
    ],
    (state: ContextType) => ({
      ...state,
      error: null,
      loading: true,
    }),
  )
  .case(
    ContextActions.getContextDetails.done,
    (state: ContextType, payload: { result: UserContextData }) => ({
      ...state,
      error: null,
      loading: false,
      contextDetails: payload.result,
    }),
  )
  .case(
    ContextActions.fetchCandidateRelativePathList.done,
    (state: ContextType, payload: { result: RelativePathResponse }) => {
      return {
        ...state,
        error: null,
        loading: false,
        relativePath: payload.result,
      };
    },
  )
  .case(
    ContextActions.fetchCandidateRelativePathList.failed,
    (state: ContextType, payload: any) => ({
      ...state,
      error: payload,
      loading: false,
    }),
  )
  .case(
    ContextActions.getUserDetails.done,
    (state: ContextType, payload: { result: UserDetails }) => {
      return {
        ...state,
        error: null,
        loading: false,
        userDetails: payload.result,
      };
    },
  )
  .case(
    ContextActions.getUserDetails.failed,
    (state: ContextType, payload: any) => ({
      ...state,
      error: payload,
      loading: false,
    })
  )
  .case(
    ContextActions.fetchUserActivities.done,
    (state: ContextType, payload: any) => ({
      ...state,
      allowedActivities: payload,
    })
  )
  .default(state => {
    return state;
  });

export default contextReducer;
