import actionCreatorFactory from "typescript-fsa";
import { Error } from "store/Models";
import {
  AssociatesReimbursementResponse,
  ApproveExpense,
} from "models/reimbursement.data";
import { ExpenseFilterInterface } from "screens/reimbursement/type";

const actionCreator = actionCreatorFactory("REIMBURSEMENT");

export class ReimbursementActions {
  public static getAssociatesReimbursementDetails = actionCreator.async<
    ExpenseFilterInterface,
    AssociatesReimbursementResponse,
    Error
  >("GET_ASSOCIATES_Reimburement_DETAILS");
}
