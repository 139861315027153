import { Modal, Tabs,Row } from 'antd'
import React from 'react'
import CustomImage from './CustomImage/CustomImage';
const { TabPane } = Tabs;

function ImageModal(props: {
  images: {
    title: string;
    relativePath: string;
    imageName: string;
  }[];
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}) {

  const imageModalOnCancel = (e: any) => {
    props.setIsModalOpen(false)
  }

  return (
    <Modal visible={props.isModalOpen} footer={null} onCancel={imageModalOnCancel}>
        <Row style={{height:"500px"}}>
        <Tabs defaultActiveKey="0">
          {props.images.map((item, index) => (
            <TabPane tab={item.title} key={`${index}`}>
              <CustomImage
                file={item.imageName}
                relativePath={item.relativePath}
                width="100%"
                hideDefaultImage
              />
            </TabPane>
          ))}
        </Tabs>
        </Row>
      </Modal>
  )
}

export default ImageModal
