import React, { useState } from "react";
import { Modal, Input } from "antd";

interface CollectionCreateFormProps {
  visible: boolean;
  onReject: (values: string, data:any, reason: string) => void;
  onCancel: () => void;
  data: any;
  value: string;
}

export const RejectPopUp: React.FC<CollectionCreateFormProps> = ({
  visible,
  onReject,
  onCancel,
  data,
  value,
}) => {
  const [reason, setReason] = useState<string>("");
  return (
    <Modal
      destroyOnClose={true}
      visible={visible}
      okText="Reject"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={() => {
        onReject(value, data, reason)}}
      maskClosable={false}
    >
      <Input
        className="mt-8"
        type="text"
        onChange={e => {
          setReason(e.target.value);
        }}
        placeholder="Reason for rejection"
      />
    </Modal>
  );
};
