import React from "react";
import { Row, Col } from "antd";
import AssociateCard from "../AssociateCard/AssociateCard";
import { Reportees } from "../../models/associate.data";
import { Empty } from "antd";

export interface AssociateList {
  name: string;
  designation: string;
  phn_no: string;
}

export default function AssociateList(Props: {
  associateList: Reportees[];
  gridView: boolean;
}) {
  const { associateList, gridView } = Props;
  const spanMd = gridView ? 12 : 24;
  const spanLg = gridView ? 8 : 24;
  return (
    <Row type="flex" gutter={[8, 8]} className="justify-content-left">
      {associateList.length ? (
        associateList.map((item, index) => (
          <Col
            key={item.id}
            xs={{ span: 24 }}
            md={{ span: spanMd }}
            lg={{ span: spanLg }}>
            <AssociateCard cardData={item} gridView={gridView} />
          </Col>
        ))
      ) : (
          <Row className="bg-white w-100"><Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/></Row>
      )}
    </Row>
  );
}
