import React, { useEffect, useState } from "react";
import { Row, Tabs } from "antd";
import { style } from "styles/Fonts";
import { associateImage } from "AssetHelper";
import PersonalInfo from "screens/profile/components/ProfileDetails/PersonalInfo";
import ProfessionalInfo from "screens/profile/components/ProfileDetails/ProfessionalInfo";
import { getRequestProfileDetails } from "models/profile.data";
import { useSelector, useDispatch } from "react-redux";
import { ProfileActions } from "screens/profile/store/profile/actions";

export default function AssociateDetails() {
  const dispatch = useDispatch();

  const { TabPane } = Tabs;

  function callback(key: any) {
    // console.log(key);
  }

  const employeeId: string = useSelector((store: any) => {
    return (
      store.profileStore.user.data.length &&
      store.profileStore.user.data[0].Employeecode
    );
  });

  useEffect(() => {
    dispatch(ProfileActions.getUser.started());
  }, [dispatch]);

  useEffect(() => {
    if (employeeId) {
      const requestData: getRequestProfileDetails = {
        employeeCode: employeeId,
      };
      dispatch(ProfileActions.getPersonalInformation.started(requestData));
      dispatch(ProfileActions.getContacts.started(requestData));
      // dispatch(ProfileActions.getFamilyMembers.started(requestData));
      // dispatch(ProfileActions.getQualifications.started(requestData));
      // dispatch(ProfileActions.getWorkExperience.started(requestData));
    }
  }, [dispatch, employeeId]);

  return (
    <Row className="p-6" id="associate-details">
      <Row className="pb-2" id="heading">
        <img src={associateImage} className="h-6" alt="associate-icon" />
        &nbsp;<span style={style.large}> Associate Details</span>
      </Row>
      <Tabs defaultActiveKey="1" onChange={callback}>
        <TabPane tab="Personal Info" key="1">
          <PersonalInfo />
        </TabPane>
        {/* <TabPane tab="Professional Info" key="2">
                    <ProfessionalInfo />
                </TabPane> */}
      </Tabs>
    </Row>
  );
}
