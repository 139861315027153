import { Row, Input } from "antd";
import React from "react";
import text from "text";
import { TableData } from "./type";
import {
  GetPayrollResponse,
  GetEmployeeValueList,
  EmployeeValueList,
} from "models/otherInput.data";
import {
  actionTypeCodes,
  CREATE,
  EDIT,
  NOT_ALLOWED_TO_SUBMIT,
} from "./constants";
import UploadDoc from "components/UploadDoc/UploadDoc";
import { uploadFileIcon } from "AssetHelper";

export function column(
  onValueChange: (value: string, row: TableData) => void,
  onRemarksChange: (value: string, row: TableData) => void,
  onDocumentUpload: (value: any, row: TableData) => void,
  downloadFile: (file: string) => void,
  actionType: string,
) {
  const fixed = window.innerWidth > 600 ? "left" : "";
  const dataColumns = [];
  dataColumns.push({
    title: text.EMPLOYEE_CODE,
    key: "employeeCode",
    dataIndex: "employeeCode",
    fixed,
  });
  dataColumns.push({
    title: text.EMPLOYEE_NAME,
    key: "employeeName",
    dataIndex: "employeeName",
    fixed,
  });
  dataColumns.push({
    title: text.VALUE,
    key: "value",
    dataIndex: "value",
    render: (value: number, row: TableData, index: number) => {
      return (
        <Input
          disabled={
            row.isAllowedToSubmit === NOT_ALLOWED_TO_SUBMIT ? true : false
          }
          value={value ? value : undefined}
          maxLength={15}
          onChange={e => {
            const reg = /^-?\d*(\.\d*)?$/;
            if (!isNaN(Number(e.target.value)) && reg.test(e.target.value)) {
              onValueChange(e.target.value, row);
            }
          }}
        />
      );
    },
  });
  if (actionType === actionTypeCodes.ADD_NEW || actionType === actionTypeCodes.REQUESTED) {
    dataColumns.push({
      title: text.DOCUMENT_ATTACHMENT,
      key: "documentAttachment",
      dataIndex: "documentAttachment",
      render: (documentAttachment: number, row: TableData, index: number) => {
        const uploadDocument = (file: any) => {
          onDocumentUpload(file, row);
        };
        return (
          <UploadDoc
            onUpload={uploadDocument}
            lable={text.UPLOAD_FILE}
            type={text.FILE}
            icon={uploadFileIcon}
            iconSize={"3"}
            isDisable={
              row.isAllowedToSubmit === NOT_ALLOWED_TO_SUBMIT ? true : false
            }
          />
        );
      },
    });
  }
  dataColumns.push({
    title: text.DOWNLOAD_ATTACHMENT,
    key: "file",
    dataIndex: "file",
    render: (file: string, row: TableData, index: number) => {
      return (
        <Row type="flex" justify="start">
          {!file ? (
            text.NOT_AVAILABLE
          ) : (
            <a
              className="c-info bg-white"
              style={{ textDecoration: "underline" }}
              onClick={() => downloadFile(file)}>
              {text.DOWNLOAD}
            </a>
          )}
        </Row>
      );
    },
  });
  dataColumns.push({
    title: text.REMARKS,
    key: "remarks",
    dataIndex: "remarks",
    render: (remarks: string, row: TableData, index: number) => {
      return (
        <Input
          value={remarks}
          disabled={
            row.isAllowedToSubmit === NOT_ALLOWED_TO_SUBMIT ? true : false
          }
          onChange={e => {
            onRemarksChange(e.target.value, row);
          }}
        />
      );
    },
  });
  return dataColumns;
}

export const getManipulatedData = (data: GetPayrollResponse) => {
  const updateTableData: TableData[] = [];
  data?.data.EmployeeValueList
  .map(val =>
    updateTableData.push({
      sNo: val.slNo as number,
      employeeCode: val.employeeCode as string,
      employeeName: val.employeeName as string,
      value: val.payElementValue as number,
      documentAttachment: val.attachmentFileName as string,
      remarks: val.valueRemarks as string,
      file: val.attachmentFileName as string,
      isAllowedToSubmit: val.customEVLTextOutput1 as string,
    }),
  );
  return updateTableData;
};

export const getBadgeValue = (
  data: GetPayrollResponse,
  propertyName: string,
) => {
  switch (propertyName) {
    case text.INVOICED_COUNT:
      return `( ${data.data.invoicedCount} )`;
    case text.PROCESSED_COUNT:
      return `( ${data.data.processedCount} )`;
    case text.REQUESTED_COUNT:
      return `( ${data.data.requestedCount} )`;
    default:
      return "";
  }
};

export const getPostRequestParams = (
  selectedRowKey: string[],
  actionType: string,
  payrollList: GetPayrollResponse,
) => {
  const employeeList: GetEmployeeValueList[] = [];
  payrollList.data.EmployeeValueList.forEach(row => {
    selectedRowKey.filter(
      data => data === row.employeeCode || "",
    ).forEach(item => {
      const employeeObj: EmployeeValueList = {
        employeeCode: row.employeeCode,
        payElementValue: row.payElementValue,
        recordLevelAction: actionType === actionTypeCodes.ADD_NEW ? CREATE : EDIT,
        valueRemarks: row.valueRemarks,
        attachmentFileName: row.attachmentFileName,
      };
      employeeList.push(employeeObj);
    });
  });
  return employeeList;
};

export const getActionType = (activeKey: string) => {
  switch (activeKey) {
    case "1":
      return actionTypeCodes.ADD_NEW;
    case "2":
      return actionTypeCodes.REQUESTED;
    case "3":
      return actionTypeCodes.PROCESSED;
    default:
      return actionTypeCodes.INVOICED;
  }
};

export const getKeyOfActionType = (activeKey: string) => {
  switch (activeKey) {
    case actionTypeCodes.ADD_NEW:
      return "1";
    case actionTypeCodes.REQUESTED:
      return "2";
    case actionTypeCodes.PROCESSED:
      return "3";
    case actionTypeCodes.INVOICED:
      return "4";
    default:
      return "1";
  }
};

export const columnForExcel = () => {
  const dataColumns = [
    {
      key: "Emp Code",
      dataIndex: "employeeCode",
    },
    {
      key: "Emp Name",
      dataIndex: "employeeName",
    },
    {
      key: "Value",
      dataIndex: "payElementValue",
    },
    {
      key: "Document Attachment",
      dataIndex: "attachmentFileName",
    },
    {
      key: "Remarks",
      dataIndex: "valueRemarks",
    },
  ];
  return dataColumns;
};

export const getErrorMessage = (code: string, msg: string) => {
  switch (code) {
    case "500":
      return text.ERROR_MESSAGE_FOR_500;
    case "401":
      return text.ERROR_MESSAGE_FOR_401;
    default:
      return msg;
  }
};

export const getDuplicateRecords = (value: any) => {
  const employeeCodes : string[] = [];
  const uniqueRecords = new Map<string, number>();
  value.map((item: any) => {
    if(!uniqueRecords.has(item.employeeCode)){
      uniqueRecords.set(item.employeeCode, 1);
    } else {
      employeeCodes.push(item.employeeCode);
    }
  });
  return employeeCodes;
};

export const getUpdatedRecords = (value: any) => {
  return value.filter((item: any) =>
    item.employeeCode ||
    item.employeeName ||
    item.attachmentFileName ||
    item.valueRemarks,
  );
}