import {
  UserContextData,
  UserDetails,
  Ramco20usersuseruserdef,
} from "../../../../models/userGontext.data";
import { ContextActions } from "./actions";
import contextService from "./service";
import userService from "./userService";
import { put, takeLatest } from "redux-saga/effects";
import { RelativePathResponse } from "models/relativePath.data";
import { Action } from "typescript-fsa";
import { UserActivity, FeaturePermissionRight } from "models/userActivity.data";
import { createRoleMapping, createRoleMappingForFeaturePermission } from "./helper";

function* getContextData(action: any) {
  const { payload } = action;
  try {
    const response: UserContextData = yield contextService.getContextDetails();
    yield put(
      ContextActions.getContextDetails.done({
        params: payload,
        result: response,
      }),
    );
    yield put({
      type: ContextActions.getUserDetails.started.type,
    });
  } catch (e) {
    yield put(
      ContextActions.getContextDetails.failed({
        params: payload,
        error: e,
      }),
    );
  }
}

function* getRelativePath(action: any) {
  const { payload } = action;
  try {
    const response: RelativePathResponse = yield contextService.fetchRelativePathData();
    yield put(
      ContextActions.fetchCandidateRelativePathList.done({
        params: payload,
        result: response,
      }),
    );
    yield put({ type: ContextActions.fetchUserActivities.started.type });
  } catch (e) {
    yield put(
      ContextActions.fetchCandidateRelativePathList.failed({
        params: payload,
        error: e,
      }),
    );
  }
}

function* getUser(action: any) {
  const { payload } = action;
  try {
    const response: UserDetails = yield userService.getUser();
    yield put(
      ContextActions.getUserDetails.done({
        params: payload,
        result: response,
      }),
    );
    yield put({
      type: ContextActions.fetchCandidateRelativePathList.started.type,
    });
  } catch (e) {
    yield put(
      ContextActions.getUserDetails.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* getUserActivities(action: Action<{}>) {
  try {
    const response: FeaturePermissionRight = yield contextService.fetchUserActivities();
    yield put({
      type: ContextActions.fetchUserActivities.done.type,
      payload: { ...createRoleMappingForFeaturePermission(response) },
    });
  } catch (e) {}
}

function* ContextSaga() {
  yield takeLatest(ContextActions.getContextDetails.started, getContextData);
  yield takeLatest(ContextActions.getUserDetails.started, getUser);
  yield takeLatest(
    ContextActions.fetchCandidateRelativePathList.started,
    getRelativePath,
  );
  yield takeLatest(
    ContextActions.fetchUserActivities.started.type,
    getUserActivities
  );
}

export default ContextSaga;
