import React from "react";
import { Row, Col, Button, Typography, Input, Modal } from "antd";
import text from "text";
import { IRejectModal } from "./type";
import { REJT } from "./constant";

const { Text } = Typography;

export default function RejectModal(props: IRejectModal) {

  const {
      data,
      loading,
      onChangeReason,
      onReject,
      reason,
      onCancel,
      showModal,
    } = props;

  const isRejectButtonDisabled = () => {
    return !reason || loading;
  };

  return (
    <Modal
        visible={showModal}
        footer={null}
        onCancel={onCancel}
        closable={false}
        width={450}
    >
        <Row type="flex" align="middle">
            <Col span={10}><Text>{text.REASON_FOR_REJECTION}</Text></Col>
            <Col span={14}>
                <Input
                    onChange={e => onChangeReason(e.target.value)}
                    value={reason}
                />
            </Col>
        </Row>
        <Row type="flex" justify="end" className="mr-2 mt-4">
            <Button
                disabled={isRejectButtonDisabled()}
                className={isRejectButtonDisabled()
                    ? "px-4 fw-bold"
                    : "bg-primary c-white px-4 fw-bold"}
                onClick={() => onReject(REJT, data)}
                loading={loading}
            >
                {text.REJECT}
            </Button>
        </Row>
    </Modal>
  );
}
