import React from "react";
import { Row } from "antd";

const ErrorMessage = (props: { messages: string[] }) => {
  return (
    <Row
      className="w-100 w-md-50 mt-4 mt-md-8 flex-column d-flex"
      style={{ position: "absolute", alignItems: "center", top: "0" }}>
      <div
        className="w-80 text-align-left p-2"
        style={{
          backgroundColor: "#f2dede",
          color: "#af5350",
          border: "1px solid #af5350",
          borderRadius: "4px",
          wordBreak: "break-word",
        }}>
        <div> Error</div>
        <ul>
          {props.messages.map(item => (
            <li key={item}>{item}</li>
          ))}
        </ul>
      </div>
    </Row>
  );
};

export default ErrorMessage;
