import React, { useState } from "react";
import { Row, Button } from "antd";
import { INetPayFilterProps, NetPaySelectedFilters } from "./type";
import text from "text";
import _ from "lodash";
import { ProcessPeriodList } from "models/otherInput.data";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import { NetPayTypeList } from "models/netpay.data";
import { StatusList } from "models/netpay.data";
import { ModalPopUp } from "components/Modal/ModalPopUp";
import { ModalType } from "components/Modal/type";

export default (props: INetPayFilterProps) => {
  const { dataList, onGetDetails } = props;

  const [selectedValue, setSelectedValue] = useState<NetPaySelectedFilters>({
    netPayType: "",
    payrollPeriod: "",
    processPeriodNumber: "",
    payrollCalendarCode: "",
    statusType: "",
  });

  const validateFilters = () => {
    if (!selectedValue?.netPayType && !selectedValue?.payrollPeriod) {
      ModalPopUp({
        type: ModalType.warning,
        title: text.WARNING,
        description: "Please select net pay type and process period",
      });
    } else if (!selectedValue.netPayType) {
      ModalPopUp({
        type: ModalType.warning,
        title: "Net Pay Type",
        description: "Please select net pay type",
      });
    } else if (!selectedValue.payrollPeriod) {
      ModalPopUp({
        type: ModalType.warning,
        title: text.PROCESS_PERIOD,
        description: text.PROCESS_PERIOD_REQUIRED,
      });
    } else {
      return true;
    }
  };

  const selectNetPayType = (netPayType: any) => {
    setSelectedValue({
      ...selectedValue,
      netPayType: netPayType.netPayTypeCode,
    });
  };

  const selectProcessPeriod = (processPeriod: any) => {
    setSelectedValue({
      ...selectedValue,
      processPeriodNumber: processPeriod.processPeriodNumber,
      payrollCalendarCode: processPeriod.payrollCalendarCode,
      payrollPeriod: processPeriod.processPeriodCode,
    });
  };

  const selectStatus = (status: any) => {
    setSelectedValue({
      ...selectedValue,
      statusType: status.statusCode,
    });
  };

  const getDetails = () => {
    onGetDetails(selectedValue);
  };

  return (
    <Row
      type="flex"
      className="py-4 py-md-2"
      align="middle"
      justify="space-between">
      <SearchableDropdown<NetPayTypeList>
        list={dataList?.netPayTypeList ? dataList?.netPayTypeList : []}
        onSelect={selectNetPayType}
        label={"Net Pay Type"}
        optionKeyExtractor={item => item.netPayTypeCode}
        optionLableExtractor={item => item.netPayType}
        value={selectedValue?.netPayType}
      />
      <SearchableDropdown<ProcessPeriodList>
        list={dataList?.processPeriodList ? dataList?.processPeriodList : []}
        onSelect={selectProcessPeriod}
        label={"Process Period"}
        optionKeyExtractor={item => item.processPeriodCode}
        optionLableExtractor={item => item.processPeriodDescription}
        value={selectedValue?.payrollPeriod}
      />
      <SearchableDropdown<StatusList>
        list={dataList?.statusList ? dataList?.statusList : []}
        onSelect={selectStatus}
        label={"Status"}
        optionKeyExtractor={item => item.statusCode}
        optionLableExtractor={item => item.statusDescription}
        value={selectedValue?.statusType}
      />
      <Button
        onClick={() => (validateFilters() ? getDetails() : () => {})}
        className="bg-info c-white fw-bold px-4"
        disabled={false}>
        {text.GET_DETAILS}
      </Button>
    </Row>
  );
};
