import { Attendancedetails, Authrejectrequest } from "models/timesheet.data";
import { TableData } from "./type";

export function createPostObject(
  value: string,
  data: TableData,
  reason?: string,
) {
  const requestData: Authrejectrequest[] = [
    {
      Action: value,
      Startdate: data.bookedDate?.toString(),
      Employeecode: data.Employeecode,
      Attendanceidentifier:
        data.Attendanceidentification,
      Remarks: reason,
    },
  ];
  return requestData;
}
