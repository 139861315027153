import React, { useEffect, useState } from "react";
import { Button, Card, Row, Table, Tabs } from "antd";
import text from "text";
import { transferRequestImage, uploadFile } from "AssetHelper";
import { style } from "styles/Fonts";
import TransferSummaryCard from "components/TransferSummaryCard/TransferSummaryCard";
import {
  FilterParams,
  ITabMenu,
  ListType,
  MetadataType,
  SummaryType,
  TableData,
} from "./type";
import {
  defaultDetailList,
  defaultMetadataValue,
  defaultTableData,
  getSummaryValue,
  initialFilterValues,
  tabsMenu,
  summaryValue,
  CLMGR_INTERNAL_ROLE,
  offerProcessApplicable,
} from "./constant";
import TransferRequestFilter from "components/TransferRequestFilter/TransferRequestFilter";
import Loader from "components/Loader";
import {
  DetailList,
  Metadata,
  SummaryData,
  DetailListObj,
} from "models/transferRequest.data";
import {
  getCode,
  getCompensationUOMList,
  getCountryList,
  getDepartmentList,
  getDownloadExcelData,
  getDuplicateRecords,
  getFilterDate,
  getGenderList,
  getManipulatedData,
  getNewProfileData,
  getPfApplicabilityList,
  getPositionList,
  getProcessApplicabilityList,
  getSaveRequestedParams,
  getSelectedTab,
  getSkillTypeList,
  getStoreBranchList,
  getUpdatedValue,
  getValidationList,
  isValidExcel,
  newRequestColumn,
  requestedTransferColumn,
} from "./helper";
import _ from "lodash";
import DownloadSheet from "components/DownloadSheet/DownloadSheet";
import TransferRequestService from "./service";
import DownloadExcel from "components/DownloadExcel/DownloadExcel";
import UploadDoc from "components/UploadDoc/UploadDoc";
import { showNotification } from "components/ShowNotification/ShowNotification";
import {
  dateFormat,
  defaultClientStartDate,
  defaultTransferEndDate,
} from "date";
import { ApplicationState } from "store/RootReducer";
import { useSelector } from "react-redux";
import { ModuleType } from "screens/home/store/home/constants";

const { TabPane } = Tabs;

export default function TransferRequest() {
  const [summaryData, setSummaryData] = useState<SummaryType>({
    data: summaryValue,
    isLoading: true,
  });

  const [metadata, setMetadata] = useState<MetadataType>({
    isLoading: true,
    data: defaultMetadataValue,
  });

  const [detailList, setDetailList] = useState<ListType>({
    isLoading: false,
    data: defaultDetailList,
  });

  const [currentTab, setSelectedTab] = useState(text.RAISE_NEW_REQUEST);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState<string[]>([]);
  const [filterValue, setFilterValue] = useState<FilterParams>(
    initialFilterValues
  );
  const [searchValue, setSearchValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState<number>(0);
  const [newRequest, setNewRequest] = useState<TableData[]>([]);

  const { contextStore } = useSelector((state: ApplicationState) => {
    return state;
  });

  useEffect(() => {
    if (
      contextStore.userDetails.data.find(
        (item: any) => item.internalRole === "CLMGR"
      )
    ) {
      getMetadata();
      getList({
        startDate: defaultClientStartDate,
        endDate: defaultTransferEndDate,
        statusType: text.ALL,
      });
      getSummary({
        startDate: defaultClientStartDate,
        endDate: defaultTransferEndDate,
        statusType: text.ALL,
      });
    }
  }, []);

  const getMetadata = async () => {
    let response: Metadata = defaultMetadataValue;
    try {
      setMetadata({
        ...metadata,
        isLoading: true,
      });
      response = await TransferRequestService.getMetadata();
      setMetadata({
        isLoading: false,
        data: response,
      });
    } catch (err) {
    } finally {
      setMetadata({
        isLoading: false,
        data: response,
      });
    }
  };

  const getSummary = async (requestedData: FilterParams) => {
    let summaryCount = summaryValue;
    try {
      setSummaryData({
        ...summaryData,
        isLoading: true,
      });
      const { startDate, endDate } = requestedData;
      const response: SummaryData = await TransferRequestService.getSummary({
        startDate: startDate ? startDate.format(dateFormat) : "",
        endDate: endDate ? endDate.format(dateFormat) : "",
        statusCode: text.ALL,
        internalRole: CLMGR_INTERNAL_ROLE,
      });
      summaryCount = getSummaryValue(response.data);
      setSummaryData({
        isLoading: false,
        data: summaryCount,
      });
    } catch (err) {
    } finally {
      setSummaryData({
        isLoading: false,
        data: summaryCount,
      });
    }
  };

  const getList = async (requestedData: FilterParams, tab?: string) => {
    let response: DetailList = defaultDetailList;
    try {
      setDetailList({
        ...detailList,
        isLoading: true,
      });
      const { startDate, endDate, statusType } = requestedData;
      response = await TransferRequestService.getDetailList({
        startDate: startDate ? startDate.format(dateFormat) : "",
        endDate: endDate ? endDate.format(dateFormat) : "",
        statusCode: statusType,
        internalRole: CLMGR_INTERNAL_ROLE,
      });
      setDetailList({
        isLoading: false,
        data: response,
      });
      tab === text.NEW_PROFILE && getNewProfileRecords(response.data);
    } catch (err) {
    } finally {
      setDetailList({
        isLoading: false,
        data: response,
      });
    }
  };

  const onSubmit = async () => {
    const requestedList = getSaveRequestedParams(
      newRequest,
      selectedRowKey,
      currentTab
    );
    try {
      setIsSaveLoading(true);
      await TransferRequestService.postCandidateRequest({
        internalRole: CLMGR_INTERNAL_ROLE,
        candidateDataList: requestedList,
      });
      showNotification({
        message: text.SUCCESS,
        description: text.SUBMIT_SUCCESSFULLY,
        type: text.SUCCESS,
      });
      if (currentTab === text.RAISE_NEW_REQUEST) {
        setNewRequest([]);
        getSummary({
          startDate: defaultClientStartDate,
          endDate: defaultTransferEndDate,
          statusType: text.ALL,
        });
      }
      setSelectedRowKey([]);
    } catch {
    } finally {
      setIsSaveLoading(false);
    }
  };

  const onFilterChange = (value: FilterParams) => {
    const { startDate, endDate } = getFilterDate(value);
    getList({
      startDate,
      endDate,
      statusType: value.statusType,
    });
    getSummary({
      startDate,
      endDate,
      statusType: value.statusType,
    });
    setSearchValue("");
    setFilterValue({
      startDate: value.startDate,
      endDate: value.startDate ? value.endDate : null,
      statusType: value.statusType,
    });
  };

  const onUploadExcelFile = (value: any) => {
    if (value) {
      if (isValidExcel(value)) {
        showNotification({
          message: text.WARNING,
          description: text.UPLOAD_VALID_EXCEL,
          type: text.ERROR,
        });
        return;
      }
      const updatedValue = getUpdatedValue(value);
      const duplicateCandidate: string[] = getDuplicateRecords(updatedValue);
      if (duplicateCandidate.length > 0) {
        showNotification({
          message: text.WARNING,
          description: `${duplicateCandidate.join(", ")} ${
            text.DUPLICATE_ENTRY_MESSAGE
          }`,
          type: text.ERROR,
        });
      } else {
        const tableData: TableData[] = [];
        setSelectedRowKey([]);
        const metadataList = metadata.data.data;
        updatedValue.map((item: TableData, index: number) => {
          tableData.push({
            ...item,
            id: index,
            title: getCode(
              item.title,
              metadataList.titleList,
              "titleCode",
              "titleDescription"
            ),
            gender: getCode(
              item.gender,
              metadataList.genderList,
              "genderCode",
              "genderDescription"
            ),
            position: getCode(
              item.position,
              metadataList.positionList,
              "positionCode",
              "positionDescription"
            ),
            country: getCode(
              item.country,
              metadataList.countryList,
              "countryCode",
              "countryDescription"
            ),
            state: getCode(
              item.state,
              metadataList.countryList.find(
                data => data.countryDescription === item.country
              )?.stateList || [],
              "stateCode",
              "stateDescription"
            ),
            city: getCode(
              item.city,
              metadataList.countryList
                .find(data => data.countryDescription === item.country)
                ?.stateList.find(state => state.stateDescription === item.state)
                ?.cityList || [],
              "cityCode",
              "cityDescription"
            ),
            department: getCode(
              item.department,
              metadataList.departmentList,
              "divisionCode",
              "divisionName"
            ),
            storeBranch: getCode(
              item.storeBranch,
              metadataList.storeBranchList,
              "storeBranchCode",
              "storeBranchDescription"
            ),
            skillType: getCode(
              item.skillType,
              metadataList.skillTypeList,
              "skillTypeCode",
              "skillTypeDescription"
            ),
            pfApplicability: getCode(
              item.pfApplicability,
              metadataList.pfApplicabilityList,
              "pfApplicabilityCode",
              "pfApplicability"
            ),
            compensationUOM: getCode(
              item.compensationUOM,
              metadataList.compensationUOMList,
              "compensationUOMCode",
              "compensationUOMDescription"
            ),
            offerClientApproval: getCode(
              item.offerClientApproval,
              offerProcessApplicable,
              "code",
              "value"
            ),
            offerProcessApplicable: getCode(
              item.offerProcessApplicable,
              offerProcessApplicable,
              "code",
              "value"
            ),
          });
        });
        setNewRequest(tableData);
        showNotification({
          message: text.SUCCESS,
          description: text.UPLOAD_EXCEL_MESSAGE,
          type: text.SUCCESS,
        });
      }
    }
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const onChangeRowSelection = (value: any, selectedRowsData: any[]) => {
    setSelectedRowKey(value);
  };

  const getNewProfileRecords = (data: DetailListObj) => {
    const newProfileRecords = getNewProfileData(data);
    setNewRequest(newProfileRecords);
  };

  const onTabChange = async (activeKey: string) => {
    setCurrentPage(1);
    setSelectedRowKey([]);
    setNewRequest([]);
    setCount(0);
    setSearchValue("");
    setFilterValue(initialFilterValues);
    const type = getSelectedTab(activeKey);
    setSelectedTab(type);
    if (!(type === text.RAISE_NEW_REQUEST)) {
      await getList(
        {
          startDate: defaultClientStartDate,
          endDate: defaultTransferEndDate,
          statusType: text.ALL,
        },
        type
      );
    }
  };

  const rowSelection = () => {
    return {
      selectedRowKeys: selectedRowKey,
      onChange: onChangeRowSelection,
    };
  };

  const handleDelete = (index: number) => {
    const updatedData = [...newRequest];
    const selectedRow = [...selectedRowKey];
    selectedRow.splice(index, 1);
    setSelectedRowKey(selectedRow);
    updatedData.splice(index, 1);
    setNewRequest(updatedData);
  };

  const handleAddRow = () => {
    const t = {
      ...defaultTableData,
      id: count,
    };
    setCount(count + 1);
    setNewRequest([...newRequest, t]);
  };

  const renderTabs = () => {
    return (
      <Row type="flex" className="pb-4" align="middle" justify="space-between">
        <Tabs
          defaultActiveKey="0"
          onChange={(activeKey: string) => onTabChange(activeKey)}>
          {tabsMenu.map((item: ITabMenu, index: number) => {
            return (
              <TabPane
                key={item.key}
                tab={item.tabName}
                disabled={detailList.isLoading || isSaveLoading}
              />
            );
          })}
        </Tabs>
        <Row type="flex">
          {currentTab !== text.REQUESTED_TRANSFERS ? (
            <DownloadSheet
              positionList={getPositionList(metadata.data)}
              genderList={getGenderList(metadata.data)}
              storeBranchList={getStoreBranchList(metadata.data)}
              countryList={getCountryList(metadata.data)}
              skillTypeList={getSkillTypeList(metadata.data)}
              pfApplicabilityList={getPfApplicabilityList(metadata.data)}
              compensationUOMList={getCompensationUOMList(metadata.data)}
              departmentList={getDepartmentList(metadata.data)}
              processApplicabilityList={getProcessApplicabilityList()}
              data={newRequest}
              lable={text.DOWNLOAD_TABLE}
              downloadData={getDownloadExcelData(newRequest, metadata.data)}
              inputList={getValidationList(metadata.data)}
              isDisable={newRequest.length === 0}
            />
          ) : (
            <DownloadExcel
              data={getManipulatedData(detailList.data.data)}
              lable={text.DOWNLOAD_TABLE}
              column={requestedTransferColumn()}
              isDisable={detailList.data.data.candidateList.length === 0}
            />
          )}
          <UploadDoc
            onUpload={onUploadExcelFile}
            lable={text.UPLOAD_INFO_DATA}
            type={text.EXCEL}
            icon={uploadFile}
            iconSize={"7"}
            column={getDownloadExcelData(newRequest, metadata.data).column}
            isDisable={!(currentTab === text.RAISE_NEW_REQUEST)}
            dataToFit={{
              list: newRequest ? newRequest : [],
              key: "Email",
            }}
          />
          <Button
            className="bg-info c-white m-1"
            onClick={() => handleAddRow()}
            disabled={!(currentTab === text.RAISE_NEW_REQUEST) || isSaveLoading}>
            {text.ADD_NEW_ROW}
          </Button>
        </Row>
      </Row>
    );
  };

  const onChangeData = (inputValue: string, key: string, row: TableData) => {
    const newRequestedData = _.cloneDeep(newRequest);
    if (currentTab === text.NEW_PROFILE)
      newRequestedData
        .filter(item => item.applicantId === row.applicantId)
        .map((item: any, index) => {
          item[key] = inputValue;
        });
    else
      newRequestedData
        .filter(item => item.id === row.id)
        .map((item: any, index) => {
          item[key] = inputValue;
        });
    setNewRequest(newRequestedData);
  };

  const getRequestedTransferList = () => {
    const candidateList = getManipulatedData(detailList.data.data);
    if (!searchValue) return candidateList;
    return candidateList.filter(item => {
      return item.firstName?.toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  if (!contextStore.allowedActivities[ModuleType.TRANSFER_REQUEST])
    return <p>{text.UNAUTHORIZED_VIEW}</p>;

  return (
    <Row className="p-6">
      <Row className="pb-4 ff-secondary" style={style.large}>
        <img src={transferRequestImage} className="pr-3 h-6" />
        {text.TRANSFER_REQUEST}
      </Row>
      <TransferSummaryCard
        isLoading={summaryData.isLoading}
        data={summaryData.data}
      />
      {metadata.isLoading ? (
        <Card className="my-2">
          <Loader />
        </Card>
      ) : (
        <>
          <TransferRequestFilter
            onChangeFilters={onFilterChange}
            data={metadata.data.data.statusList || []}
            filterValues={filterValue}
            setSearchText={setSearchValue}
            currentTab={currentTab}
            searchValue={searchValue}
          />
          {renderTabs()}
        </>
      )}
      {currentTab === text.REQUESTED_TRANSFERS && (
        <Row id="table">
          <Table
            // @ts-ignore
            columns={requestedTransferColumn()}
            dataSource={getRequestedTransferList()}
            rowKey={"applicantId"}
            scroll={{ x: true }}
            pagination={{
              pageSize: 5,
              current: currentPage,
              onChange: changePage,
            }}
            loading={detailList.isLoading}
          />
        </Row>
      )}
      {!(currentTab === text.REQUESTED_TRANSFERS) && (
        <>
          <Row id="table">
            <Table
              // @ts-ignore
              columns={newRequestColumn(
                onChangeData,
                metadata.data,
                handleDelete,
                currentTab,
                isSaveLoading,
              )}
              rowSelection={rowSelection()}
              dataSource={newRequest}
              rowKey={currentTab === text.NEW_PROFILE ? "applicantId" : "id"}
              scroll={{ x: true }}
              pagination={{
                pageSize: 5,
                current: currentPage,
                onChange: changePage,
              }}
              loading={
                !(currentTab === text.RAISE_NEW_REQUEST) && detailList.isLoading
              }
            />
          </Row>
          <Row className="py-5 w-100" type="flex" justify="end">
            <Button
              onClick={onSubmit}
              disabled={!(selectedRowKey.length > 0)}
              type="primary"
              className={
                !(selectedRowKey.length > 0)
                  ? "px-8 h-8 fw-bold"
                  : "c-white px-8 h-8 fw-bold"
              }
              loading={isSaveLoading}>
              {text.SUBMIT}
            </Button>
          </Row>
        </>
      )}
    </Row>
  );
}
