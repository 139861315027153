import React from "react";
import { Col, Row } from "antd";
import { Reportees } from "../../models/associate.data";
import { callImage } from "AssetHelper";
import { CardLayout } from "./CardLayout";
import { style } from "styles/Fonts";
import CustomImage from "components/CustomImage/CustomImage";
import { useSelector, shallowEqual } from "react-redux";
import text from "text";
import { getRelativePathForAssociate } from "common/Utils.tsx/GetRelativePath";

function AssociateCard(props: { cardData: Reportees, gridView: boolean }) {
  const {
    employeeName,
    primaryEmailId,
    primaryMobileNumber,
    employeeCode,
    customOutpt1,
    customOutput2,
  } = props.cardData;
  const { contextStore } = useSelector((state: any) => {
    return state;
  }, shallowEqual);
  return (
    <CardLayout>
      <Row type="flex" className="pb-1" gutter={[8, 16]}>
        <Col xs={{ span: 24 }} sm={{ span: 4 }} className={!props.gridView ? "w-sm-7" : ""}>
          <CustomImage
            file={customOutput2}
            relativePath={getRelativePathForAssociate(
              contextStore.relativePath.data,
              text.ASSOCIATE_RELATIVEPATH_KEY,
            )}
            width="95%"
          />
        </Col>
        <Col span={20}>
          <div style={style.normal}>{employeeName}</div>
          <div style={style.xsmall} className="c-disabled">
            {customOutpt1}
          </div>
          <div style={style.xsmall} className="c-disabled">
            {primaryEmailId}
          </div>
          <div style={style.xsmall} className="c-disabled">
            {employeeCode}
          </div>
        </Col>
      </Row>
      <Row>
        <div
          className="mt-2 pt-1 c-disabled"
          style={{ borderTop: "1px solid rgba(0, 0, 0, 0.05)" }}>
          <span>
            <img width={12} alt="call-img" src={callImage} />{" "}
          </span>
          <span>
            <a href={`tel:+91${primaryMobileNumber}`} className="c-black">
              {primaryMobileNumber ? `+91${primaryMobileNumber}` : "N/A"}
            </a>
          </span>
        </div>
      </Row>
    </CardLayout>
  );
}

export default AssociateCard;
