import React, { Fragment, useState, useEffect } from "react";
import { Row, Table, Button, Tabs, Modal, Col, Empty } from "antd";
import {
  column,
  getManipulatedData,
  getBadgeValue,
  getPostRequestParams,
  getActionType,
  columnForExcel,
  getDuplicateRecords,
  getKeyOfActionType,
  getUpdatedRecords,
} from "./helper";
import text from "../../text";
import { TableData, ITabMenu } from "./type";
import { style } from "styles/Fonts";
import DownloadExcel from "components/DownloadExcel/DownloadExcel";
import { PostPayrollRequestBody } from "models/otherInput.data";
import { ModalPopUp } from "components/Modal/ModalPopUp";
import { ModalType } from "components/Modal/type";
import {
  PayrollHookTypes,
  usePayrollAction,
  usePayrollList,
  usePayrollUploadAction,
} from "./hooks";
import usePayrollMetadata from "./hooks";
import DropdownPayrollFilter from "components/DropdownPayrollFilter/DropdownPayrollFilter";
import {
  IDropdownFilterParams,
  PayrollPeriodParams,
  AssociateList,
} from "components/DropdownPayrollFilter/type";
import {
  tabsMenu,
  actionTypeCodes,
  EMPLOYEE_CODE_SEARCH,
  CLI_INTERNAL_ROLE,
  CLMGR_INTERNAL_ROLE,
  NOT_ALLOWED_TO_SUBMIT,
} from "./constants";
import Loader from "components/Loader";
import { queryCache } from "react-query";
import { ApplicationState } from "store/RootReducer";
import { useSelector } from "react-redux";
import { getRelativePathForAssociate } from "common/Utils.tsx/GetRelativePath";
import { getPDFURI } from "common/Utils.tsx/Download";
import _ from "lodash";
import UploadDoc from "components/UploadDoc/UploadDoc";
import { uploadFile, otherInputImage } from "AssetHelper";
import DisplayError from "components/DisplayError/DisplayError";
import { showNotification } from "components/ShowNotification/ShowNotification";
import { ModuleType } from "screens/home/store/home/constants";

const { PAYROLL_METADATA } = PayrollHookTypes;
const { PAYROLL_DATA } = PayrollHookTypes;

export default function OtherInput() {
  const [selectedRowKey, setSelectedRowKey] = useState<string[]>([]);
  const [showLoadingModal, setShowLoadingModal] = useState(false);
  const [loadingModalMsg, setLoadingModalMsg] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const { contextStore } = useSelector((state: ApplicationState) => {
    return state;
  });

  const isInternalRoleClient = contextStore.userDetails.data.find(
    (item: any) => item.internalRole === CLI_INTERNAL_ROLE
  )
    ? true
    : false;

  const [actionType, setActionType] = useState(actionTypeCodes.ADD_NEW);
  const [selectedValue, setSelectedValue] = useState<IDropdownFilterParams>({
    lineManagerName: "",
    payElementCode: "",
    payrollCalendarCode: "",
    processPeriodCode: "",
    processPeriodNumber: 0,
    associateList: [],
  });

  const clearQueryCaches = () => {
    queryCache.removeQueries(PAYROLL_DATA);
    queryCache.removeQueries(PAYROLL_METADATA);
  };

  useEffect(() => {
    return clearQueryCaches;
  }, []);

  const onChangeRowSelection = (value: any, selectedRowsData: TableData[]) => {
    setSelectedRowKey(value);
  };

  const rowSelection = () => {
    return {
      selectedRowKeys: selectedRowKey,
      onChange: onChangeRowSelection,
      getCheckboxProps: (record: TableData) => ({
        disabled: record.isAllowedToSubmit === NOT_ALLOWED_TO_SUBMIT,
      }),
    };
  };

  const relativePath = contextStore.relativePath.data.find(
    item => item.modulecode === text.BULK_PAYROLL_MODULE_CODE
  )?.relativepath as string;

  const getCustomTextInput = () => {
    if (selectedValue.associateList)
      return selectedValue.associateList.join(";");
    else return "";
  };

  const {
    isFetching: isListLoading,
    data: payrollList,
    refetch: refetchPayrollList,
    isError: isListError,
    error: listErrorResponse,
  } = usePayrollList(PAYROLL_DATA, {
    internalRole: isInternalRoleClient
      ? CLI_INTERNAL_ROLE
      : CLMGR_INTERNAL_ROLE,
    payrollCalendarCode: selectedValue.payrollCalendarCode,
    processPeriodCode: selectedValue.processPeriodCode,
    payElementCode: selectedValue.payElementCode,
    processPeriodNumber: selectedValue.processPeriodNumber,
    customTextInput4: getCustomTextInput(),
    employeeCodeSearch: EMPLOYEE_CODE_SEARCH,
    lineManagerCodeSearch: selectedValue.lineManagerName
      ? selectedValue.lineManagerName
      : "",
    actionType,
  });

  useEffect(() => {
    refetchPayrollList();
  }, [actionType]);

  const {
    isFetching: isMetaDataLoading,
    data: payrollMetatdata,
    isError: isMetadataError,
    error: metaDataErrorResponse,
    refetch: refetchMetadata,
  } = usePayrollMetadata(PAYROLL_METADATA, {
    internalRole: isInternalRoleClient
      ? CLI_INTERNAL_ROLE
      : CLMGR_INTERNAL_ROLE,
  });

  const [payrollData, { isLoading }] = usePayrollAction();

  const onSubmit = async () => {
    const employeeList = getPostRequestParams(
      selectedRowKey,
      actionType,
      payrollList!
    );
    const postPayrollRequestParams: PostPayrollRequestBody = {
      actionType,
      internalRole: isInternalRoleClient
        ? CLI_INTERNAL_ROLE
        : CLMGR_INTERNAL_ROLE,
      payElementCode: selectedValue.payElementCode,
      payrollCalendarCode: selectedValue.payrollCalendarCode,
      processPeriodCode: selectedValue.processPeriodCode,
      processPeriodNumber: selectedValue.processPeriodNumber,
      EmployeeValueListArray: employeeList,
    };
    await payrollData(postPayrollRequestParams);
    !isLoading && setSelectedRowKey([]);
  };

  const [uploadData] = usePayrollUploadAction();

  const onValueChange = (value: string, row: TableData) => {
    if (payrollList) {
      const newPayrollList = _.cloneDeep(payrollList);
      newPayrollList.data.EmployeeValueList.filter(
        (item: any) => item.employeeCode === row.employeeCode
      ).map((item: any) => {
        item.payElementValue = value;
      });
      queryCache.setQueryData(PAYROLL_DATA, newPayrollList);
    }
  };

  const onRemarksChange = (value: string, row: TableData) => {
    if (payrollList) {
      const newPayrollList = _.cloneDeep(payrollList);
      newPayrollList.data.EmployeeValueList.filter(
        item => item.employeeCode === row.employeeCode
      ).map(item => {
        item.valueRemarks = value;
      });
      queryCache.setQueryData(PAYROLL_DATA, newPayrollList);
    }
  };

  const UploadExcelFile = (value: any) => {
    if (value) {
      const updatedRecords = getUpdatedRecords(value);
      if(updatedRecords.length){
      const duplicateEmployeeCodes: string[] = getDuplicateRecords(value);
      if (duplicateEmployeeCodes.length > 0) {
        showNotification({
          message: text.WARNING,
          description: `${duplicateEmployeeCodes.join(", ")} ${
            text.DUPLICATE_ENTRY_MESSAGE
          }`,
          type: text.ERROR,
        });
      } else {
        setSelectedRowKey([]);
        const newPayrollList = _.cloneDeep(payrollList);
        newPayrollList!.data.EmployeeValueList = value;
        queryCache.setQueryData(PAYROLL_DATA, newPayrollList);
        showNotification({
          message: text.SUCCESS,
          description: text.UPLOAD_EXCEL_MESSAGE,
          type: text.SUCCESS,
        });
      }
    }else{
      showNotification({
        message: text.WARNING,
        description: "Please enter valid file",
        type: text.ERROR,
      });
    }
    }
  };

  const onDocumentUpload = async (file: any, row: TableData) => {
    try {
      const requestBody = {
        file: file[0],
        relativePath,
      };
      if (!requestBody.file) return;
      setLoadingModalMsg(text.UPLOADING_DOCUMENT);
      setShowLoadingModal(true);
      const fileName = await uploadData(requestBody);
      if (!fileName) {
        showNotification({
          message: text.ERROR,
          description: text.UPLOAD_FAILED,
          type: text.ERROR,
        });
        return;
      }
      if (payrollList) {
        const newPayrollList = _.cloneDeep(payrollList);
        newPayrollList.data.EmployeeValueList.filter(
          item => item.employeeCode === row.employeeCode
        ).map(item => {
          item.attachmentFileName = fileName;
        });
        queryCache.setQueryData(PAYROLL_DATA, newPayrollList);
        showNotification({
          message: text.SUCCESS,
          description: text.UPLOAD_SUCCESSFULL,
          type: text.SUCCESS,
        });
      }
    } catch {
    } finally {
      setShowLoadingModal(false);
    }
  };

  const downloadFile = async (file: string) => {
    try {
      if (file) {
        setLoadingModalMsg(text.LOADING_DOWNLOAD);
        setShowLoadingModal(true);
        await getPDFURI(
          file,
          getRelativePathForAssociate(
            contextStore.relativePath.data,
            text.BULK_PAYROLL_MODULE_CODE
          )
        );
      }
    } catch (err) {
    } finally {
      setShowLoadingModal(false);
    }
  };

  const validateFilters = () => {
    if (!selectedValue.payElementCode && !selectedValue.processPeriodCode) {
      ModalPopUp({
        type: ModalType.warning,
        title: text.WARNING,
        description: text.INPUT_TYPE_AND_PROCESS_PERIOD_REQUIRED,
      });
    } else if (!selectedValue.payElementCode) {
      ModalPopUp({
        type: ModalType.warning,
        title: text.INPUT_TYPE,
        description: text.INPUT_TYPE_REQUIRED,
      });
    } else if (!selectedValue.processPeriodCode) {
      ModalPopUp({
        type: ModalType.warning,
        title: text.PROCESS_PERIOD,
        description: text.PROCESS_PERIOD_REQUIRED,
      });
    } else {
      return true;
    }
  };

  const onLineMangagerChange = (value: string) => {
    setSelectedValue({
      ...selectedValue,
      lineManagerName: value,
      associateList: [],
    });
  };

  const onInputTypeChange = (value: string) => {
    setSelectedValue({ ...selectedValue, payElementCode: value });
  };

  const onProcessPeriodChange = (value: PayrollPeriodParams) => {
    setSelectedValue({
      ...selectedValue,
      payrollCalendarCode: value.calendarCode,
      processPeriodCode: value.periodCode,
      processPeriodNumber: value.periodNumber,
    });
  };

  const onAssociateNameChange = (value: string[]) => {
    setSelectedValue({
      ...selectedValue,
      associateList: value,
    });
  };

  const changePage = (page: number) => {
    setCurrentPage(page);
  };

  const onTabChange = (activeKey: string) => {
    const type = getActionType(activeKey);
    setSelectedRowKey([]);
    setActionType(type);
    setCurrentPage(1);
    queryCache.removeQueries(PAYROLL_DATA);
  };

  const renderTabContent = () => {
    return tabsMenu.map((item: ITabMenu, index) => {
      return (
        <Tabs.TabPane
          key={item.key}
          tab={
            <span className="mr-1">
              {item.tabName}
              {payrollList && getBadgeValue(payrollList!, item.dataKey)}
            </span>
          }
          disabled={item.isDisabled}
        />
      );
    });
  };

  const renderTable = () => {
    return payrollList && payrollList?.data.EmployeeValueList.length !== 0 ? (
      <Table
        // @ts-ignore
        columns={column(
          onValueChange,
          onRemarksChange,
          onDocumentUpload,
          downloadFile,
          actionType
        )}
        rowSelection={
          actionType !== actionTypeCodes.INVOICED &&
          actionType !== actionTypeCodes.PROCESSED
            ? rowSelection()
            : undefined
        }
        dataSource={getManipulatedData(payrollList!)}
        rowKey="employeeCode"
        scroll={{ x: true }}
        pagination={{
          pageSize: 5,
          current: currentPage,
          onChange: changePage,
        }}
        loading={isListLoading}
      />
    ) : (
      <Row className="bg-white w-100">
        {isListLoading ? (
          <Row className="m-9">
            <Loader />
          </Row>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Row>
    );
  };

  const renderDropdownFilter = () => {
    return (
      <>
        <Col md={24} lg={20}>
          <DropdownPayrollFilter
            dataList={payrollMetatdata?.data}
            isLineManagerEnabled={isInternalRoleClient}
            getSelectedLineManager={onLineMangagerChange}
            getSelectedInputType={onInputTypeChange}
            getSelectedProcess={onProcessPeriodChange}
            getSelectedAssociate={onAssociateNameChange}
            selectedValues={selectedValue}
          />
        </Col>
        <Col md={24} lg={4} className="d-flex justify-content-end">
          <Button
            onClick={() => {
              if (validateFilters()) onTabChange("1");
            }}
            className="bg-info c-white fw-bold px-4"
            disabled={isListLoading}>
            {text.GET_DETAILS}
          </Button>
        </Col>
      </>
    );
  };

  if (!contextStore.allowedActivities[ModuleType.BULK_PAYROLL])
    return <p>{text.UNAUTHORIZED_VIEW}</p>;

  return (
    <Fragment>
      <Row className="p-6" id="otherinput">
        <Modal visible={showLoadingModal} footer={null} closable={false}>
          <Loader />
          <Row className="c-primary" type="flex" justify="center">
            {loadingModalMsg}
          </Row>
        </Modal>
        {isMetadataError ? (
          <DisplayError
            errorCode={metaDataErrorResponse?.name || ""}
            errorMsg={metaDataErrorResponse?.message || ""}
            onTryAgain={refetchMetadata}
          />
        ) : (
          <>
            <Row className="pb-2 ff-secondary" style={style.large} type="flex">
              <Row>
                <img src={otherInputImage} className="pr-3 h-6" />
              </Row>
              <Row>{text.OTHER_INPUTS}</Row>
            </Row>
            <Row type="flex" justify="center" align="middle">
              {isMetaDataLoading ? <Loader /> : renderDropdownFilter()}
            </Row>
            <Tabs
              defaultActiveKey="1"
              activeKey={getKeyOfActionType(actionType)}
              className="c-secondary"
              onChange={(activeKey: string) => onTabChange(activeKey)}>
              {renderTabContent()}
            </Tabs>
            <Row type="flex" justify="end" className="pb-4">
              <DownloadExcel
                data={payrollList ? payrollList.data.EmployeeValueList : []}
                lable={text.DOWNLOAD_EXCEL}
                column={columnForExcel()}
                isDisable={
                  payrollList?.data.EmployeeValueList.length ? false : true
                }
              />
              <UploadDoc
                onUpload={UploadExcelFile}
                lable={text.UPLOAD_EXCEL}
                type={text.EXCEL}
                isDisable={
                  payrollList === undefined ||
                  actionType === actionTypeCodes.INVOICED ||
                  actionType === actionTypeCodes.PROCESSED
                    ? true
                    : false
                }
                icon={uploadFile}
                iconSize={"7"}
                column={columnForExcel()}
                dataToFit={{
                  list: payrollList ? payrollList.data.EmployeeValueList : [],
                  key: "employeeCode",
                }}
              />
            </Row>
            <Row id="table">
              {listErrorResponse?.name !== "404" && isListError ? (
                <DisplayError
                  errorCode={listErrorResponse?.name || ""}
                  errorMsg={listErrorResponse?.message || ""}
                  onTryAgain={refetchPayrollList}
                />
              ) : (
                renderTable()
              )}
            </Row>
            {(actionType === actionTypeCodes.ADD_NEW ||
              actionType === actionTypeCodes.REQUESTED) && (
              <Row className="py-5 w-100" type="flex" justify="end">
                <Button
                  onClick={onSubmit}
                  loading={isLoading}
                  disabled={!(selectedRowKey.length > 0)}
                  type="primary"
                  className={
                    !(selectedRowKey.length > 0)
                      ? "px-3 h-9 fw-bold"
                      : "c-white px-3 h-9 fw-bold"
                  }>
                  {text.SUBMIT}
                </Button>
              </Row>
            )}
          </>
        )}
      </Row>
    </Fragment>
  );
}
