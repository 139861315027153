import {
  OnboardingDetails,
  OnboardingTableDetailsResponse,
  OnboardingMetaDataDetails,
} from "models/analytics.data";
import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { RequestParams } from "./type";

export class AnalyticsService {
  public getOnboardingMetaDataAnalyticsList(params: {
    internalRole: string;
    apiCode: string;
    callingFrom: string;
    fromDate?:string;
    toDate?:string;
  }): Promise<OnboardingMetaDataDetails> | OnboardingMetaDataDetails {
    const service: ApiService = new ApiService(
      EndPoints.GET_ONBOARDING_ANALYTICS_METADATA
    );
    //@ts-ignore
    return service.get({ dynamicQueryParams: [params] });
  }

  public getOnboardingAnalyticsList(
    params: RequestParams
  ): Promise<OnboardingDetails> | OnboardingDetails {
    const service: ApiService = new ApiService(params.queryKey);
    const requestParams = {
      internalRole: params.internalRole,
      toDate: params.toDate,
      fromDate: params.fromDate,
      clraStatusSearchCode: params.clraStatusCodeSearch!,
    };
    return service.get({ dynamicQueryParams: [requestParams] });
  }

  public getOnboardingTableAnalyticsList(
    params: RequestParams
  ): Promise<OnboardingTableDetailsResponse> | OnboardingTableDetailsResponse {
    const service: ApiService = new ApiService(params.queryKey);
    const requestParams = {
      internalRole: params.internalRole,
      toDate: params.toDate,
      fromDate: params.fromDate,
      clraStatusSearchCode: params.clraStatusCodeSearch!,
    };
    return service.get({ dynamicQueryParams: [requestParams] });
  }
}

export default new AnalyticsService();
