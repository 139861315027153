import {
  BulkAttendanceMetaData,
  AttendanceListType,
  LineManagerList,
  EmployeeList,
  ProcessPeriodList,
  StatusList,
  DayValueList,
  EmpAttendanceList,
  BulkAttendanceList,
} from "models/bulkAttendance.data";
import {
  BulkAttendanceManipulatedMetaData,
  InputManipulatedListType,
  AttendanceManipulatedListType,
  LeaveSessionManipulatedList,
  LineManagerManipulatedList,
  EmployeeManipulatedList,
  ProcessPeriodManipulatedList,
  StatusManipulatedList,
  TableData,
} from "./type";
import React from "react";
import {
  actionTypeCodes,
  DAILY_ATTENDANCE_TYPE_CODE,
  DAILY_INDICATOR,
  HOULRY_INDICATOR,
  HOURLY_ATTENDANCE_TYPE_CODE,
  YES_CODE,
  VIEW_RECORD,
} from "./constant";
import BulkAttendanceInput from "components/BulkAttendanceInput/BulkAttendanceInput";
import moment from "moment";
import { Row } from "antd";
import text from "text";

export function manipulateData(metaData: BulkAttendanceMetaData) {
  let manipulatedData: BulkAttendanceManipulatedMetaData = {
    data: {
      clientCode: "",
      clientName: "",
      attendanceTypeList: [],
      inputTypeList: [],
      leaveSessionList: [],
      lineManagerList: [],
      processPeriodList: [],
      statusList: [],
      employeeManagerList: {},
    },
    message: "",
  };
  manipulatedData.data.clientCode = metaData.data.clientCode;
  manipulatedData.data.clientName = metaData.data.clientName;
  // Attendance list
  metaData.data.attendanceTypeList.map((item: AttendanceListType, index) => {
    const attendanceType: AttendanceManipulatedListType = {
      attendanceTypeCode: item.attendanceTypeCode,
      attendanceTypeDesc: item.attendanceTypeDesc,
    };
    manipulatedData.data.attendanceTypeList[index] = attendanceType;
  });
  // Input type list
  metaData.data.inputTypeList.map((item, index) => {
    const inputType: InputManipulatedListType = {
      dailyApplicability: item.dailyApplicability,
      hourlyApplicability: item.hourlyApplicability,
      inputTypeCategory: item.inputTypeCategory,
      inputTypeCode: item.inputTypeCode,
      inputTypeDesc: item.inputTypeDesc,
    };
    manipulatedData.data.inputTypeList[index] = inputType;
  });
  // leave session list
  metaData.data.leaveSessionList.map(
    (item: LeaveSessionManipulatedList, index) => {
      const leaveSession: LeaveSessionManipulatedList = {
        defaultSessionFlag: item.defaultSessionFlag,
        leaveSessionCode: item.leaveSessionCode,
        leaveSessionDesc: item.leaveSessionDesc,
      };
      manipulatedData.data.leaveSessionList[index] = leaveSession;
    }
  );
  // line manager list and employee list
  metaData.data.lineManagerList.map((item: LineManagerList, index) => {
    const lineManager: LineManagerManipulatedList = {
      lineManagerCode: item.lineManagerCode,
      lineManagerName: item.lineManagerName,
    };
    manipulatedData.data.lineManagerList[index] = lineManager;
    const employeeList: EmployeeList[] = [];
    item.employeeList?.map((i: EmployeeList, index) => {
      const employee: EmployeeManipulatedList = {
        employeeCode: i.employeeCode,
        employeeName: i.employeeName,
        employeePosition: i.employeePosition,
        supervisorCode: i.supervisorCode,
      };
      employeeList[index] = employee;
    });
    manipulatedData.data.employeeManagerList[
      item.lineManagerCode
    ] = employeeList;
  });
  // process period code
  metaData.data.processPeriodList.map((item: ProcessPeriodList, index) => {
    const processPeriodList: ProcessPeriodManipulatedList = {
      payrollCalendarCode: item.payrollCalendarCode,
      processPeriodCode: item.processPeriodCode,
      processPeriodDescription: item.processPeriodDescription,
      processPeriodFlag: item.processPeriodFlag,
      processPeriodFromDate: item.processPeriodFromDate,
      processPeriodNumber: item.processPeriodNumber,
      processPeriodToDate: item.processPeriodToDate,
      attendanceList: item.attendanceCycleList,
    };
    manipulatedData.data.processPeriodList[index] = processPeriodList;
  });
  //status List
  metaData.data.statusList.map((item: StatusList, index) => {
    const statusList: StatusManipulatedList = {
      defaultStatusFlag: item.defaultStatusFlag,
      statusCode: item.statusCode,
      statusDesc: item.statusDesc,
    };
    manipulatedData.data.statusList[index] = statusList;
  });
  return manipulatedData;
}

export const getPostRequestParams = (
  selectedRowKey: string[],
  actionType: string,
  attendanceList: BulkAttendanceList
) => {
  let employeeList: EmpAttendanceList[] = [];
  employeeList = attendanceList.data.empAttendanceList.filter(data =>
    selectedRowKey.includes(data.employeeCode || "")
  );
  return employeeList;
};

export const getList = (
  attendanceType: string,
  inputType: InputManipulatedListType[]
) => {
  const type =
    attendanceType === DAILY_ATTENDANCE_TYPE_CODE
      ? DAILY_INDICATOR
      : HOULRY_INDICATOR;
  const list: {
    key: string;
    value: string;
  }[] = [];
  inputType.map(item => {
    if (item[type] === "Y") {
      const input = {
        key: item.inputTypeCode || "",
        value: item.inputTypeDesc || "",
      };
      list.push(input);
    }
  });
  return list;
};

export function column(
  employeeList: EmpAttendanceList[],
  dayValueList: DayValueList[],
  attendanceType: string,
  inputType: InputManipulatedListType[],
  onChangeData: (
    dropdownValue: string,
    inputValue: string,
    key: number,
    row: TableData
  ) => void
) {
  let dataColumns: {
    key: string;
    title: string;
    dataIndex: string;
  }[] = [];
  let col = {
    key: "sNo",
    title: "S.NO",
    dataIndex: "slNo",
    fixed: true,
  };
  dataColumns.push(col);
  col = {
    key: "eCode",
    title: "Employee Code",
    dataIndex: "employeeCode",
    fixed: true,
  };
  dataColumns.push(col);
  col = {
    key: "eName",
    title: "Employee Name",
    dataIndex: "employeeName",
    fixed: true,
  };
  dataColumns.push(col);
  let col1 = {
    key: "status",
    title: "Status",
    dataIndex: "statusDescription",
    fixed: true,
    render: (status: string, row: TableData) => {
      return (
        <Row style={{ color: getColor(row.statusCode || "") }}>
          {row.statusCode ? status : "Draft"}
        </Row>
      );
    },
  };
  dataColumns.push(col1);
  dayValueList.map((item, index) => {
    const eachDayData = {
      key: item.dayCaption?.toString() || "",
      title:
        `${moment(item.dateValueOutput).format("DD-MMM")} (${moment(
          item.dateValueOutput
        ).format("ddd")})` || "",
      dataIndex: `day${index + 1}InputValue`,
      render: (dayValue: string, row: TableData) => {
        const r: any = row;
        return (
          <BulkAttendanceInput
            menuList={getList(attendanceType, inputType)}
            getSelectedValue={(dropdownValue, inputValue) =>
              onChangeData(dropdownValue, inputValue, index + 1, row)
            }
            dropdownText={getDescription(dayValue, inputType) || text.SELECT}
            hour={r[`day${index + 1}InputUnits`]}
            inputType={attendanceType}
            shouldDisable={row.customEALTextOutput1 === VIEW_RECORD}
          />
        );
      },
    };
    dataColumns.push(eachDayData);
  });
  let col2 = {
    key: "totalDays",
    title: "Total Days",
    dataIndex: "totalDays",
    fixed: false,
    render: (days: number, row: TableData) => {
      return <Row type="flex" justify="center">{row.totalDays ? days : 0}</Row>;
    },
  };
  dataColumns.push(col2);
  col2 = {
    key: "totalLeaveDays",
    title: "Leaves",
    dataIndex: "totalLeaveDays",
    fixed: false,
    render: (days: number, row: TableData) => {
      return <Row type="flex" justify="center">{row.totalLeaveDays ? days : 0}</Row>;
    },
  };
  dataColumns.push(col2);
  col2 = {
    key: "totalPresentDays",
    title: "Present Days",
    dataIndex: "totalPresentDays",
    fixed: false,
    render: (days: number, row: TableData) => {
      return <Row type="flex" justify="center">{row.totalPresentDays ? days : 0}</Row>;
    },
  };
  dataColumns.push(col2);
  col2 = {
    key: "totalWeeklyOffs",
    title: "Weekly Off",
    dataIndex: "totalWeeklyOffs",
    fixed: false,
    render: (days: number, row: TableData) => {
      return <Row type="flex" justify="center">{row.totalWeeklyOffs ? days : 0}</Row>;
    },
  };
  dataColumns.push(col2);
  return dataColumns;
}

export const columnForExcel = (
  dayValueList: DayValueList[],
  attendanceType: string
) => {
  let dataColumns: {
    key: string;
    dataIndex: string;
  }[] = [];
  let col = {
    key: "S.NO",
    dataIndex: "slNo",
  };
  dataColumns.push(col);
  col = {
    key: "Employee Code",
    dataIndex: "employeeCode",
  };
  dataColumns.push(col);
  col = {
    key: "Employee Name",
    dataIndex: "employeeName",
  };
  dataColumns.push(col);
  dayValueList.map((item, index) => {
    const eachDayData = {
      key: item.dayCaption || "",
      dataIndex: `day${index + 1}InputValue`,
    };
    dataColumns.push(eachDayData);
    if (attendanceType === "H") {
      const temp = {
        key: `${item.dayCaption} - Units` || "",
        dataIndex: `day${index + 1}InputUnits`,
      };
      dataColumns.push(temp);
    }
  });
  return dataColumns;
};

export const getActionType = (activeKey: string) => {
  switch (activeKey) {
    case "1":
      return actionTypeCodes.ALL;
    case "2":
      return actionTypeCodes.DRAFT;
    case "3":
      return actionTypeCodes.NON_CONFIRMED;
    case "4":
      return actionTypeCodes.CONFIRMED;
  }
};

export const getKeyOfActionType = (activeKey: string) => {
  switch (activeKey) {
    case actionTypeCodes.ALL:
      return "1";
    case actionTypeCodes.DRAFT:
      return "2";
    case actionTypeCodes.NON_CONFIRMED:
      return "3";
    case actionTypeCodes.CONFIRMED:
      return "4";
    default:
      return "1";
  }
};
export function getInputCategory(
  inputType: string,
  metaData: BulkAttendanceManipulatedMetaData | undefined
) {
  const input = metaData?.data.inputTypeList.find(
    item => item.inputTypeCode === inputType
  );
  return input ? input.inputTypeCategory : null;
}

export function setCategory(
  detailList: EmpAttendanceList[],
  metaData: BulkAttendanceManipulatedMetaData | undefined
) {
  let newDetailsList: EmpAttendanceList[] = [];
  detailList.map(employee => {
    const record: any = employee;
    [...new Array(31)].forEach((item, index) => {
      record[`day${index + 1}InputCategory`] = getInputCategory(
        record[`day${index + 1}InputValue`] || "",
        metaData
      );
      if (disableInput(record[`day${index + 1}InputValue`]))
        record[`day${index + 1}InputUnits`] = null;
    });
    newDetailsList.push(record);
  });
  return newDetailsList;
}

export function disableInput(dropdownValue: string) {
  switch (dropdownValue) {
    case "CL~WDAY" || "LOP~WDAY" || "HOL" || "WOFF" || "EL~WDAY" || "SL~WDAY":
      return true;
    default:
      return false;
  }
}

export function getColor(statusCode: string) {
  switch (statusCode) {
    case actionTypeCodes.DRAFT:
      return "maroon";
    case actionTypeCodes.CONFIRMED:
      return "#31b549";
    case actionTypeCodes.NON_CONFIRMED:
      return "#fbb517";
    default:
      return "maroon";
  }
}

export function getDescription(
  inputCode: string,
  inputTypeList: InputManipulatedListType[]
) {
  const input = inputTypeList.find(item => item.inputTypeCode === inputCode);
  return input ? input.inputTypeDesc : "";
}

export const getInputCode = (
  value: string,
  inputList: InputManipulatedListType[]
) => {
  const input = inputList.find(item => item.inputTypeDesc === value);
  return input ? input.inputTypeCode : null;
};

export const getColumnName = (num: number) => {
  for (var ret = "", a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode((num % b) / a + 65) + ret;
  }
  return ret;
};

const hourlyData = (
  empAttendanceList: EmpAttendanceList[],
  inputTypeList: string[],
  inputList: InputManipulatedListType[]
) => {
  let validationIndex = 0;
  const validationArray: {
    col: string;
    validation: {};
  }[] = [];
  let dataColumns: {
    header: string;
    key: string;
    dataIndex: string;
  }[] = [];
  let col = {
    header: "S.NO",
    key: "S.NO",
    dataIndex: "slNo",
  };
  dataColumns.push(col);
  col = {
    header: "Employee Code",
    key: "Employee Code",
    dataIndex: "employeeCode",
  };
  dataColumns.push(col);
  col = {
    header: "Employee Name",
    key: "Employee Name",
    dataIndex: "employeeName",
  };
  dataColumns.push(col);
  [...new Array(31)].forEach((item, index) => {
    const record: any = empAttendanceList && empAttendanceList[0];
    const eachDayData = {
      header: `${moment(record ? record[`day${index + 1}Date`] : "").format(
        "DD-MMM"
      )}`,
      key: `${moment(record ? record[`day${index + 1}Date`] : "").format(
        "DD-MMM"
      )}`,
      dataIndex: `day${index + 1}InputValue`,
    };
    dataColumns.push(eachDayData);
    const temp = {
      header: `day${index + 1}Date - Units` || "",
      key: `day${index + 1}Date - Units` || "",
      dataIndex: `day${index + 1}InputUnits`,
    };
    dataColumns.push(temp);
    let validate = {
      col: getColumnName(validationIndex + 4),
      validation: {
        type: "list",
        allowBlank: false,
        showErrorMessage: true,
        errorStyle: "error",
        errorTitle: text.SELECT_CORRECT_VALUE,
        formulae: [`$CD$100:$CD${inputTypeList.length + 100}`],
      },
    };
    validationArray.push(validate);
    let validateHours = {
      col: getColumnName(validationIndex + 5),
      validation: {
        type: "decimal",
        operator: "between",
        allowBlank: true,
        showInputMessage: true,
        showErrorMessage: true,
        formulae: [0.0, 24.0],
        errorStyle: "error",
        errorTitle: text.HOURS_SHOULD_BE_BETWEEN_0_TO_24,
      },
    };
    validationIndex += 2;
    validationArray.push(validateHours);
  });
  const excelData: (string | number | undefined)[][] = [];
  empAttendanceList.map(item => {
    const eachRecord = [item.slNo, item.employeeCode, item.employeeName];
    const record: any = item;
    [...new Array(31)].forEach((eachDay, index) => {
      eachRecord.push(
        getDescription(record[`day${index + 1}InputValue`], inputList)
      );
      eachRecord.push(record[`day${index + 1}InputUnits`]);
    });
    excelData.push(eachRecord);
  });
  return {
    column: dataColumns,
    validation: validationArray,
    excelData: excelData,
  };
};

const dailyData = (
  empAttendanceList: EmpAttendanceList[],
  inputTypeList: string[],
  inputList: InputManipulatedListType[]
) => {
  const validationArray: {
    col: string;
    validation: {};
  }[] = [];
  let dataColumns: {
    header: string;
    key: string;
    dataIndex: string;
  }[] = [];
  let col = {
    header: "S.NO",
    key: "S.NO",
    dataIndex: "slNo",
  };
  dataColumns.push(col);
  col = {
    header: "Employee Code",
    key: "Employee Code",
    dataIndex: "employeeCode",
  };
  dataColumns.push(col);
  col = {
    header: "Employee Name",
    key: "Employee Name",
    dataIndex: "employeeName",
  };
  dataColumns.push(col);
  [...new Array(31)].forEach((item, index) => {
    const record: any = empAttendanceList && empAttendanceList[0];
    const eachDayData = {
      header: `${moment(record ? record[`day${index + 1}Date`] : "").format(
        "DD-MMM"
      )}`,
      key: `${moment(record ? record[`day${index + 1}Date`] : "").format(
        "DD-MMM"
      )}`,
      dataIndex: `day${index + 1}InputValue`,
    };
    dataColumns.push(eachDayData);
    const validate = {
      col: getColumnName(index + 4),
      validation: {
        type: "list",
        allowBlank: false,
        showErrorMessage: true,
        errorStyle: "error",
        errorTitle: text.SELECT_CORRECT_VALUE,
        formulae: [`$CD$100:$CD${inputTypeList.length + 100}`],
      },
    };
    validationArray.push(validate);
  });
  const excelData: (string | number | undefined)[][] = [];
  empAttendanceList.map(item => {
    const eachRecord = [item.slNo, item.employeeCode, item.employeeName];
    const record: any = item;
    [...new Array(31)].forEach((eachDay, index) => {
      eachRecord.push(
        getDescription(record[`day${index + 1}InputValue`], inputList)
      );
    });
    excelData.push(eachRecord);
  });
  return {
    column: dataColumns,
    validation: validationArray,
    excelData: excelData,
  };
};

export const getDownloadExcelData = (
  empAttendanceList: EmpAttendanceList[],
  attendanceType: string,
  inputTypeList: InputManipulatedListType[]
) => {
  const list = getValidationList(attendanceType, inputTypeList);
  if (attendanceType === HOURLY_ATTENDANCE_TYPE_CODE) {
    return hourlyData(empAttendanceList, list, inputTypeList);
  } else {
    return dailyData(empAttendanceList, list, inputTypeList);
  }
};

export const getValidationList = (
  attendanceType: string,
  inputType: InputManipulatedListType[]
) => {
  const type =
    attendanceType === DAILY_ATTENDANCE_TYPE_CODE
      ? DAILY_INDICATOR
      : HOULRY_INDICATOR;
  const list: Array<string> = [];
  inputType.map(item => {
    if (item[type] === "Y") {
      list.push(item.inputTypeDesc || "");
    }
  });
  return list;
};

export const getUpdatedValue = (value: any) => {
  return value.filter((item: any) =>
    item.day1Date ||
    item.day2Date ||
    item.day3Date ||
    item.day4Date ||
    item.day5Date ||
    item.day6Date ||
    item.day7Date ||
    item.day8Date ||
    item.day9Date ||
    item.day10Date ||
    item.day11Date ||
    item.day12Date ||
    item.day13Date ||
    item.day14Date ||
    item.day15Date ||
    item.day16Date ||
    item.day17Date ||
    item.day18Date ||
    item.day19Date ||
    item.day20Date ||
    item.day21Date ||
    item.day22Date ||
    item.day23Date ||
    item.day24Date ||
    item.day25Date ||
    item.day26Date ||
    item.day27Date ||
    item.day28Date ||
    item.day29Date ||
    item.day30Date ||
    item.day31Date,
  );
};