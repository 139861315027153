import { size } from "styles/Fonts";

export const defaultTitleStyle = { fontSize: size.medium, color: "#877a77" };
export const defaultTitleStyle2 = {
  fontSize: size.medium,
  color: "#877a77",
};
export const defaultStylePending = {
  fontSize: size.large,
  color: "#fbb517",
};

export const defaultStyleAuthorized = {
  fontSize: size.large,
  color: "#02af9b",
};
