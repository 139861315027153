import { AssociatesReimbursementResponse } from "models/reimbursement.data";
import { put, takeLatest } from "redux-saga/effects";
import ReimbursementService from "./ReimbursementService";
import { ReimbursementActions } from "./actions";

function* getAssociatesReimbursementData(action: any) {
  const { payload } = action;
  try {
    const response: AssociatesReimbursementResponse = yield ReimbursementService.getAssociateReimbursement(
      payload,
    );
    yield put(
      ReimbursementActions.getAssociatesReimbursementDetails.done({
        params: payload,
        result: response,
      }),
    );
  } catch (e) {
    yield put(
      ReimbursementActions.getAssociatesReimbursementDetails.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* ReimbursementManagementSaga() {
  yield takeLatest(
    ReimbursementActions.getAssociatesReimbursementDetails.started,
    getAssociatesReimbursementData,
  );
}

export default ReimbursementManagementSaga;
