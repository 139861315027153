import { ReporteesList, Reportees } from "models/associate.data";
import actionCreatorFactory from "typescript-fsa";
import { Error } from "store/Models";

const actionCreator = actionCreatorFactory("REPORTEE");

export class AssociateActions {
  public static getReporteesListList = actionCreator.async<
    void,
    Reportees[],
    Error
  >("GET_REPORTEE_LIST");
}
