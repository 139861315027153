import { combineReducers } from "redux";
import { AuthReducer } from "./auth/AuthReducer";
import { AuthState } from "./model/ReduxStates.data";

import LeaveReducer from "screens/leave/reducer";
import { AssociateReducer } from "../screens/associate";
import { ProfileReducer } from "../screens/profile/store";
import TimesheetReducer from "screens/timesheet/reducer";
import ReimbursementReducer from "screens/reimbursement/reducer";
import { DashboardReducer } from "../screens/dashboard";
import { ContextReducer } from "../screens/home/store";
import { AssociateLeaveDetailsStateType } from "models/leave.data";
import { AssociateReimbursementDetailsStateType } from "models/reimbursement.data";
import { AssociateTimesheetDetailsStateType } from "models/timesheet.data";
import { ProfileDetailStateType } from "models/profile.data";
import { DasboardStateType } from "models/summaryApis.data";
import { ContextType } from "models/userGontext.data";
import { RepoteesListStateType } from "models/associate.data";

export interface ApplicationState {
  auth: AuthState;
  leaveStore: AssociateLeaveDetailsStateType;
  contextStore: ContextType;
  associateStore: RepoteesListStateType;
  profileStore: ProfileDetailStateType;
  timesheetStore: AssociateTimesheetDetailsStateType;
  reimbursementStore: AssociateReimbursementDetailsStateType;
  dashboardStore: DasboardStateType;
}

const rootReducer = combineReducers<ApplicationState>({
  auth: AuthReducer,
  leaveStore: LeaveReducer,
  contextStore: ContextReducer,
  associateStore: AssociateReducer,
  profileStore: ProfileReducer,
  timesheetStore: TimesheetReducer,
  reimbursementStore: ReimbursementReducer,
  dashboardStore: DashboardReducer,
});

const appReducer = (state: ApplicationState | undefined, action: any) => {
  if (action.type === "ON_LOGOUT") state = undefined;
  return rootReducer(state, action);
};

export default appReducer;
