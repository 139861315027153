import { createSelector } from "reselect";

import { AuthState, AUTH_STATUS } from "../model/ReduxStates.data";
import { AuthResponse } from "../model/Auth.data";
import { AuthActionTypes, AuthAction, AuthActions } from "./AuthActions";

export const initialState: AuthState = {
  loading: false,
  error: null,
  authDetails: undefined,
  authStatus: AUTH_STATUS.PENDING,
  userProjects: ["Manipal", "Adecco", "Unisys"],
};

export function AuthReducer(
  state = initialState,
  action: AuthAction,
): AuthState {
  switch (action.type) {
    case AuthActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
        authDetails: undefined,
      };
    case AuthActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        authDetails: action.payload as AuthResponse,
        authStatus: AUTH_STATUS.SUCCESS,
      };
    case AuthActionTypes.LOGIN_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload as Error,
        authDetails: undefined,
        authStatus: AUTH_STATUS.FAIL,
      };
    case AuthActions.checkUserValidity.done.type:
      return { ...state, authStatus: AUTH_STATUS.SUCCESS };
    case AuthActions.checkUserValidity.failed.type:
      return { ...state, authStatus: AUTH_STATUS.FAIL };

    case AuthActions.logoutUserAction.done.type:
      return { ...state, authStatus: AUTH_STATUS.FAIL };
    case AuthActions.logoutUserAction.failed.type:
      return { ...state, authStatus: AUTH_STATUS.SUCCESS };
    default:
      return state;
  }
}

const authSelector = (store: { auth: AuthState }) => store.auth;

export const isAuthPending = createSelector(authSelector, state => {
  return state.authStatus === AUTH_STATUS.PENDING;
});
export const isAuthFailed = createSelector(authSelector, state => {
  return state.authStatus === AUTH_STATUS.FAIL;
});
export const isAuthSuccess = createSelector(authSelector, state => {
  return state.authStatus === AUTH_STATUS.SUCCESS;
});

export const userProjects = createSelector(authSelector, state => {
  return state.userProjects;
});
