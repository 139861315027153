import React, { useState } from "react";
import { Row, Col, Typography, Button, Divider, Checkbox } from "antd";
import text from "text";
import { CardLayout } from "components/AssociateCard/CardLayout";
import { ISalaryProps } from "./type";
import {
  InputFieldStyle,
  TextColorStyle,
  employeeNameStyle,
  appliedSalaryTimeStyle,
} from "./SalaryCardStyle";
import { getColor } from "./helper";
import moment from "moment";
import RejectModal from "./RejectModal";
import { AUTH } from "screens/advanceSalary/constant";
const { Text } = Typography;

export default function AdvanceSalaryCard(props: ISalaryProps) {
  const {
    data,
    select,
    selectedItems,
    onAuthRej,
    approveLoader,
    rejectLoader,
    onChangeRejectionReason,
    onModalClose,
    reason,
  } = props;

  const [openModal, setOpenModal] = useState(false);

  const onApprove = () => {
    onAuthRej(AUTH, data);
  };

  const onReject = () => {
    setOpenModal(true);
  };

  const getNoOfDays = (requestDate: string) => {
    const requestedApiDate = moment(requestDate);
    const currentDate = new Date();
    const diff = moment(currentDate).diff(requestedApiDate, "days");
    return `${diff} ${diff > 1 ? "Days" : "Day"}`;
  };

  const isDisabled = () => {
    return data.status !== "P" || rejectLoader || approveLoader;
  };

  const onCancel = () => {
    setOpenModal(false);
    onModalClose();
  };

  return (
    <>
      {openModal &&
        <RejectModal
          data={data}
          loading={rejectLoader}
          onChangeReason={onChangeRejectionReason}
          onReject={onAuthRej}
          reason={reason}
          onCancel={onCancel}
          showModal={openModal}
        />
      }
      <CardLayout>
        {data.status === "P" &&
          <Checkbox
            onChange={() => select(data)}
            className="align-self-end"
            checked={selectedItems[data.id as number]}
          />}
        <Row type="flex" justify="space-between">
          <Col>
            <Row>
              <Text style={employeeNameStyle}>{data.empName}</Text>
            </Row>
            <Row>
              <Text style={TextColorStyle}>{data.empCode}</Text>
            </Row>
          </Col>
          <Col className="py-3">
            <Row type="flex" justify="space-between" align="middle">
              <Text className="mx-2 fw-600" style={appliedSalaryTimeStyle}>
                {`${text.APPLIED} ${getNoOfDays(data.applicationDate)} ${text.AGO}`}
              </Text>
              <Text
                className="px-2 py-1"
                style={{
                  border: `1px solid ${getColor(data.status)}`,
                  color: `${getColor(data.status)}`,
                }}
              >
                {data.position}
              </Text>
            </Row>
          </Col>
        </Row>
        <Divider className="m-2" />
        <Row type="flex" justify="space-between">
          <Col span={4} lg={5} md={14} xs={16}>
            <Row>
              <Text>{text.EMPLOYEE_CODE}</Text>
            </Row>
            <Row className="my-2 p-2" style={InputFieldStyle}>
              <Text>{data.empCode || text.NOT_APPLICABLE}</Text>
            </Row>
          </Col>
          <Col span={4} lg={5} md={14} xs={16}>
            <Row>
              <Text>{text.AMOUNT_REQUESTED}</Text>
            </Row>
            <Row className="my-2 p-2" style={InputFieldStyle}>
              <Text>{data.loanAmount}</Text>
            </Row>
          </Col>
          <Col span={4} lg={5} md={14} xs={16}>
            <Row>
              <Text>{text.NO_OF_INSTALLMENTS}</Text>
            </Row>
            <Row className="my-2 p-2" style={InputFieldStyle}>
              <Text>{data.desNoofIntInstallments}</Text>
            </Row>
          </Col>
          <Col span={4} lg={5} md={14} xs={16}>
            <Row>
              <Text>{text.REMARKS}</Text>
            </Row>
            <Row className="my-2 p-2" style={InputFieldStyle}>
              <Text>{data.additionalComments || "N/A"}</Text>
            </Row>
          </Col>
        </Row>
        <Row className="py-2" type="flex" justify="end">
          <Button
            className={
              isDisabled()
                ? "px-6 fw-bold mr-2"
                : "c-white px-6 fw-bold bg-success mr-2"
            }
            onClick={onApprove}
            disabled={isDisabled()}
          >
            {text.APPROVE}
          </Button>
          <Button
            className={
              isDisabled()
                ? "px-6 fw-bold"
                : "c-white px-6 fw-bold bg-primary"
            }
            onClick={onReject}
            disabled={isDisabled()}
          >
            {text.REJECT}
          </Button>
        </Row>
      </CardLayout>
    </>
  );
}
