import React, { useEffect, useState } from "react";
import { Row, Card } from "antd";
import DashboardCard from "components/DashboardCard/DashboardCard";
import { CardItem, leaveData } from "screens/dashboard/constants";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { DashboardActions } from "screens/dashboard/actions";
import { useHistory } from "react-router-dom";
import { leaveImage } from "AssetHelper";
import { SummaryResponse, SummaryDetails } from "models/dashboard.data";
import Loader from "./Loader";
import { defaultClientLeaveEndDate, defaultClientLeaveStartDate, dateFormat } from "date";

export default function LeaveCards() {
  const [displayData] = useState(leaveData);
  const [data, setData] = useState();

  const summary = useSelector((state: any) => {
    return state.dashboardStore;
  }, shallowEqual);

  const dispatch = useDispatch();

  const getLeaveSummary = () => {
    const updatedLeaveSummary: any = {};
    summary.dashboardSummary.leaveSummary.data.map(
      (item: SummaryDetails, index: number) => {
        updatedLeaveSummary[item.statusCode] = item.recordCount || 0;
      },
    );
    return updatedLeaveSummary;
  };

  const getLeaveSummaryData = () => {
    const requestData = {
      internalRole: "APR",
      endDate: defaultClientLeaveEndDate.format(dateFormat),
      startDate: defaultClientLeaveStartDate.format(dateFormat),
    };
    dispatch(DashboardActions.getLeaveSummary.started(requestData));
  };

  useEffect(() => {
    getLeaveSummaryData();
  }, []);

  useEffect(() => {
    const updatedSummary = getLeaveSummary();
    setData(updatedSummary);
  }, [summary.dashboardSummary.leaveSummary]);

  const history = useHistory();

  const onClickCard = (value: string) => {
    history.push({
      pathname: "/home/leave",
      search: `status=${value}`,
    });
  };

  const renderCards = (
    title: string,
    value: string,
    icon: string,
    color: string,
    index: number,
  ) => {
    return (
      <div
        className={`card-${title.replace(/ /g, "_")} w-100 w-md-auto`}
        onClick={() => onClickCard(value)}>
        <DashboardCard
          title={title}
          value={data && data[`${value}`]}
          icon={icon}
          valueColor={color}
          hoverable={true}
        />
      </div>
    );
  };

  return (
    <div className={`p-2 Leave`}>
      <Row className="p-2" type="flex" justify="start">
        <img src={leaveImage} />
        <h4 className="p-2 my-1 ff-secondary">{"Leave"}</h4>
      </Row>
      <Row type="flex" justify="start">
        {summary.leaveLoading ? (
          <div className="w-50 py-3">
            <Loader />
          </div>
        ) : (
          displayData.map((cardItem: CardItem, index: number) =>
            renderCards(
              cardItem.title,
              cardItem.value,
              cardItem.icon,
              cardItem.color,
              index,
            ),
          )
        )}
      </Row>
    </div>
  );
}
