import {
  AssociateReimbursementDetailsStateType,
  AssociatesReimbursementResponse,
} from "models/reimbursement.data";

import { reducerWithInitialState } from "typescript-fsa-reducers";

import { ReimbursementActions } from "./actions";

const initialState: AssociateReimbursementDetailsStateType = {
  loading: false,
  error: null,
  Approverdetails: {
    data: {
      ApproverDetails: null,
      pageIndex: 1,
      pageSize: 1,
      totalSize: 1,
    },
    message: "",
  },
};

const reimbursementReducer = reducerWithInitialState(initialState)
  .cases(
    [ReimbursementActions.getAssociatesReimbursementDetails.started],
    (state: AssociateReimbursementDetailsStateType) => ({
      ...state,
      error: null,
      loading: true,
    }),
  )
  .case(
    ReimbursementActions.getAssociatesReimbursementDetails.done,
    (
      state: AssociateReimbursementDetailsStateType,
      payload: { result: AssociatesReimbursementResponse },
    ) => ({
      ...state,
      error: null,
      loading: false,
      Approverdetails: payload.result,
    }),
  )

  .default(state => {
    return state;
  });

export default reimbursementReducer;
