import React, { useState, useEffect } from "react";
import { Card, Row, Col, Skeleton, Modal, Table, Button } from "antd";
import text from "text";
import {
  defaultTitleStyle,
  defaultStyleAuthorized,
  defaultStylePending,
  defaultTitleStyle2,
} from "./constant";
import { useOnboardingDetailsList } from "screens/analytics/hooks";
import { queryCache } from "react-query";
import AnalyticsScreenModal from "components/AnalyticsScreenModal/AnalyticsScreenModal";
import {
  AnalyticsApiCodeTypes,
  CLMGR_INTERNAL_ROLE,
} from "screens/analytics/constant";
import { IMultipleSummaryItemsProps } from "screens/analytics/type";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/RootReducer";
import { CLI_INTERNAL_ROLE } from "screens/bulkAttendance/constant";

export default function MultipleSummaryCard(props: IMultipleSummaryItemsProps) {
  const [modalVisible, setModalVisibility] = useState(false);
  const [selectedItem, setSelectedItem] = useState<AnalyticsApiCodeTypes>(
    AnalyticsApiCodeTypes.ANALYTICS_ONBOARDING_BGV_INPROGRESS
  );
  const { contextStore } = useSelector((state: ApplicationState) => state);

  const isInternalRoleClient = contextStore.userDetails.data.find(
    (item: any) => item.internalRole === CLI_INTERNAL_ROLE
  )
    ? true
    : false;

  const [statusCodeSearch, setStatusCodeSearch] = useState<string>(text.VAL);
  const {
    isFetching: isRightSummaryLoading,
    data: cardRightAnalytics,
  } = useOnboardingDetailsList(props.cardDetail.items[1]?.endpoint, {
    toDate: props.toDate,
    fromDate: props.fromDate,
    internalRole: isInternalRoleClient ? CLI_INTERNAL_ROLE :CLMGR_INTERNAL_ROLE,
    queryKey: props.cardDetail.items[1]?.endpoint,
    clraStatusCodeSearch: props.cardDetail.items[1]?.clraStatusCode,
  });

  const {
    isFetching: isLeftSummaryLoading,
    data: cardLeftAnalytics,
  } = useOnboardingDetailsList(props.cardDetail.items[0]?.endpoint, {
    toDate: props.toDate,
    fromDate: props.fromDate,
    internalRole: isInternalRoleClient ? CLI_INTERNAL_ROLE :CLMGR_INTERNAL_ROLE,
    queryKey: props.cardDetail.items[0]?.endpoint,
    clraStatusCodeSearch: props.cardDetail.items[0]?.clraStatusCode,
  });

  const clearQueryCache = () => {
    props.cardDetail.items.map(item => {
      queryCache.removeQueries(item.endpoint);
    });
  };

  useEffect(() => {
    return clearQueryCache;
  }, []);

  const onLeftClick = (heading: string) => {
    switch (heading) {
      case text.BGV:
        setSelectedItem(props.cardDetail.items[0].apiCode);
        setModalVisibility(true);
        return;

      case text.CLIENT_CLRA_STATUS:
        setSelectedItem(props.cardDetail.items[0].apiCode);
        setStatusCodeSearch(props.cardDetail.items[0].clraStatusCode!);
        setModalVisibility(true);
        return;
    }
  };

  const onRightClick = (heading: string) => {
    switch (heading) {
      case text.BGV:
        setSelectedItem(props.cardDetail.items[1].apiCode);
        setModalVisibility(true);
        return;

      case text.CLIENT_CLRA_STATUS:
        setSelectedItem(props.cardDetail.items[1].apiCode);
        setStatusCodeSearch(props.cardDetail.items[1].clraStatusCode!);
        setModalVisibility(true);
        return;
    }
  };

  const onItemClick = () => {
    setModalVisibility(false);
  };

  const getItemDetails = () => {
    return (
      props.cardDetail.items.find(val => val.apiCode === selectedItem) ||
      props.cardDetail.items[0]
    );
  };

  const GetLeftCardValue = (heading: string) => {
    switch (heading) {
      case text.BGV:
        return cardLeftAnalytics?.data.totalCount;
      case text.CLIENT_CLRA_STATUS:
        return cardLeftAnalytics?.data.totalValidCount;
    }
  };

  const GetRightCardValue = (heading: string) => {
    switch (heading) {
      case text.BGV:
        return cardRightAnalytics?.data.totalCount;
      case text.CLIENT_CLRA_STATUS:
        return cardRightAnalytics?.data.totalInvalidCount;
    }
  };

  const renderContent = () => {
    return (
      <Row type="flex" justify="space-between">
        <Col span={10}>
          <Row style={defaultTitleStyle2} className="ff-secondary">
            {props.cardDetail.items[0].subTitle}
          </Row>
          <Row
            style={defaultStylePending}
            className="ff-secondary cursor-pointer"
            onClick={() => onLeftClick(props.cardDetail.heading)}>
            {isLeftSummaryLoading ? (
              <Skeleton paragraph={{ rows: 0 }} active={true} loading={true} />
            ) : (
              GetLeftCardValue(props.cardDetail.heading)
            )}
          </Row>
        </Col>
        <Col style={{ borderRight: "1px solid #877a77" }}/>
        <Col span={10} >
          <Row style={defaultTitleStyle2} className="ff-secondary">
            {props.cardDetail.items[1].subTitle}
          </Row>
          <Row
            style={defaultStyleAuthorized}
            className="ff-secondary cursor-pointer"
            onClick={() => onRightClick(props.cardDetail.heading)}>
            {isRightSummaryLoading ? (
              <Skeleton paragraph={{ rows: 0 }} active={true} loading={true} />
            ) : (
              GetRightCardValue(props.cardDetail.heading)
            )}
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <Card className="m-1 w-100 w-md-24  min-vh-15">
        <Row type="flex" className="w-100">
          <Col span={6}>
            <img src={props.cardDetail.icon} width="40" />
          </Col>
          <Col span={18}>
            <Row style={defaultTitleStyle} className="ff-secondary pb-1">
              {props.cardDetail.heading}
            </Row>
            {renderContent()}
          </Col>
        </Row>
      </Card>
      {modalVisible && (
        <AnalyticsScreenModal
          title={props.cardDetail.heading === "CLRA STATUS" ? `${props.cardDetail.heading}` : `${props.cardDetail.heading} ${getItemDetails().subTitle}`}
          tableEndpoint={getItemDetails().tableEndpoint}
          onItemClick={onItemClick}
          modalVisible={modalVisible}
          toDate={props.toDate}
          fromDate={props.fromDate}
          apiCode={getItemDetails().apiCode}
          statusCodeSearch={statusCodeSearch}
        />
      )}
    </>
  );
}
