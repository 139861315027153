import { ITabMenu } from "./type";
import text from "text";

export const actionTypeCodes = {
  ALL: "ALL",
  CONFIRMED: "CON",
  NON_CONFIRMED: "NCON",
  DRAFT: "DRFT",
};

export const VIEW_RECORD = "View";
export const EDIT_RECORD = "Edit";

export const tabsMenu: ITabMenu[] = [
  {
    key: "1",
    tabName: text.ALL,
    isDisabled: false,
    dataKey: actionTypeCodes.ALL,
  },
  {
    key: "2",
    tabName: text.DRAFT,
    isDisabled: false,
    dataKey: actionTypeCodes.DRAFT,
  },
  {
    key: "3",
    tabName: text.NOT_CONFIRMED,
    isDisabled: true,
    dataKey: actionTypeCodes.NON_CONFIRMED,
  },
  {
    key: "4",
    tabName: text.CONFIRMED,
    isDisabled: true,
    dataKey: actionTypeCodes.CONFIRMED,
  },
];

export const CLI_INTERNAL_ROLE = "CLI";
export const CLMGR_INTERNAL_ROLE = "CLMGR";
export const DAILY_ATTENDANCE_TYPE_CODE = "D";
export const HOURLY_ATTENDANCE_TYPE_CODE = "H";
export const DAILY_INDICATOR = "dailyApplicability";
export const HOULRY_INDICATOR = "hourlyApplicability";
export const YES_CODE = "Y";
export const NO_CODE = "N";
