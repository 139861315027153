import React, { useState } from "react";
import { Row, Col, Typography, Button, Divider, Modal } from "antd";
import text from "text";
import { CardLayout } from "components/AssociateCard/CardLayout";
import { IResignationProps, WavierList } from "./type";
import { PDFImage } from "AssetHelper";
import {
  InputFieldStyle,
  TextColorStyle,
  employeeNameStyle,
  durationStyle,
} from "./ResignationStyle";
import { getColor } from "./helper";
import ConfirmationModal from "./ConfirmationModal";
import { wavierList, statusList } from "./constant";
import moment from "moment";
import { ModalPopUp } from "components/Modal/ModalPopUp";
import { ModalType } from "components/Modal/type";
import { getPDFURI } from "common/Utils.tsx/Download";
import { dateCodes } from "date";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";

const { Text, Paragraph } = Typography;

export default function ResignationCard(props: IResignationProps) {
  const {
    data,
    onChangeWavier,
    onApprove,
    isApproveLoading,
    isRejectLoading,
    employerReason,
    onChangeEmployerReason,
    onChangeNoDueConfirmation,
    onChangeApproverComments,
    onChangeRemarks,
    onReject,
    onModalClose,
  } = props;

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [actionType, setActionType] = useState("");

  const onSubmit = (value: string) => {
    setActionType(value);
    if (value === text.APPROVE && data.noticePeriodWaiverFlagCode === ""){
      ModalPopUp({
        type: ModalType.warning,
        title: text.ALERT.toUpperCase(),
        description: text.REQUIRED_NOTICE_PERIOD_WAVIER,
      });
      return;
    }
    setShowConfirmationModal(true);
  };

  const onCancel = () => {
    setShowConfirmationModal(false);
    onModalClose(data.separationNumber);
  };

  const renderConfirmationModal = () => {
    return (
      <Modal
        visible={showConfirmationModal}
        footer={null}
        onCancel={onCancel}
        closable={false}
        width={450}>
        <ConfirmationModal
          employerReason={employerReason}
          isApproveLoading={isApproveLoading}
          isRejectLoading={isRejectLoading}
          actionType={actionType}
          data={data}
          onChangeEmployerReason={onChangeEmployerReason}
          onChangeNoDueConfirmation={onChangeNoDueConfirmation}
          onApprove={onApprove}
          onChangeRemarks={onChangeRemarks}
          onReject={onReject}
          onChangeApproverComments={onChangeApproverComments}
        />
      </Modal>
    );
  };

  const getNoOfDays = (requestDate: string) => {
    const requestedApiDate = moment(requestDate);
    const currentDate = new Date();
    const diff = moment(currentDate).diff(requestedApiDate, "days");
    return `${diff} ${diff > 1 ? "Days" : "Day"}`;
  };

  const downloadFile = async (fileName: string) => {
    try {
      if (!fileName) {
        ModalPopUp({
          type: ModalType.error,
          title: text.ALERT.toUpperCase(),
          description: text.NO_FILE,
        });
        return;
      }
      await getPDFURI(fileName, "Document/EI").then(response => {
        if (response !== 200) {
          ModalPopUp({
            type: ModalType.error,
            title: text.ALERT.toUpperCase(),
            description: text.NOT_ABLE_TO_DOWNLOAD,
          });
        }
      });
    } catch (err) {
    } finally {
    }
  };

  return (
    <>
      {renderConfirmationModal()}
      <CardLayout>
        <Row type="flex" justify="space-between">
          <Col>
            <Row>
              <Text style={employeeNameStyle}>{data.employeeName}</Text>
            </Row>
            <Row>
              <Text style={TextColorStyle}>{data.jobTitle}</Text>
            </Row>
          </Col>
          <Col className="py-3">
            <Row type="flex" justify="space-between" align="middle">
              <Text className="mx-2 fw-600" style={durationStyle}>
                {`${text.APPLIED} ${getNoOfDays(data.requestDate)} ${text.AGO}`}
              </Text>
              <Text
                className="px-5 py-1"
                style={{
                  border: `1px solid ${getColor(data.separationStatusCode)}`,
                  color: `${getColor(data.separationStatusCode)}`,
                }}>
                {statusList.map(item =>
                  item.statusCode === data.separationStatusCode
                    ? item.statusDesc
                    : ""
                )}
              </Text>
            </Row>
          </Col>
        </Row>
        <Divider className="m-2" />
        <Row type="flex" justify="space-between">
          <Col span={4} lg={5} md={14} xs={16}>
            <Row>
              <Text style={TextColorStyle}>{text.SYSTEM_GENERATED_DATE}</Text>
            </Row>
            <Row className="my-2 p-2" style={InputFieldStyle}>
              <Text>
                {moment(data.lastAvailableDateSysComp).format(
                  dateCodes.STD_DATE
                )}
              </Text>
            </Row>
          </Col>
          <Col span={4} lg={5} md={14} xs={16}>
            <Row>
              <Text style={TextColorStyle}>
                {text.REQUESTED_LAST_WORKING_DATE}
              </Text>
            </Row>
            <Row className="my-2 p-2" style={InputFieldStyle}>
              <Text>
                {moment(data.requestedRelievingDate).format(dateCodes.STD_DATE)}
              </Text>
            </Row>
          </Col>
          <Col span={4} lg={5} md={14} xs={16}>
            <Row>
              <Text style={TextColorStyle}>{text.REMARKS}</Text>
            </Row>
            <Row className="my-2 p-2" style={InputFieldStyle}>
              <Text>{data.employeeComments || text.NOT_APPLICABLE}</Text>
            </Row>
          </Col>
          <Col span={4} lg={5} md={14} xs={16}>
            <Row>
              <Text style={TextColorStyle}>{text.NOTICE_PERIOD_WAIVER}</Text>
            </Row>
            <Row className="mt-2">
              <SearchableDropdown<WavierList>
                list={wavierList}
                onSelect={e =>
                  onChangeWavier(e as WavierList, data.separationNumber)
                }
                optionLableExtractor={item => item.wavierDesc}
                optionKeyExtractor={item => item.wavierCode}
                value={data.noticePeriodWaiverFlag}
                width={225}
                isDisabled={!(data.separationStatusCode === "P")}
              />
            </Row>
          </Col>
        </Row>
        <Row className="my-2">
          <Text style={TextColorStyle}>{text.DOCUMENTS}</Text>
        </Row>
        <Row type="flex">
          <Row
            style={{ wordBreak: "break-word" }}
            onClick={() => downloadFile(data.attachDocFileName1)}
            className="c-info mr-3 cursor-pointer">
            <img src={PDFImage} className="h-8 mr-1" />
            {data.attachDocFileName1 || text.NOT_APPLICABLE}
          </Row>
          <Row
            style={{ wordBreak: "break-word" }}
            onClick={() => downloadFile(data.attachDocFileName2)}
            className="c-info mr-3 cursor-pointer">
            <img src={PDFImage} className="h-8 mr-1" />
            {data.attachDocFileName2 || text.NOT_APPLICABLE}
          </Row>
        </Row>
        <Text className="mt-6 mb-2" style={TextColorStyle}>
          {text.REASON_FOR_RESIGNATION}
        </Text>
        <Row className="my-2">
          <Paragraph className="p-2" style={InputFieldStyle}>
            {data.employeeReason || text.NOT_APPLICABLE}
          </Paragraph>
        </Row>
        <Row className="py-5 w-100" type="flex" justify="end">
          <Button
            title={text.REJECT}
            disabled={data.separationStatusCode !== "P"}
            className={
              data.separationStatusCode !== "P"
                ? "bg-disabledRed c-white px-6 mx-2"
                : "bg-primary c-white px-6 mx-2"
            }
            onClick={() => onSubmit(text.REJECT)}>
            {text.REJECT}
          </Button>
          <Button
            disabled={data.separationStatusCode !== "P"}
            className={
              data.separationStatusCode !== "P"
                ? "bg-disabledGreen c-white px-6 mx-2"
                : "bg-success c-white px-6 mx-2"
            }
            onClick={() => onSubmit(text.APPROVE)}>
            {text.APPROVE}
          </Button>
        </Row>
      </CardLayout>
    </>
  );
}
