import React from "react";

export const CardLayout: React.FC = ({ children }) => {
  return (
    // <a className="c-black" href="#!">
    <div
      style={{
        borderRadius: "3px",
        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.12)",
        height: "100%",
      }}
      className="p-3 pb-2 bg-white d-flex flex-column justify-content-between">
      {children}
    </div>
    // </a>
  );
};
