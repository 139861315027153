import {
  ProfileDetailStateType,
  PersonalInformationDetails,
  EmployeeFamilyMemberDetails,
  WorkExperienceDetails,
  QualificationDetails,
  ContactsDetails,
  UserDetails,
} from "../../../../models/profile.data";

import { reducerWithInitialState } from "typescript-fsa-reducers";
import { ProfileActions } from "./actions";

const initialState: ProfileDetailStateType = {
  loading: false,
  error: null,
  contacts: { data: {}, message: "" },
  qualifications: { data: {}, message: "" },
  familyMembers: { data: {}, message: "" },
  personalInformation: { data: [], message: "" },
  workExperience: { data: {}, message: "" },
  user: { data: [], message: "" },
};
const profileReducer = reducerWithInitialState(initialState)
  .cases(
    [
      ProfileActions.getPersonalInformation.started,
      ProfileActions.getFamilyMembers.started,
      ProfileActions.getQualifications.started,
      ProfileActions.getWorkExperience.started,
      ProfileActions.getContacts.started,
    ],
    (state: ProfileDetailStateType) => ({
      ...state,
      error: null,
      loading: true,
    }),
  )
  .case(ProfileActions.getUser.started, (state: ProfileDetailStateType) => {
    return {
      ...state,
      error: null,
      loading: true,
    };
  })

  .case(
    ProfileActions.getPersonalInformation.done,
    (
      state: ProfileDetailStateType,
      payload: { result: PersonalInformationDetails },
    ) => {
      return {
        ...state,
        error: null,
        loading: false,
        personalInformation: payload.result,
      };
    },
  )
  .case(
    ProfileActions.getFamilyMembers.done,
    (
      state: ProfileDetailStateType,
      payload: { result: EmployeeFamilyMemberDetails },
    ) => {
      return {
        ...state,
        error: null,
        loading: false,
        familyMembers: payload.result,
      };
    },
  )
  .case(
    ProfileActions.getQualifications.done,
    (
      state: ProfileDetailStateType,
      payload: { result: QualificationDetails },
    ) => {
      return {
        ...state,
        error: null,
        loading: false,
        qualifications: payload.result,
      };
    },
  )
  .case(
    ProfileActions.getWorkExperience.done,
    (
      state: ProfileDetailStateType,
      payload: { result: WorkExperienceDetails },
    ) => {
      return {
        ...state,
        error: null,
        loading: false,
        workExperience: payload.result,
      };
    },
  )
  .case(
    ProfileActions.getContacts.done,
    (state: ProfileDetailStateType, payload: { result: ContactsDetails }) => {
      return {
        ...state,
        error: null,
        loading: false,
        contacts: payload.result,
      };
    },
  )
  .case(
    ProfileActions.getUser.done,
    (state: ProfileDetailStateType, payload: { result: UserDetails }) => {
      return {
        ...state,
        error: null,
        loading: false,
        user: payload.result,
      };
    },
  )
  .default(state => {
    return state;
  });

export default profileReducer;
