import { ApproveExpense } from "models/reimbursement.data";
import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { AssociatesReimbursementResponse } from "models/reimbursement.data";
import { ExpenseFilterInterface } from "./type";
import { ItemDetails, requestDataForItem } from "models/itemDetails.data";

export class ReimbursementService {
  public getAssociateReimbursement(
    params: ExpenseFilterInterface,
  ):
    | Promise<AssociatesReimbursementResponse>
    | AssociatesReimbursementResponse {
    const service: ApiService = new ApiService(EndPoints.reimbursement);
    return service.get({ dynamicQueryParams: [{ ...params }] });
  }

  public approveExpense(
    request: ApproveExpense[],
  ):
    | Promise<AssociatesReimbursementResponse>
    | AssociatesReimbursementResponse {
    const service: ApiService = new ApiService(EndPoints.approveExpense);
    return service.post({}, request);
  }

  public getReimbursementDetails(
    request: requestDataForItem,
  ):
    | Promise<ItemDetails>
    | ItemDetails {
    const service: ApiService = new ApiService(EndPoints.ITEM_DETAILS);
    console.log()
    return service.get({dynamicQueryParams:[{...request}]});
  }
}

const reimbursementService = new ReimbursementService();
export default reimbursementService;
