import { LoanPostBody, LoanPostResponse, SalaryListResponse, SummaryData } from "models/advanceSalary.data";
import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { GetRequestParams } from "./type";

export class LoanService {
  public getSummary(requestData: GetRequestParams) :
   Promise<SummaryData> | SummaryData {
    const service: ApiService = new ApiService(
      EndPoints.GET_LOAN_SUMMARY_DETAILS,
    );
    return service.get({
      dynamicQueryParams: [{
        toDateSearch: requestData.endDate,
        fromDateSearch: requestData.startDate,
      }],
    });
  }

  public getDetailList(requestData: GetRequestParams) :
   Promise<SalaryListResponse> | SalaryListResponse {
    const service: ApiService = new ApiService(
      EndPoints.GET_LOAN_DETAILS_LIST,
    );
    return service.get({
      dynamicQueryParams: [{
        loanApplicationPeriodEndDate : requestData.endDate,
        loanApplicationPeriodFromDate: requestData.startDate,
        loanStatusCode: requestData.statusType,
      }],
    });
  }

  public approveRejectLoan(
    request: LoanPostBody,
  ): Promise<LoanPostResponse> | LoanPostResponse {
    const service: ApiService = new ApiService(EndPoints.POST_AUTH_REJ_LOAN);
    return service.post({}, request);
  }

}

export default new LoanService();
