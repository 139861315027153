import ApiService from "service/RootApiService";
import { EndPoints, DynamicQueryPath } from "store/model/ApiConfig.data";
import {
  PayrollMetaDataDetails,
  PostPayrollRequestBody,
  PostPayrollResponse,
  GetPayrollResponse,
} from "models/otherInput.data";
import { GetPayrollListParams, GetPayrollMetaDataParams } from "./type";
import { IFileUploadResponse } from "models/uploadDoc.data";

export class PayrollService {
  public getPayrollMetaDataInputs(
    params: GetPayrollMetaDataParams
  ): Promise<PayrollMetaDataDetails> | PayrollMetaDataDetails {
    const service: ApiService = new ApiService(
      EndPoints.GET_BULK_PAYROLL_METADATA
    );
    return service.get({
      dynamicQueryParams: [{ internalRole: params.internalRole }],
    });
  }

  public getPayrollList(
    params: GetPayrollListParams
  ): Promise<GetPayrollResponse> | GetPayrollResponse {
    const service: ApiService = new ApiService(EndPoints.GET_BULK_PAYROLL_DATA);
    const requestParams = {
      internalRole: params.internalRole,
      payrollCalendarCode: params.payrollCalendarCode,
      processPeriodCode: params.processPeriodCode,
      payElementCode: params.payElementCode,
      processPeriodNumber: params.processPeriodNumber,
      lineManagerCodeSearch: params.lineManagerCodeSearch,
      actionType: params.actionType,
    };
    if (params.customTextInput4) {
      return service.get({
        dynamicQueryParams: [
          {
            ...requestParams,
            customTextInput4: params.customTextInput4,
            employeeCodeSearch: params.employeeCodeSearch,
          },
        ],
      });
    }
    return service.get({
      dynamicQueryParams: [requestParams],
    });
  }

  public postPayrollData(
    request: PostPayrollRequestBody
  ): Promise<PostPayrollResponse> | PostPayrollResponse {
    const service: ApiService = new ApiService(
      EndPoints.POST_BULK_PAYROLL_DATA
    );
    return service.post({}, request);
  }

  public uploadFile(
    file: any,
    path: DynamicQueryPath
  ): Promise<IFileUploadResponse> {
    const service = new ApiService(EndPoints.CORE_UTILITY_DOWNLOAD_FILE);
    const formData: any = new FormData();
    formData.append("file", file);
    return service.uploadFile(path, formData);
  }
}

export default new PayrollService();
