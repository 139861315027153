import React, { useState } from "react";
import { Row } from "antd";
import SingleSummaryCard from "components/SingleSummaryCard/SingleSummaryCard";
import MultipleSummaryCard from "components/MultipleSummaryCard/MultipleSummaryCard";
import {
  IAnalyticsSectionCardsProps,
  SingleOnboardingItems,
  MultipleOnboardingItems,
} from "screens/analytics/type";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/RootReducer";
import { showTitle } from "screens/analytics/helper";
export default function AnalyticsSectionCards(
  props: IAnalyticsSectionCardsProps,
) {
  const [isValidItem, setIsValidItem] = useState(true);

  const { contextStore } = useSelector((state: ApplicationState) => state);

  const checkClientContractExpiration = (isValid: boolean) => {
    setIsValidItem(isValid);
  };
    return (
    <div className={`p-2`}>
      {showTitle(props.analyticsSectionDetails.sectionTitle , contextStore.allowedActivities) && <Row className="p-2" type="flex" justify="start">
        <h4 className="p-2 my-1 ff-secondary">
          {props.analyticsSectionDetails.sectionTitle}
        </h4>
      </Row>}
      <Row type="flex">
        {props.analyticsSectionDetails.metrics.map(
          (cardItem: SingleOnboardingItems | MultipleOnboardingItems) =>
            cardItem.isMultiple ? (
              contextStore.allowedActivities[cardItem.cardName] &&
              <MultipleSummaryCard
                cardDetail={cardItem as MultipleOnboardingItems}
                toDate={props.toDate}
                fromDate={props.fromDate}
              />
            ) : cardItem.apiCode === "CLI_ANLY_CLIENT_CONTRACT_DET" ? (
              isValidItem && (
                contextStore.allowedActivities[cardItem.cardName] &&
                <SingleSummaryCard
                  cardDetail={(cardItem as unknown) as SingleOnboardingItems}
                  toDate={props.toDate}
                  fromDate={props.fromDate}
                  clientContractExpiration={checkClientContractExpiration}
                />
              )
            ) : (
              contextStore.allowedActivities[cardItem.cardName] &&
              <SingleSummaryCard
                cardDetail={(cardItem as unknown) as SingleOnboardingItems}
                toDate={props.toDate}
                fromDate={props.fromDate}
                clientContractExpiration={checkClientContractExpiration}
              />
            )
        )}
      </Row>
    </div>
  );
}
