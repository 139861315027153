export function statusColor(status: string) {
  switch (status) {
    case "AU":
      return "#02af9b";
    case "RJ":
      return "#ee2e22";
    case "FR":
      return "#fbb517";
    case "IV":
      return "#00b6ef";
    default:
      return "#00b6ef";
  }
}

export function statusDescription(status: string) {
  switch (status) {
    case "AU":
      return "Authorized";
    case "RJ":
      return "Rejected";
    case "FR":
      return "Pending of Approval";
    case "IV":
      return "Invoice";
    default:
      return "Unknown";
  }
}
