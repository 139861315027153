import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { ColumnObj } from "./type";

export const downloadExcel = (
  column: ColumnObj[],
  dataSource: any,
  fileName: string | undefined | null
) => {
  const data = getExcelParsabelObj(column, dataSource);
  const worksheet = XLSX.utils.json_to_sheet(data);
  let workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "out.xlsb");
  var wopts = { bookType: "xlsx", bookSST: false, type: "array" };
  // @ts-ignore: Unreachable code error
  var wbout = XLSX.write(workbook, wopts);

  saveAs(
    new Blob([wbout], { type: "application/octet-stream" }),
    fileName ? fileName + ".xlsx" : "dataSheet.xlsx"
  );
};

const getExcelParsabelObj = (column: ColumnObj[], data: any) => {
  let excelObjList: any[] = [];
  data.map((eachData: any) => {
    let excelObj: any = {};
    column.map((columnObj: ColumnObj) => {
      excelObj[columnObj.title ? columnObj.title : columnObj.key] = columnObj.parser ? columnObj.parser(eachData[columnObj.dataIndex]) : eachData[columnObj.dataIndex];
    });
    excelObjList.push(excelObj);
  });
  return excelObjList;
};
