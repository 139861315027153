import React from "react";
import { downloadFile } from "AssetHelper";
import { IDownloadExcelProps } from "./type";
import { downloadExcel } from "./helper";
import { noop } from "lodash";

const DownloadExcel = (props: IDownloadExcelProps) => {
  return (
    <div
      className={
        props.isDisable ? "m-2 cursor-not-allowed" : "m-2 cursor-pointer"
      }
      onClick={
        props.isDisable
          ? noop
          : 
          (props.onClick ? props.onClick :
              () =>
              props.data?.length &&
              downloadExcel(
                props.column,
                props.data,
                props.fileNameWithoutExtension
              )
              )
      }>
      <img src={downloadFile} className="mr-2 h-7" alt="associate-icon" />
      <span className="align-middle">{props.lable}</span>
    </div>
  );
};

export default DownloadExcel;
