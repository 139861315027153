import { APIFieldList, OnboardingMetaDataDetails } from "models/analytics.data";
import {
  EmployeeList,
  LeaveBalanceReportData,
  LeaveTypeFieldList,
} from "models/summaryApis.data";
import moment from "moment";
import text from "text";
import { dateFormat } from "./constant";
import { dateCodes } from "date";
export enum APICodeTypesReport {
  LEAV_RPT_LEAV_DET_LIST = "LEAV_RPT_LEAV_DET_LIST",
  LEAV_RPT_LEAV_BAL_LIST = "LEAV_RPT_LEAV_BAL_LIST",
  EXP_RPT_EXP_DET_LIST = "EXP_RPT_EXP_DET_LIST",
  TMSHT_RPT_ATT_DET_LIST = "TMSHT_RPT_ATT_DET_LIST",
}

export const GetApiCode = (selectedValue: string) => {
  if (selectedValue === "Leave Balance") {
    return APICodeTypesReport.LEAV_RPT_LEAV_BAL_LIST;
  } else if (selectedValue === "Leave") {
    return APICodeTypesReport.LEAV_RPT_LEAV_DET_LIST;
  } else if (selectedValue === "Reimbursement")
    return APICodeTypesReport.EXP_RPT_EXP_DET_LIST;
  else if (selectedValue === "Advance Salary")
    return APICodeTypesReport.EXP_RPT_EXP_DET_LIST;
  return APICodeTypesReport.TMSHT_RPT_ATT_DET_LIST;
};

export function column(excelColumnData: OnboardingMetaDataDetails) {
  const dataColumns: {
    title: string;
    key: string;
    dataIndex: string;
    parser: (value: string | number) => string;
  }[] = [];
  const sortedReportHeading = excelColumnData?.data.apiFieldList.sort(
    (a: APIFieldList, b: APIFieldList) => {
      return a.apiParameterSequence - b.apiParameterSequence;
    }
  );
  sortedReportHeading?.map((item: APIFieldList) => {
    dataColumns.push({
      title: item.apiParameterCaption,
      key: item.apiParameterCaption,
      dataIndex: item.apiParameterName,
      parser: (value: string | number) => {
        if (
          item.parameterDataType === "date" ||
          item.parameterDataType === "Date"
        ) {
          return moment(value).format(dateCodes.STD_DATE);
        }
        return value as string;
      },
    });
  });
  return dataColumns;
}

export function getLeaveBalanceColumn(
  excelColumnData: OnboardingMetaDataDetails,
  reportLeaveExtraFields: LeaveBalanceReportData
) {
  const dataColumns: { title: string; key: string; dataIndex: string }[] = [];
  const sortedReportHeading = excelColumnData?.data.apiFieldList.sort(
    (a: APIFieldList, b: APIFieldList) => {
      return a.apiParameterSequence - b.apiParameterSequence;
    }
  );
  sortedReportHeading?.map((item: APIFieldList) => {
    dataColumns.push({
      title: item.apiParameterCaption,
      key: item.apiParameterCaption,
      dataIndex: item.apiParameterName,
    });
  });
  reportLeaveExtraFields.leaveTypeFieldList.map(item => {
    dataColumns.push({
      title: item.leaveTypeDescription,
      key: item.leaveTypeDescription,
      dataIndex: item.leaveTypeDescription,
    });
  });
  return dataColumns;
}

export const advanceSalaryColumns = [
  {
    title: "Emp ID",
    key: "employeeCode",
    dataIndex: "employeeCode",
  },
  {
    title: "Emp Name",
    key: "employeeName",
    dataIndex: "employeeName",
  },
  {
    title: "Date",
    key: "applicationDate",
    dataIndex: "applicationDate",
  },
  {
    title: "Amount",
    key: "loanAmount",
    dataIndex: "loanAmount",
  },
  {
    title: "Installments",
    key: "desiredNoofPrincipalInstallments",
    dataIndex: "desiredNoofPrincipalInstallments",
  },
  {
    title: "Reason",
    key: "remarks",
    dataIndex: "remarks",
  },
  {
    title: "Status",
    key: "custom_1_Char_Output",
    dataIndex: "custom_1_Char_Output",
  },
  {
    title: "Approved by",
    key: "approvedBy",
    dataIndex: "approvedBy",
  },
];

export const downloadExcelData = (value: string, data: any) => {
  switch (value) {
    case "Leave":
      return data?.data.leaveApplicationList;
    case "Leave Balance":
      return data?.data.employeeList;
    case "Reimbursement":
      return data?.data.expenseRequestList;
    case "Attendance":
      return data?.data.attendanceEmployeeList;
    case "Advance Salary":
      return data?.data.loanList;
  }
};
export function getDataList(
  key: string,
  columnData: OnboardingMetaDataDetails,
  tableData: any
) {
  switch (key) {
    case text.LEAVE:
      return getDataSource(
        columnData.data.apiFieldList,
        tableData.leaveApplicationList
      );
    case text.REIMBURSEMENT:
      return getDataSource(
        columnData.data.apiFieldList,
        tableData.expenseRequestList
      );
    case text.LEAVE_BALANCE:
      let leaveBalanceList = getDataSource(
        columnData.data.apiFieldList,
        tableData.expenseRequestList
      );
      tableData.employeeList.map((leaveBalanceData: EmployeeList) => {
        let data: { [key: string]: string | number } = {};
        //@ts-ignore
        data = leaveBalanceData;
        tableData.leaveTypeFieldList.forEach(
          (leaveType: LeaveTypeFieldList) => {
            //@ts-ignore
            data[leaveType.leaveTypeDescription] = leaveBalanceData[`leaveType${leaveType.leaveTypeNumber}Balance`];
          }
        );
        leaveBalanceList.push(data);
      });
      return leaveBalanceList;
    default:
      return getDataSource(
        columnData.data.apiFieldList,
        tableData.leaveApplicationList
      );
  }
}

export function getDataSource(columnData: APIFieldList[], tableData: any) {
  let excelData: any = [];
  let dateColumn: string[] = [];
  columnData.map(columnFields => {
    if (columnFields.parameterDataType === "date")
      dateColumn.push(columnFields.apiParameterName);
  });
  tableData?.map(
    (item: any) => (
      dateColumn.map((dateTypeField: string) => {
        item[`${dateTypeField}`] = moment(item[`${dateTypeField}`]).format(
          dateFormat
        );
      }),
      excelData.push(item)
    )
  );
  return excelData;
}
