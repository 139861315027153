import {
  NetPayEmployeeList,
  NetPayPostReferenceDetails,
} from "models/netpay.data";

export const getPostRequestParams = (
  selectedRowKey: string[],
  screen: string,
  referenceNumber: string,
  employeeList: NetPayEmployeeList[]
) => {
  const list: NetPayPostReferenceDetails[] = [];
  selectedRowKey.length && screen === "NETPAYMAIN"
    ? employeeList.forEach(row => {
        selectedRowKey
          .filter(data => data === row.employeeCode || "")
          .forEach(item => {
            const employeeObj: NetPayPostReferenceDetails = {
              employeeCode: row.employeeCode,
              referenceRemarks: row.referenceRemarks,
              referenceNumber: referenceNumber,
            };
            list.push(employeeObj);
          });
      })
    : employeeList.forEach(item => {
        const employeeObj: NetPayPostReferenceDetails = {
          employeeCode: item.employeeCode,
          referenceRemarks: item.referenceRemarks,
          referenceNumber: referenceNumber,
        };
        list.push(employeeObj);
      });
  return list;
};
