import React from "react";
import { ILineManagerCardProps } from "./type";
import { DatePicker, Row, Checkbox, Col } from "antd";
import text from "text";
import { CardLayout } from "components/AssociateCard/CardLayout";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import { NewLineManagerList } from "models/lineManager.data";
import moment, { Moment } from "moment";
import { SaveRequestParams } from "screens/lineManager/type";
import { dateCodes } from "date";

export default function LineManagerCard(
  props: ILineManagerCardProps,
) {
  const {
    data,
    lineManagerList,
    select,
    selectedItems,
    onEffectiveFromDateChnage,
    onLineManagerChange,
    selectedFilter,
    lineManagerValue,
  } = props;

  const selectLineManager = (value: NewLineManagerList) => {
    onLineManagerChange(value.newLineManagerCode as string, data.employeeCode as string);
  };

  const selectEffectiveFromDate = (value: Moment | null, dateString: string) => {
    onEffectiveFromDateChnage(value?.format(dateCodes.TO_ISO_8601) || "", data.employeeCode as string);
  };

  const onSelect = () => {
    const selectedData: SaveRequestParams = {
      id: data.id as number,
      employeeCode: data.employeeCode as string,
      newEffectiveFromDate: data.effectiveFromDate as string,
      newLineManagerCode: data.lineManager as string,
    };
    select(selectedData);
  };

  const disableDates = (date: Moment | null) => {
    if (date && date.isBefore(moment(), "month"))
      return true;
    return false;
  }

  return (
    <CardLayout>
      <Checkbox
        onChange={onSelect}
        className="align-self-end"
        checked={selectedItems[data.id as number]}
      />
      <Row type="flex">
        <Col span={11}>
          <Row className="pb-2">{text.ASSOCIATE_CODE}</Row>
          <Row className="py-2">{text.ASSOCIATE_NAME}</Row>
          <Row className="py-2">{text.ASSOCIATE_EMAIL_ID}</Row>
          <Row className="py-2">{text.EFFECTIVE_FROM}</Row>
          <Row className="py-2">{text.LINE_MANAGER}</Row>
        </Col>
        <Col span={12} className="fw-bold" style={{wordBreak: "break-all"}}>
          <Row className="pb-2">{data.employeeCode || text.NOT_APPLICABLE}</Row>
          <Row className="py-2">{data.employeeName || text.NOT_APPLICABLE}</Row>
          <Row className="py-2">{data.emailID || text.NOT_APPLICABLE}</Row>
          <DatePicker
            getCalendarContainer={(t: any) => t.parentNode as HTMLElement}
            value={data.effectiveFromDate ? moment(data.effectiveFromDate) : undefined}
            className="w-100 pr-1"
            onChange={selectEffectiveFromDate}
            disabled={!selectedItems[data.id as number]}
            allowClear={false}
            format={"DD-MM-YYYY"}
            disabledDate={disableDates}
          />
          <Row className="pt-1">
            <SearchableDropdown<NewLineManagerList>
              placeholder={text.SELECT_LINE_MANAGER}
              list={lineManagerList || []}
              onSelect={(i) => selectLineManager(i as NewLineManagerList)}
              optionLableExtractor={(item) => item.newLineManagerCode}
              optionKeyExtractor={(item) => item.newLineManagerName}
              value={data.lineManager || selectedFilter.existingLineManager}
              isDisabled={!selectedItems[data.id as number]}
              fullWidth={"100"}
            />
          </Row>
        </Col>
      </Row>
    </CardLayout>
  );
}
