import { DashboardActions } from "./actions";

import { reducerWithInitialState } from "typescript-fsa-reducers";
import { DasboardStateType } from "../../models/summaryApis.data";
import { SummaryResponse } from "models/dashboard.data";

const initialState: DasboardStateType = {
  loading: false,
  leaveLoading: false,
  reimbursementLoading: false,
  timesheetLoading: false,
  error: null,
  dashboardSummary: {
    leaveSummary: { data: [], message: "" },
    timesheetSummary: { data: [], message: "" },
    reimbursementSummary: { data: [], message: "" },
  },
};

const dashboardReducer = reducerWithInitialState(initialState)
  .cases(
    [DashboardActions.getLeaveSummary.started],
    (state: DasboardStateType) => ({
      ...state,
      error: null,
      loading: true,
      leaveLoading: true,
    }),
  )
  .cases(
    [DashboardActions.getReimbursementSummary.started],
    (state: DasboardStateType) => ({
      ...state,
      error: null,
      loading: true,
      reimbursementLoading: true,
    }),
  )
  .cases(
    [DashboardActions.getTimesheetSummary.started],
    (state: DasboardStateType) => ({
      ...state,
      error: null,
      loading: true,
      timesheetLoading: true,
    }),
  )
  .case(
    DashboardActions.getLeaveSummary.done,
    (state: DasboardStateType, payload: { result: SummaryResponse }) => ({
      ...state,
      error: null,
      loading: false,
      leaveLoading: false,
      dashboardSummary: {
        ...state.dashboardSummary,
        leaveSummary: payload.result,
      },
    }),
  )
  .case(
    DashboardActions.getTimesheetSummary.done,
    (state: DasboardStateType, payload: { result: SummaryResponse }) => ({
      ...state,
      error: null,
      loading: false,
      timesheetLoading: false,
      dashboardSummary: {
        ...state.dashboardSummary,
        timesheetSummary: payload.result,
      },
    }),
  )
  .case(
    DashboardActions.getReimbursementSummary.done,
    (state: DasboardStateType, payload: { result: SummaryResponse }) => ({
      ...state,
      error: null,
      loading: false,
      reimbursementLoading: false,
      dashboardSummary: {
        ...state.dashboardSummary,
        reimbursementSummary: payload.result,
      },
    }),
  )
  .case(
    DashboardActions.getLeaveSummary.failed,
    (state: DasboardStateType, payload: any) => ({
      ...state,
      error: payload,
      loading: false,
      leaveLoading: false,
    }),
  )
  .case(
    DashboardActions.getTimesheetSummary.failed,
    (state: DasboardStateType, payload: any) => ({
      ...state,
      error: payload,
      loading: false,
      timesheetLoading: false,
    }),
  )
  .case(
    DashboardActions.getReimbursementSummary.failed,
    (state: DasboardStateType, payload: any) => ({
      ...state,
      error: payload,
      loading: false,
      reimbursementLoading: false,
    }),
  )
  .default(state => {
    return state;
  });

export default dashboardReducer;
