import React, { useEffect, useState } from "react";
import { Button, Checkbox, Empty, Row } from "antd";
import text from "text";
import { salaryImage } from "AssetHelper";
import { style } from "styles/Fonts";
import { IFilterParams, SummaryType, ListType } from "./type";
import {
  defaultFilterValues,
  getSummaryValue,
  defaultList,
  summaryCardData,
  REJT,
  AUTH,
} from "./constant";
import SummaryCard from "components/AdvanceSalaryComponent/SummaryCard";
import Filter from "components/AdvanceSalaryComponent/Filter";
import {
  LoanInputList,
  SalaryList,
  SalaryListResponse,
  SummaryData,
} from "models/advanceSalary.data";
import Loader from "components/Loader";
import LoanService from "./service";
import { dateFormat, defaultClientEndDate, defaultClientStartDate } from "date";
import { getFilterDate } from "./helper";
import AdvanceSalaryCard from "components/AdvanceSalaryComponent/SalaryCard";
import { showNotification } from "components/ShowNotification/ShowNotification";
import _ from "lodash";
import RejectModal from "components/AdvanceSalaryComponent/RejectModal";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/RootReducer";
import { ModuleType } from "screens/home/store/home/constants";

export default function AdvanceSalary() {
  const [detailList, setDetailList] = useState<ListType>({
    isLoading: false,
    data: defaultList,
  });

  const [summaryData, setSummaryData] = useState<SummaryType>({
    data: summaryCardData,
    isLoading: true,
  });

  const [selectedRecord, setSelectedRecord] = useState(new Array(0).fill({}));
  const [selectedItems, setSelectedItems] = useState({});

  const [filterValue, setFilterValue] = useState<IFilterParams>(
    defaultFilterValues
  );
  const [loading, setLoading] = useState({
    isApprove: false,
    isReject: false,
    isBulkApprove: false,
    isBulkReject: false,
  });

  const { contextStore } = useSelector((state: ApplicationState) => state);

  const [searchValue, setSearchValue] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [reason, setReason] = useState("");

  useEffect(() => {
    getSummary({
      startDate: defaultClientStartDate,
      endDate: defaultClientEndDate,
      statusType: text.ALL,
    });
    getList({
      startDate: defaultClientStartDate,
      endDate: defaultClientEndDate,
      statusType: "",
    });
  }, []);

  const getSummary = async (requestedData: IFilterParams) => {
    let summaryCount = summaryCardData;
    try {
      setSummaryData({
        ...summaryData,
        isLoading: true,
      });
      const { startDate, endDate } = requestedData;
      const response: SummaryData = await LoanService.getSummary({
        startDate: startDate ? startDate.format(dateFormat) : "",
        endDate: endDate ? endDate.format(dateFormat) : "",
        statusType: text.ALL,
      });
      summaryCount = getSummaryValue(response.data);
      setSummaryData({
        isLoading: false,
        data: summaryCount,
      });
    } catch (err) {
    } finally {
      setSummaryData({
        isLoading: false,
        data: summaryCount,
      });
    }
  };

  const getList = async (requestedData: IFilterParams) => {
    let response: SalaryListResponse = defaultList;
    try {
      setDetailList({ ...detailList, isLoading: true });
      const { startDate, endDate, statusType } = requestedData;
      response = await LoanService.getDetailList({
        startDate: startDate ? startDate.format(dateFormat) : "",
        endDate: endDate ? endDate.format(dateFormat) : "",
        statusType,
      });
      response.data
        ?.filter(item => item.status === "P")
        .map((item: SalaryList, index: number) => {
          item.id = index;
          return item;
        });
      setDetailList({ isLoading: false, data: response });
    } catch (err) {
    } finally {
      setDetailList({ isLoading: false, data: response });
    }
  };

  const resetEverything = () => {
    setSearchValue("");
    setSelectedRecord([]);
    setSelectedItems({});
    setFilterValue(defaultFilterValues);
    getList({
      startDate: defaultClientStartDate,
      endDate: defaultClientEndDate,
      statusType: "",
    });
    getSummary({
      startDate: defaultClientStartDate,
      endDate: defaultClientEndDate,
      statusType: "",
    });
  };

  const onBulkAuthRej = async (actionType: string) => {
    actionType === AUTH
      ? setLoading({ ...loading, isBulkApprove: true })
      : setLoading({ ...loading, isBulkReject: true });
    try {
      const selectedItem: any = selectedItems;
      const employeeList: LoanInputList[] = [];
      getSearchedRecords()
        .filter(item => selectedItem[item.id] === true)
        .map(row => {
          employeeList.push({
            loanApplnNumber: row.loanApplnNumber,
            rejectionReason: actionType === REJT ? reason : "",
          });
        });
      await LoanService.approveRejectLoan({
        authRejt: actionType,
        applyLoansInput: employeeList,
      });
      resetEverything();
      showNotification({
        message: text.SUCCESS,
        description: text.SUBMIT_SUCCESSFULLY,
        type: text.SUCCESS,
      });
      actionType === AUTH
        ? setLoading({ ...loading, isBulkApprove: false })
        : setLoading({ ...loading, isBulkReject: false });
        setOpenModal(false);
    } catch (err) {
      setOpenModal(false);
    } finally {
      actionType === AUTH
        ? setLoading({ ...loading, isBulkApprove: false })
        : setLoading({ ...loading, isBulkReject: false });
    }
  };

  const onAuthRej = async (actionType: string, data?: SalaryList) => {
    actionType === AUTH
      ? setLoading({ ...loading, isApprove: true })
      : setLoading({ ...loading, isReject: true });
    try {
      await LoanService.approveRejectLoan({
        authRejt: actionType,
        applyLoansInput: [
          {
            loanApplnNumber: data && data.loanApplnNumber,
            rejectionReason: actionType === REJT ? reason : "",
          },
        ],
      });
      resetEverything();
      showNotification({
        message: text.SUCCESS,
        description: text.SUBMIT_SUCCESSFULLY,
        type: text.SUCCESS,
      });
      actionType === AUTH
        ? setLoading({ ...loading, isApprove: false })
        : setLoading({ ...loading, isReject: false });
    } catch (err) {
    } finally {
      actionType === AUTH
        ? setLoading({ ...loading, isApprove: false })
        : setLoading({ ...loading, isReject: false });
    }
  };

  const onChangeRejectionReason = (value: string) => {
    setReason(value);
  };

  const renderAdvanceSalaryCard = () => {
    return getSearchedRecords().length !== 0 ? (
      getSearchedRecords().map((item, index) => {
        return (
          <Row className="my-4" key={index}>
            <AdvanceSalaryCard
              data={item}
              select={toggleSelection}
              selectedItems={selectedItems}
              onAuthRej={onAuthRej}
              approveLoader={loading.isApprove}
              rejectLoader={loading.isReject}
              onChangeRejectionReason={onChangeRejectionReason}
              onModalClose={() => setReason("")}
              reason={reason}
            />
          </Row>
        );
      })
    ) : (
      <Row className="bg-white w-100">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      </Row>
    );
  };

  const onFilterChange = (value: IFilterParams) => {
    const { startDate, endDate } = getFilterDate(value);
    setSearchValue("");
    setSelectedItems({});
    setSelectedRecord([]);
    getList({
      startDate,
      endDate,
      statusType: value.statusType,
    });
    getSummary({
      startDate,
      endDate,
      statusType: value.statusType,
    });
    setFilterValue({
      startDate: value.startDate,
      endDate: value.startDate ? value.endDate : null,
      statusType: value.statusType,
    });
  };

  const onSearchTextChange = (value: string) => {
    setSearchValue(value);
    setSelectedItems({});
    setSelectedRecord([]);
  };

  const getSearchedRecords = () => {
    const associateList = detailList.data.data || [];
    if (!searchValue) return associateList;
    return associateList.filter(item => {
      return item.empName?.toLowerCase().includes(searchValue.toLowerCase());
    });
  };

  const isSelectAllChecked = () => {
    const employeeList = getSearchedRecords().filter(
      item => item.status === "P"
    );
    return (
      employeeList.length !== 0 && employeeList.length === selectedRecord.length
    );
  };

  const selectAll = () => {
    const addRecords: SalaryList[] = [];
    setSelectedItems({});
    const pendingRecords = getSearchedRecords().filter(i => i.status === "P");
    if (pendingRecords.length !== selectedRecord.length) {
      const selectedItem: any = selectedItems;
      const updatedEmployeeList = _.cloneDeep(getSearchedRecords());
      updatedEmployeeList.map(item => {
        if (item.status.toLowerCase() === "p") selectedItem[item.id] = true;
        setSelectedItems(selectedItem);
        addRecords.push(item);
      });
      setSelectedRecord(
        addRecords.filter(item => selectedItem[item.id] === true)
      );
    } else {
      setSelectedItems({});
      setSelectedRecord([]);
    }
  };

  const toggleSelection = (values: SalaryList) => {
    const selectedItem: any = selectedItems;
    if (!selectedItem[values.id]) {
      if (!selectedRecord.find(item => item.id === values.id)) {
        const data = [...selectedRecord];
        data.push(values);
        selectedItem[values.id] = true;
        setSelectedRecord(data);
      }
    } else {
      const index = selectedRecord.indexOf(
        selectedRecord.find(item => values.id === item.id)
      );
      const updatedData = [...selectedRecord];
      updatedData.splice(index, 1);
      delete selectedItem[values.id];
      setSelectedRecord(updatedData);
    }
  };

  const isDisabled = () => {
    return (
      !(selectedRecord.length > 0) ||
      loading.isBulkApprove ||
      loading.isBulkReject ||
      !getSearchedRecords().filter(item => item.status === "P").length
    );
  };

  const isCheckBoxDisabled = () => {
    return !getSearchedRecords().filter(item => item.status === "P").length;
  };

  const onCancel = () => {
    setOpenModal(false);
    setReason("");
  };

  if (!contextStore.allowedActivities[ModuleType.ADVANCE_SALARY])
    return <p>{text.UNAUTHORIZED_VIEW}</p>;

  return (
    <>
      {openModal && (
        <RejectModal
          loading={loading.isBulkReject}
          onChangeReason={onChangeRejectionReason}
          onReject={() => onBulkAuthRej(REJT)}
          reason={reason}
          onCancel={onCancel}
          showModal={openModal}
        />
      )}
      <Row className="p-6">
        <Row className="pb-4 ff-secondary" style={style.large}>
          <img src={salaryImage} className="pr-3 h-6" />
          {text.ADVANCE_SALARY}
        </Row>
        <SummaryCard
          isLoading={summaryData.isLoading}
          data={summaryData.data}
        />
        <Filter
          filterValues={filterValue}
          setSearchText={onSearchTextChange}
          onChangeFilters={onFilterChange}
          searchValue={searchValue}
        />
        <Row type="flex" justify="space-between">
          <Checkbox
            onChange={selectAll}
            className="fw-bold"
            disabled={isCheckBoxDisabled()}
            checked={isSelectAllChecked()}>
            {text.SELECT_ALL}
          </Checkbox>
          {selectedRecord.length > 0 && (
            <Row>
              <Button
                className="c-white px-6 fw-bold bg-success mr-2"
                disabled={isDisabled()}
                loading={loading.isBulkApprove}
                onClick={() => onBulkAuthRej(AUTH)}>
                {text.APPROVE_ALL}
              </Button>
              <Button
                className="c-white px-6 fw-bold bg-primary"
                onClick={() => setOpenModal(true)}
                disabled={isDisabled()}
                loading={loading.isBulkReject}>
                {text.REJECT_ALL}
              </Button>
            </Row>
          )}
        </Row>
        {detailList.isLoading ? (
          <Row className="bg-white w-100 py-15 my-2">
            {" "}
            <Loader />{" "}
          </Row>
        ) : (
          renderAdvanceSalaryCard()
        )}
      </Row>
    </>
  );
}
