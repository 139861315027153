import React, { useState } from "react";
import { Row, Col, Button, Typography, Checkbox, Input } from "antd";
import text from "text";
import { IConfirmationProps } from "./type";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import { EmployerReasonList } from "models/resignation.data";

const { Text } = Typography;
export default function ConfirmationModal(props: IConfirmationProps) {

  const {
    employerReason,
    isApproveLoading,
    isRejectLoading,
    onChangeEmployerReason,
    onChangeNoDueConfirmation,
    onChangeRemarks,
    onChangeApproverComments,
    data,
    onApprove,
    onReject,
    actionType,
    } = props;

  const [isNoDueSelected, setIsNoDueSelected] = useState(false);

  const onNoDueConfirmationChange = () => {
    setIsNoDueSelected(!isNoDueSelected);
    onChangeNoDueConfirmation(!isNoDueSelected ? "Y" : "N", data.separationNumber);
  };

  const isRejectButtonDisabled = () => {
    return !data.approverComments || !data.reason || isRejectLoading;
  };

  const isApproveButtonDisabled = () => {
    return !data.relievingRemarks || !data.reason || isApproveLoading || !isNoDueSelected;
  };

  return (
        <Row>
            <SearchableDropdown<EmployerReasonList>
                label={text.CHOOSE_EMPLOYER_REASON}
                list={employerReason}
                width={232}
                onSelect={(e) => onChangeEmployerReason(e as EmployerReasonList, data.separationNumber)}
                optionLableExtractor={(item) => item.employerReason}
                optionKeyExtractor={(item) => item.employerReasonCode}
                value={data.reason}
            />
            {actionType === text.APPROVE &&
                <>
                    <Row type="flex" align="middle" >
                        <Col span={4}><Text>{text.REMARKS}{" : "}</Text></Col>
                        <Col span={3}/>
                        <Col span={14}>
                            <Input
                                onChange={e => onChangeRemarks(e.target.value, data.separationNumber)}
                                value={data.relievingRemarks}
                            />
                        </Col>
                    </Row>
                    <Row type="flex" align="middle" className="mb-1 mt-5">
                        <Checkbox onChange={onNoDueConfirmationChange} />
                        <Text className="pb-1 pl-2">{text.NO_DUE_CONFIRMATION}</Text>
                    </Row>
                    <Row type="flex" justify="end" className="mr-1 mt-4">
                        <Button
                            disabled={isApproveButtonDisabled()}
                            className={isApproveButtonDisabled()
                                ? "px-4 fw-bold"
                                : "bg-success c-white px-4 fw-bold"}
                            onClick={() => onApprove(data)}
                            loading={isApproveLoading}
                        >
                            {actionType}
                        </Button>
                    </Row>
                </>}
            {actionType === text.REJECT &&
                <>
                    <Row type="flex" align="middle">
                        <Col span={4}><Text>{text.REMARKS}{" : "}</Text></Col>
                        <Col span={3}/>
                        <Col span={14}>
                            <Input
                                onChange={e => onChangeApproverComments(e.target.value, data.separationNumber)}
                                value={data.approverComments}
                            />
                        </Col>
                    </Row>
                    <Row type="flex" justify="end" className="mr-2 mt-4">
                        <Button
                            disabled={isRejectButtonDisabled()}
                            className={isRejectButtonDisabled()
                                ? "px-4 fw-bold"
                                : "bg-primary c-white px-4 fw-bold"}
                            onClick={() => onReject(data)}
                            loading={isRejectLoading}
                        >
                            {actionType}
                        </Button>
                    </Row>
                </>}
        </Row>
  );
}
