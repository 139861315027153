import {
  PayrollMetaDataDetails,
  PostPayrollRequestBody,
  PostPayrollResponse,
  GetPayrollResponse,
} from "models/otherInput.data";
import { useQuery, useMutation, queryCache } from "react-query";
import {
  GetPayrollListParams,
  GetPayrollMetaDataParams,
  IUploadParams,
} from "./type";
import { PayrollService } from "./serivce";
import text from "text";
import { IFileUploadResponse } from "models/uploadDoc.data";
import { showNotification } from "components/ShowNotification/ShowNotification";
import { getErrorMessage } from "./helper";
import { NOT_ALLOWED_TO_SUBMIT } from "./constants";
import _ from "lodash";

export enum PayrollHookTypes {
  PAYROLL_METADATA = "bulkpayrollinputs/nonrecurringelements/metadata",
  PAYROLL_DATA = "bulkpayrollinputs/nonrecurringelements/list",
}

const getPayrollMetadata = async (
  params: GetPayrollMetaDataParams
): Promise<PayrollMetaDataDetails> => {
  const payrollService = new PayrollService();
  const data: PayrollMetaDataDetails = await payrollService.getPayrollMetaDataInputs(
    params
  );
  return data;
};

export default function usePayrollMetadata(
  queryKey: PayrollHookTypes,
  params: { internalRole: string }
) {
  return useQuery(queryKey, async () =>
    getPayrollMetadata({
      internalRole: params.internalRole,
    })
  );
}

const getPayrollList = async (
  params: GetPayrollListParams,
): Promise<GetPayrollResponse> => {
  const payrollService = new PayrollService();
  const data: GetPayrollResponse = await payrollService.getPayrollList(params);
  _.remove(data.data.EmployeeValueList, (item, index) =>
    item.customEVLTextOutput1 === NOT_ALLOWED_TO_SUBMIT,
  );
  return data;
};

export function usePayrollList(
  queryKey: PayrollHookTypes,
  params: GetPayrollListParams,
) {
  return useQuery(queryKey, async () =>
    getPayrollList({
      internalRole: params.internalRole,
      payrollCalendarCode: params.payrollCalendarCode,
      processPeriodCode: params.processPeriodCode,
      payElementCode: params.payElementCode,
      processPeriodNumber: params.processPeriodNumber,
      customTextInput4: params.customTextInput4 ? params.customTextInput4 : "",
      employeeCodeSearch: params.employeeCodeSearch,
      lineManagerCodeSearch: params.lineManagerCodeSearch
        ? params.lineManagerCodeSearch
        : "",
      actionType: params.actionType,
    })
  );
}

const submitPayrollData = async (
  params: PostPayrollRequestBody
): Promise<PostPayrollResponse> => {
  const payrollService = new PayrollService();
  const response: PostPayrollResponse = await payrollService.postPayrollData(
    params
  );
  return response;
};

export function usePayrollAction() {
  return useMutation(
    (requestBody: PostPayrollRequestBody) => submitPayrollData(requestBody),
    {
      onSuccess: () => {
        showNotification({
          message: text.SUCCESS,
          description: text.SUBMIT_SUCCESSFULLY,
          type: text.SUCCESS,
        });
        queryCache.invalidateQueries(PayrollHookTypes.PAYROLL_DATA);
      },
      onError: (err: any) => {
        showNotification({
          message: text.ERROR,
          description: getErrorMessage(err.name, err.message),
          type: text.ERROR,
        });
      },
    }
  );
}

const uploadFiles = async (requestBody: IUploadParams) => {
  const payrollService = new PayrollService();
  const fileResponse: IFileUploadResponse = await payrollService.uploadFile(
    requestBody.file,
    {
      dynamicQueryParams: [{ relativepath: requestBody.relativePath }],
    }
  );
  return fileResponse.data.fileId;
};

export function usePayrollUploadAction() {
  return useMutation((requestBody: IUploadParams) => uploadFiles(requestBody));
}
