import { TimesheetActions } from "./actions";
import moment from "moment";
import { put, takeLatest } from "redux-saga/effects";
import { AssociatesTimesheetDetails } from "../../models/timesheet.data";
import TimesheetService from "screens/timesheet/TimesheetService";

function* getAssociatesData(action: any) {
  const { payload } = action;
  try {
    const response: AssociatesTimesheetDetails = yield TimesheetService.getAssociateTimesheet(
      payload,
    );
    yield put(
      TimesheetActions.getAssociatesTimesheetDetails.done({
        params: payload,
        result: response,
      }),
    );
  } catch (e) {
    yield put(
      TimesheetActions.getAssociatesTimesheetDetails.failed({
        params: payload,
        error: e.errorMsg.message,
      }),
    );
  }
}

function* TimesheetManagementSaga() {
  yield takeLatest(
    TimesheetActions.getAssociatesTimesheetDetails.started,
    getAssociatesData,
  );
}

export default TimesheetManagementSaga;
