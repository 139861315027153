import React from "react";
import { IBulkAttendanceFilterProps } from "./type";
import { Row, Col, Button } from "antd";
import {
  GET_DETAILS_BUTTON_COLOR,
  GET_DETAILS_DISABLED_BUTTON,
} from "./constants";
import text from "text";
import _ from "lodash";
import { AttendanceCycleList, AttendanceManipulatedListType, EmployeeManipulatedList, LineManagerManipulatedList, ProcessPeriodManipulatedList } from "screens/bulkAttendance/type";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";

export default function BulkAttendanceFilter(
  props: IBulkAttendanceFilterProps
) {
  const {
    list,
    onPayRollPeriodChange,
    attendanceCycleList,
    onAttendanceCycleChange,
    onAttendanceTypeChange,
    onLineManagerChange,
    associateList,
    onAssociateChange,
    onGetDetails,
    disabledButton,
    statusType,
    isLineManager,
    selectedAttendanceCycle,
    selection,
    selectedValues
  } = props;

  function getList<T>(objectList: T[], key: string) {
    /**
     * takes object list, ex => objectList = [{name: "name1"}, {name: "name2"}]
     * takes key, ex => key = "name"
     * returns list, ex => returnValue = ["name1", "name2"]
     */
    const returnList: string[] = [];
    //@ts-ignore
    objectList.forEach((item: T) => returnList.push(item[key] ? item[key] : item));
    return returnList;
  }
  return (
    <Row type="flex" className="my-2">
      <Col span={22}>
        <Col className="mr-3 my-2" span={7}>
          <SearchableDropdown<ProcessPeriodManipulatedList>
            label={text.PAYROLL_PERIOD}
            list={list?.processPeriodList ? list?.processPeriodList : []}
            onSelect={(i) => onPayRollPeriodChange(i as ProcessPeriodManipulatedList)}
            optionLableExtractor={(item: ProcessPeriodManipulatedList) => item.processPeriodDescription}
            optionKeyExtractor={(item: ProcessPeriodManipulatedList) => item.processPeriodCode}
            value={selectedValues.processPeriodCode}
          />
        </Col>
        <Col className="mr-3 my-2" span={7}>
          <SearchableDropdown<AttendanceCycleList>
            label={text.ATTENDANCE_CYCLE}
            list={attendanceCycleList ? attendanceCycleList : []}
            onSelect={(i) => onAttendanceCycleChange(i as AttendanceCycleList)}
            optionKeyExtractor={(item: AttendanceCycleList) => item.paysetCode}
            value={selectedAttendanceCycle as string}
            optionLableExtractor={(item: AttendanceCycleList) => item.attendanceCycle}
            isDisabled={!selection.isPayrollPeriodSelected}
          />
        </Col>
        <Col className="mr-3 my-2" span={8}>
          <SearchableDropdown<AttendanceManipulatedListType>
            label={text.ATTENDANCE_TYPE}
            list={list?.attendanceTypeList ? list?.attendanceTypeList : []}
            onSelect={(i) => onAttendanceTypeChange(i as AttendanceManipulatedListType)}
            optionKeyExtractor={(item: AttendanceManipulatedListType) => item.attendanceTypeCode}
            optionLableExtractor={(item: AttendanceManipulatedListType) => item.attendanceTypeDesc}
            value={selectedValues.attendanceType}
          />
        </Col>
        {!isLineManager && (
          <Col className="mr-3 my-2" span={8}>
            <SearchableDropdown<LineManagerManipulatedList>
              label={text.CHOOSE_LINE_MANAGER}
              list={list?.lineManagerList ? list?.lineManagerList : []}
              onSelect={(i) => onLineManagerChange(i as LineManagerManipulatedList)}
              optionKeyExtractor={(item: LineManagerManipulatedList) => item.lineManagerCode}
              optionLableExtractor={(item: LineManagerManipulatedList) => item.lineManagerName}
              value={selectedValues.lineManagerCode}
              allowClear
            />
          </Col>
        )}
        <Col className="mr-3 my-2" span={7}>
          <SearchableDropdown<EmployeeManipulatedList>
            label={text.SELECT_ASSOCIATE}
            list={associateList ? associateList : []}
            onSelect={(i) => onAssociateChange(getList<EmployeeManipulatedList>(i as EmployeeManipulatedList[], "employeeCode"))}
            optionKeyExtractor={(item: EmployeeManipulatedList) => item.employeeCode}
            optionLableExtractor={(item: EmployeeManipulatedList) => item.employeeName}
            isMultiple
            value={getList(selectedValues.associateList, "employeeCode")}
            allowClear
            isDisabled={!selection.isLineManagerSelected}
          />
        </Col>
      </Col>
      <Col span={2}>
        <Button
          type="primary"
          className="my-2 mx-3 c-white"
          onClick={() => onGetDetails("1")}
          disabled={disabledButton}
          style={{
            backgroundColor: disabledButton
              ? GET_DETAILS_DISABLED_BUTTON
              : GET_DETAILS_BUTTON_COLOR,
            borderColor: disabledButton
              ? GET_DETAILS_DISABLED_BUTTON
              : GET_DETAILS_BUTTON_COLOR,
          }}>
          {text.GET_DETAILS}
        </Button>
      </Col>
    </Row>
  );
}
