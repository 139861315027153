import { Button, Col, Row } from "antd";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import {
  ClientPOList,
  DepartmentList,
  LineManagerList,
} from "models/invoices.data";
import React from "react";
import { style } from "styles/Fonts";
import text from "text";
import { GET_DETAILS_BUTTON_COLOR, GET_DETAILS_DISABLED_BUTTON } from "./constant";
import { InvoicesAdvancedFilterProps } from "./type";

function InvoicesAdvancedFilter(props: InvoicesAdvancedFilterProps) {
  const {
    selectedValues,
    lineManagerList,
    departmentList,
    poNumberList,
    onGetList,
    isGetListDisabled,
    onDepartmentChange,
    onLineManagerChange,
    onPoNumberChange,
  } = props;

  return (
    <>
      <Row className="my-2">
        <Row className="my-2">
          <span style={style.medium} className="ff-secondary">
            {text.ADVANCED_FILTER}
          </span>
        </Row>
        <Row type="flex" align="middle">
            <Row type="flex">
              <Col className="mr-2">
                <SearchableDropdown<LineManagerList>
                  list={lineManagerList}
                  label={text.CHOOSE_LINE_MANAGER}
                  value={selectedValues.lineManagerCode}
                  optionKeyExtractor={item => item.lineManagerCode}
                  optionLableExtractor={item => item.lineManagerName}
                  onSelect={i => onLineManagerChange(i as LineManagerList)}
                />
              </Col>
              <Col className="mr-2">
                <SearchableDropdown<DepartmentList>
                  list={departmentList}
                  label={text.DEPARTMENT}
                  value={selectedValues.departmentCode}
                  optionKeyExtractor={item => item.departmentDescription}
                  optionLableExtractor={item => item.departmentCode}
                  onSelect={i => onDepartmentChange(i as DepartmentList)}
                />
              </Col>
              <Col className="mr-2">
                <SearchableDropdown<ClientPOList>
                  list={poNumberList}
                  label={text.PO_NUMBER}
                  value={selectedValues.clientPO}
                  optionKeyExtractor={item => item.clientPO}
                  optionLableExtractor={item => item.clientPO}
                  onSelect={i => onPoNumberChange(i as ClientPOList)}
                />
              </Col>
            </Row>
          <Button
            type="primary"
            className="mx-3 my-2 c-white"
            onClick={onGetList}
            disabled={isGetListDisabled}
            style={{
              backgroundColor: isGetListDisabled
                ? GET_DETAILS_DISABLED_BUTTON
                : GET_DETAILS_BUTTON_COLOR,
              borderColor: isGetListDisabled
                ? GET_DETAILS_DISABLED_BUTTON
                : GET_DETAILS_BUTTON_COLOR,
            }}
          >
            {text.GET_DETAILS}
          </Button>
        </Row>
      </Row>
    </>
  );
}

export default InvoicesAdvancedFilter;
