import { DetailList, Metadata, PostRequestBody, PostResponse, SummaryData } from "models/transferRequest.data";
import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { GetSummaryDetailsParams } from "./type";

export class TransferRequestService {
  public getSummary(requestData: GetSummaryDetailsParams) :
   Promise<SummaryData> | SummaryData {
    const service: ApiService = new ApiService(
      EndPoints.GET_TRANSFER_SUMMARY_DETAILS,
    );
    return service.get({
      dynamicQueryParams: [{
        toDateSearch: requestData.endDate,
        fromDateSearch: requestData.startDate,
        statusCodeSearch: requestData.statusCode,
        internalRole: requestData.internalRole,
      }],
    });
  }

  public getDetailList(requestData: GetSummaryDetailsParams) :
   Promise<DetailList> | DetailList {
    const service: ApiService = new ApiService(
      EndPoints.GET_TRANSFER_REQUEST_LIST,
    );
    return service.get({
      dynamicQueryParams: [{
        toDateSearch: requestData.endDate,
        fromDateSearch: requestData.startDate,
        statusCodeSearch: requestData.statusCode,
        internalRole: requestData.internalRole,
      }],
    });
  }

  public getMetadata() : Promise<Metadata> | Metadata {
    const service: ApiService = new ApiService(
      EndPoints.GET_TRANSFER_METADATA,
    );
    return service.get({
      dynamicQueryParams: [{internalRole: "CLMGR"}],
    });
  }

  public postCandidateRequest(
    request: PostRequestBody,
  ): Promise<PostResponse> | PostResponse {
    const service: ApiService = new ApiService(
      EndPoints.POST_CANDIDATE_TRANSFER,
    );
    return service.post({}, request);
  }

}

export default new TransferRequestService();
