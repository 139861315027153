export const config = {
  issuer: `${process.env.REACT_APP_AUTH_BASE_URL || ""}`,
  authorize: `${process.env.REACT_APP_AUTH_BASE_URL || ""}connect/authorize`,
  redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URL || ""}`,
  token_receiver: "https://adecco-middleware.herokuapp.com",
  client_id: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
  response_type: "code",
  scopes: "rvw_impersonate offline_access",
};

export const webUrl = `${config.authorize}?client_id=${config.client_id}&redirect_uri=${config.redirect_uri}&scope=${config.scopes}&response_type=${config.response_type}`;
