import React, { useEffect, useState } from "react";
import { Card, DatePicker, Button, Row, Modal } from "antd";
import DropDown from "components/Dropdown";
import { CLI_ROLE, CLMGR_ROLE, leaveBalanceDateFormat, menu } from "./constant";
import text from "text";
import DashboardService from "screens/dashboard/service";
import moment, { Moment } from "moment";
import { CLMGR_INTERNAL_ROLE } from "screens/associate/constant";
import { downloadExcel } from "components/DownloadExcel/helper";
import { column, GetApiCode, advanceSalaryColumns } from "./helper";
import { OnboardingMetaDataDetails } from "models/analytics.data";
import AnalyticsService from "screens/analytics/service";
import { ModalPopUp } from "components/Modal/ModalPopUp";
import { ModalType } from "components/Modal/type";
import { downloadExcelData } from "./helper";
import { getDataList, getLeaveBalanceColumn } from "./helper";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/RootReducer";
import { dateCodes } from "date";
import { isArguments } from "lodash";

const { MonthPicker } = DatePicker;
export default function DashboardScreenDownloadReport() {
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<string>(
    ""
  );
  const [isReportLoading, setIsReportLoading] = useState<boolean>(false);
  const [dateFilter, setDateFilters] = useState<{
    startDate: string;
    endDate: string;
  }>({ startDate: "", endDate: "" });
  const [reportData, setReportData] = useState<any>();
  const [isMetaDataLoading, setIsMetaDataLoading] = useState<boolean>(false);
  const [metaDataAnalytics, setMetaDataDetails] = useState<
    OnboardingMetaDataDetails
  >();
  const [isYearPickerOpen, setIsYearPickerOpen] = useState(false);
  const [yearDate, setYearDate] = useState<Moment | null>();

  const { contextStore } = useSelector((state: ApplicationState) => state);

  const isClientManager = contextStore.userDetails.data.find(
    (item: any) => item.internalRole === CLMGR_ROLE
  )
    ? true
    : false;

  const getMetaDataDetails = async (params: {
    internalRole: string;
    apiCode: string;
    callingFrom: string;
    fromDateSearch: string;
    toDateSearch: string;
  }) => {
    try {
      setIsMetaDataLoading(true);
      const response = await AnalyticsService.getOnboardingMetaDataAnalyticsList(
        params
      );
      setMetaDataDetails(response);
    } catch (err) {
    } finally {
      setIsMetaDataLoading(false);
    }
  };

  const getReportDetails = async (params: {
    internalRole: string;
    startDate: string;
    endDate: string;
  }) => {
    try {
      setIsReportLoading(true);
      let response;
      if (selectedDropdownValue === "Reimbursement")
        response = await DashboardService.getReimbursementReport(params);
      else if (selectedDropdownValue === "Leave")
        response = await DashboardService.getLeaveReport(params);
      else if (selectedDropdownValue === "Leave Balance")
        response = await DashboardService.getLeaveBalanceReport(params);
      else if (selectedDropdownValue === "Attendance")
        response = await DashboardService.getAttendanceReport(params);
      else if(selectedDropdownValue === "Advance Salary"){
        response = await DashboardService.getAdvanceSalaryReport(params);
        response.data.loanList = response.data.loanList.map(item => 
          {
            item.applicationDate = moment(item.applicationDate).format("DD-MM-YYYY");
            item.loanRecoveryEffectiveFrom = moment(item.loanRecoveryEffectiveFrom).format("DD-MM-YYYY");
            item.loanRecoveryEffectiveTo = moment(item.loanRecoveryEffectiveTo).format("DD-MM-YYYY");
            return item;
          }
        )
      }
      setReportData(response);
    } catch (err) {
    } finally {
      setIsReportLoading(false);
    }
  };

  const lastday = (year: number, month: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handledateFilter = (date: moment.Moment | null, dateString: string) => {
    const year = date?.year();
    let startDate, endDate;
    if (dateString.length > 7) {
      startDate = `${year}-01-01`;
      endDate = `${year}-12-31`;
    } else {
      const month = date?.month();
      const monthStr = month! < 9 ? `0${month! + 1}` : `${month! + 1}`;
      const manipulatedEndDate = `${year}-${monthStr}-${String(
        lastday(year!, month!)
      )}`;
      startDate = `${year}-${monthStr}-01`;
      endDate = manipulatedEndDate;
    }
    setDateFilters({
      startDate: date === null ? "" : startDate,
      endDate: date === null ? "" : endDate,
    });
    getMetaDataDetails({
      internalRole: isClientManager ? CLMGR_ROLE : CLI_ROLE,
      apiCode: GetApiCode(selectedDropdownValue),
      callingFrom: "OTHER_API",
      fromDateSearch: startDate,
      toDateSearch: endDate,
    });
    date &&
      getReportDetails({
        internalRole: isClientManager ? CLMGR_ROLE : CLI_ROLE,
        startDate,
        endDate,
      });
  };
  const handleMenuClick = (value: string) => {
    setDateFilters({
      startDate: "",
      endDate: "",
    });
    setSelectedDropdownValue(value);
  };

  const handleYearFilter = (date: moment.Moment | null) => {
    setIsYearPickerOpen(false);
    setYearDate(date);
    date && handledateFilter(date, date.format("DD-MM-YYYY"));
  };

  const onDownload = () => {
    downloadExcelData(selectedDropdownValue, reportData) &&
    downloadExcelData(selectedDropdownValue, reportData).length > 0
      ? downloadExcel(
          selectedDropdownValue === text.LEAVE_BALANCE
            ? getLeaveBalanceColumn(metaDataAnalytics!, reportData.data!)
            : (selectedDropdownValue === text.ADVANCE_SALARY ? advanceSalaryColumns :column(metaDataAnalytics!)),
          selectedDropdownValue === text.LEAVE_BALANCE
            ? getDataList(
                text.LEAVE_BALANCE,
                metaDataAnalytics!,
                reportData?.data
              )
            : downloadExcelData(selectedDropdownValue, reportData),
          `${selectedDropdownValue} Report`
        )
      : ModalPopUp({
          type: ModalType.info,
          title: text.INFO.toUpperCase(),
          description:
            selectedDropdownValue === text.LEAVE_BALANCE
              ? text.NO_YEAR_DATA
              : text.NO_DATA,
        });
  };

  const isDisabled = (date: Moment| null) => {
    if(selectedDropdownValue === "Attendance" || selectedDropdownValue === "Advance Salary") {
      return date && date > moment() ? true : false;
    }
    return false;
  };

  return (
    <Card
      title={text.DOWNLOAD_REPORT}
      type="inner"
      className="my-12 mr-0 mr-sm-10">
      <DropDown
        menuList={menu}
        dropdownText={text.SELECT}
        getSelectedValue={handleMenuClick}
        className="w-100"
      />
      {selectedDropdownValue !== "Leave Balance" ? (
        <MonthPicker
          className="py-5 w-100"
          onChange={handledateFilter}
          placeholder={text.SELECT_MONTH}
          format={dateCodes.MONTH_YEAR}
          disabledDate={isDisabled}
          disabled={selectedDropdownValue?.length === 0}
          allowClear={true}
          value={
            dateFilter.startDate.length ? moment(dateFilter.startDate) : null
          }
        />
      ) : (
        <DatePicker
          disabled={selectedDropdownValue?.length === 0}
          className="py-5 w-100"
          placeholder={text.SELECT_YEAR}
          mode="year"
          value={yearDate}
          format={leaveBalanceDateFormat}
          onPanelChange={handleYearFilter}
          allowClear={true}
          disabledDate={isDisabled}
          onOpenChange={value => setIsYearPickerOpen(value)}
          open={isYearPickerOpen}
          onChange={value => setYearDate(value)}
        />
      )}
      <Row type="flex" justify="center">
        <Button
          className="m-2 px-3 cursor-pointer"
          type="primary"
          disabled={
            selectedDropdownValue.length === 0 ||
            dateFilter.startDate.length === 0 ||
            isReportLoading ||
            dateFilter.startDate === null ||
            yearDate === null
          }
          loading={isReportLoading}
          onClick={onDownload}>
          {text.DOWNLOAD_REPORT}
        </Button>
      </Row>
    </Card>
  );
}
