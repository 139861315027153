import React from "react";
import { Row, Table, Empty } from "antd";
import Loader from "components/Loader";
import text from "text";
import {
  column,
  getDownloadExcelData,
  getValidationList,
} from "screens/bulkAttendance/helper";
import { actionTypeCodes, VIEW_RECORD } from "screens/bulkAttendance/constant";
import { TableData } from "screens/bulkAttendance/type";
import { IBulkTimesheetTableProps } from "./type";
import { uploadFile } from "AssetHelper";
import DownloadSheet from "components/DownloadSheet/DownloadSheet";
import UploadDoc from "components/UploadDoc/UploadDoc";

function BulkTimesheetTable(props: IBulkTimesheetTableProps) {
  const {
    detailList,
    selectedRowKey,
    attendanceType,
    actionType,
    currentPage,
    inputType,
    listLoading,
    onPageChange,
    onChangeData,
    onChangeSelectedRow,
    onUpload,
    payrollAccess,
  } = props;

  const onChangeRowSelection = (value: any, selectedRowsData: TableData[]) => {
    onChangeSelectedRow(value, selectedRowsData);
  };

  const rowSelection = () => {
    return {
      selectedRowKeys: selectedRowKey,
      onChange: onChangeRowSelection,
      getCheckboxProps: (record: TableData) => ({
        disabled: (record.customEALTextOutput1 === VIEW_RECORD),
      }),
    };
  };

  return (
    <Row id="table">
      <Row type="flex" justify="end" className="pb-4">
        <DownloadSheet
          data={detailList.empAttendanceList}
          lable={text.DOWNLOAD_EXCEL}
          isDisable={
            attendanceType === actionTypeCodes.CONFIRMED ||
            !detailList.empAttendanceList.length
          }
          downloadData={getDownloadExcelData(
            detailList.empAttendanceList,
            attendanceType,
            inputType
          )}
          inputList={getValidationList(attendanceType, inputType)}
        />
        <UploadDoc
          onUpload={onUpload}
          lable={text.UPLOAD_EXCEL}
          type={text.EXCEL}
          isDisable={
            !detailList.empAttendanceList.length || payrollAccess === "VIEW" ||
            !(actionType === actionTypeCodes.DRAFT) || listLoading
              ? true
              : false
          }
          icon={uploadFile}
          iconSize={"7"}
          column={
            getDownloadExcelData(
              detailList.empAttendanceList,
              attendanceType,
              inputType
            ).column
          }
          dataToFit={{
            list: detailList.empAttendanceList,
            key: "employeeCode",
          }}
        />
      </Row>
      {detailList.dayValueList.length && detailList.empAttendanceList.length ? (
        <Table
          columns={column(
            detailList.empAttendanceList,
            detailList.dayValueList,
            attendanceType,
            inputType,
            onChangeData
          )}
          rowSelection={
            actionType !== actionTypeCodes.CONFIRMED
              ? rowSelection()
              : undefined
          }
          scroll={{ x: true }}
          rowKey="employeeCode"
          className="w-100"
          loading={listLoading}
          dataSource={detailList.empAttendanceList}
          pagination={{
            pageSize: 5,
            current: currentPage,
            onChange: onPageChange,
          }}
        />
      ) : (
        <Row className="bg-white w-100">
          {listLoading ? (
            <Row className="m-9">
              <Loader />
            </Row>
          ) : (
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )}
        </Row>
      )}
    </Row>
  );
}

export default React.memo(
  BulkTimesheetTable,
  (prevState, nextState) => prevState === nextState
);
