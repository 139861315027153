import React, { useState, useEffect } from "react";
import { Dropdown, Button, Menu, Icon, Row, Tooltip, List } from "antd";

export default function DropDown(props: {
  menuList: any[];
  dropdownText: string;
  defaultText?: string;
  index?: number;
  actionAlreadyTaken?: boolean;
  getSelectedValue: (selectedValue: any, index?: number) => void;
  className?: any;
  inputStyle?: any;
  width?: any;
}) {
  const {
    menuList,
    dropdownText,
    index,
    getSelectedValue,
    defaultText,
    actionAlreadyTaken,
  } = props;
  const [selectedValue, setSelectedValue] = useState(dropdownText);
  const selected = dropdownText;

  const handleSelect = (data: any) => {
    setSelectedValue(data.item.props.children);
    if (index !== undefined) {
      getSelectedValue(data.item.props.eventKey, index);
    } else {
      getSelectedValue(data.item.props.eventKey);
    }
  };

  useEffect(() => {
    if (selectedValue != selected) setSelectedValue(selected);
  }, [selected]);

  useEffect(() => {
    if (defaultText !== undefined) {
      const list = menuList.find(element => {
        return element.value === defaultText;
      });
      if (list !== undefined) {
        setSelectedValue(list.key);
        getSelectedValue(defaultText);
      }
    }
  }, [defaultText]);

  const renderList = () => {
    return (
      <Menu onClick={handleSelect}>
        {menuList.map((data, index) => (
          <Menu.Item data-item={data.status} key={data.value}>
            <Tooltip title={data.value} placement="left">
            {data.key}
            </Tooltip>
          </Menu.Item>
        ))}
      </Menu>
    );
  };
  const minWidthValue = 15;
  const maxLength = () => {
    return Math.max(...menuList.map(data => data.length));
  };

  return (
    <Dropdown
      overlay={renderList}
      className={props.className ? props.className : "w-100 m-0 m-md-2"}
      disabled={actionAlreadyTaken}
      >
      <Button
        style={{ minWidth: props.width ? props.width: maxLength() * minWidthValue}} 
        className={props.inputStyle ? props.inputStyle : "w-100"}
      >
        <Row type="flex" justify="space-between" align="middle">
          {selectedValue}
          <Icon type="down" />
        </Row>
      </Button>
    </Dropdown>
  );
}
