import AnalyticsService from "./service";
import { useQuery } from "react-query";
import { RequestParams } from "./type";
import {
  OnboardingDetails,
  OnboardingTableDetailsResponse,
} from "models/analytics.data";
import { EndPoints } from "store/model/ApiConfig.data";

const getOnboardingDetails = async (
  params: RequestParams
): Promise<OnboardingDetails> => {
  const data: OnboardingDetails = await AnalyticsService.getOnboardingAnalyticsList(
    params
  );
  return data;
};

export function useOnboardingDetailsList(
  queryKey: EndPoints,
  params: RequestParams
) {
  return useQuery([queryKey, params], async () =>
    getOnboardingDetails({
      toDate: params.toDate,
      fromDate: params.fromDate,
      internalRole: params.internalRole,
      queryKey: queryKey,
      clraStatusCodeSearch: params.clraStatusCodeSearch,
    })
  );
}

const getTableDetails = async (
  params: RequestParams
): Promise<OnboardingTableDetailsResponse> => {
  const data: OnboardingTableDetailsResponse = await AnalyticsService.getOnboardingTableAnalyticsList(
    params
  );
  return data;
};

export function useOnboardingTableDetailsList(
  queryKey: EndPoints,
  params: RequestParams
) {
  return useQuery(queryKey, async () =>
    getTableDetails({
      toDate: params.toDate,
      fromDate: params.fromDate,
      internalRole: params.internalRole,
      queryKey: queryKey,
      clraStatusCodeSearch: params.clraStatusCodeSearch,
    })
  );
}
