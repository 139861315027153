export enum ModuleType {
  ATTENDANCE = "attendance",
  LEAVE = "leave",
  REIMBURSEMENT = "reimbursement",
  // ANALYTICS = "analytics",
  NETPAY = "netpay",
  LINE_MANAGER_CR = "lineManager",
  ADVANCE_SALARY = "advance-salary-request",
  ASSOCIATE = "associate",
  NOTIFICATION = "notification",
  ASSOCIATE_CONTRACT_EXPIRY = "associate_contract_expiry",
  ASSOCIATE_CONTRACT_ANALYTICS = "associate_contract_analytics",
  EMPLOYEE_ADDITIONS_ANALYTICS = "employee_additions_analytics",
  EMPLOYEE_HEAD_COUNT_ANALYTICS = "employee_head_count_analytics",
  INVOICE_ANALYTICS = "invoice_analytics",
  NETPAY_ANALYTICS = "netpay_analytics",
  EMPLOYEE_PAYOUT_ANALYTICS = "employee_payout_analytics",
  EMPLOYEE_PAYROLL_PROCESSING_ANALYTICS = "employee_payroll_processing_analytics",
  ATTENDANCE_RECEIPT_ANALYTICS = "attendance_receipt_analytics",
  ASSOCIATE_EXIT_ANALYTICS = "associate_exit_interview",
  ONBOARDING_EXPECTED_TO_JOIN = "onboarding_expected_to_join",
  ONBOARDING_INITIATED = "onboarding_initiated",
  ONBOARDING_COMPLETED = "onboarding_completed",
  ONBOARDING_JOINED = "onboarding_joined",
  CLIENT_CONTRACT = "client_contract",
  CLIENT_CLRA_STATUS = "client_clra_status",
  BULK_TIMESHEET = "bulkTimesheet",
  BULK_PAYROLL = "other-input",
  INVOICE = "invoice",
  RESIGNATION = "resignation",
  TRANSFER_REQUEST = "transfer-request",
  REPROTS = "reports",
  DAILY_ATTENDANCE = "dailyAttendance",
  BGV = "bgv",
}

export const roleMappingCli: { [key: string]: string } = {
  CATA: ModuleType.ATTENDANCE,
  CDATA:ModuleType.DAILY_ATTENDANCE,
  CLVA: ModuleType.LEAVE,
  CREA: ModuleType.REIMBURSEMENT,
  CLAA: ModuleType.ADVANCE_SALARY,
  CAAL: ModuleType.ASSOCIATE,
  AUNL: ModuleType.NOTIFICATION,
  CACE: ModuleType.ASSOCIATE_CONTRACT_EXPIRY,
  EACD: ModuleType.ASSOCIATE_CONTRACT_ANALYTICS,
  EEAD: ModuleType.EMPLOYEE_ADDITIONS_ANALYTICS,
  EEHC: ModuleType.EMPLOYEE_HEAD_COUNT_ANALYTICS,
  IINV: ModuleType.INVOICE_ANALYTICS,
  INPD: ModuleType.NETPAY_ANALYTICS,
  PEPO: ModuleType.EMPLOYEE_PAYOUT_ANALYTICS,
  PPRO: ModuleType.EMPLOYEE_PAYROLL_PROCESSING_ANALYTICS,
  TARA: ModuleType.ATTENDANCE_RECEIPT_ANALYTICS,
  AEAL: ModuleType.ASSOCIATE_EXIT_ANALYTICS,
  OEJAL: ModuleType.ONBOARDING_EXPECTED_TO_JOIN,
  OINAL: ModuleType.ONBOARDING_INITIATED,
  OCMAL: ModuleType.ONBOARDING_COMPLETED,
  OJOAL: ModuleType.ONBOARDING_JOINED,
  CCCE: ModuleType.CLIENT_CONTRACT,
  CCLRA: ModuleType.CLIENT_CLRA_STATUS,
  BAIDS: ModuleType.BULK_TIMESHEET,
  BPIDS: ModuleType.BULK_PAYROLL,
  IDDV: ModuleType.INVOICE,
  NPAV: ModuleType.NETPAY,
  LNCH: ModuleType.LINE_MANAGER_CR,
  CLRES: ModuleType.RESIGNATION,
  CLTRF: ModuleType.TRANSFER_REQUEST,
  CLRPT: ModuleType.REPROTS,
  BGV: ModuleType.BGV,
};

export const defaultAllowedActivities = {
  [`${ModuleType.ATTENDANCE}`]: false,
  [`${ModuleType.DAILY_ATTENDANCE}`]: false,
  [`${ModuleType.LEAVE}`]: false,
  [`${ModuleType.REIMBURSEMENT}`]: false,
  [`${ModuleType.LINE_MANAGER_CR}`]: false,
  [`${ModuleType.ADVANCE_SALARY}`]: false,
  [`${ModuleType.ASSOCIATE}`]: false,
  [`${ModuleType.NOTIFICATION}`]: false,
  [`${ModuleType.ASSOCIATE_CONTRACT_EXPIRY}`]: false,
  [`${ModuleType.ASSOCIATE_CONTRACT_ANALYTICS}`]: false,
  [`${ModuleType.EMPLOYEE_ADDITIONS_ANALYTICS}`]: false,
  [`${ModuleType.EMPLOYEE_HEAD_COUNT_ANALYTICS}`]: false,
  [`${ModuleType.INVOICE_ANALYTICS}`]: false,
  [`${ModuleType.NETPAY_ANALYTICS}`]: false,
  [`${ModuleType.EMPLOYEE_PAYOUT_ANALYTICS}`]: false,
  [`${ModuleType.EMPLOYEE_PAYROLL_PROCESSING_ANALYTICS}`]: false,
  [`${ModuleType.ATTENDANCE_RECEIPT_ANALYTICS}`]: false,
  [`${ModuleType.ASSOCIATE_EXIT_ANALYTICS}`]: false,
  [`${ModuleType.ONBOARDING_EXPECTED_TO_JOIN}`]: false,
  [`${ModuleType.ONBOARDING_INITIATED}`]: false,
  [`${ModuleType.ONBOARDING_COMPLETED}`]: false,
  [`${ModuleType.ONBOARDING_JOINED}`]: false,
  [`${ModuleType.BGV}`]: false,
  [`${ModuleType.CLIENT_CONTRACT}`]: false,
  [`${ModuleType.CLIENT_CLRA_STATUS}`]: false,
  [`${ModuleType.BULK_TIMESHEET}`]: false,
  [`${ModuleType.BULK_PAYROLL}`]: false,
  [`${ModuleType.INVOICE}`]: false,
  [`${ModuleType.NETPAY}`]: false,
  [`${ModuleType.LINE_MANAGER_CR}`]: false,
  [`${ModuleType.RESIGNATION}`]: false,
  [`${ModuleType.TRANSFER_REQUEST}`]: false,
  [`${ModuleType.REPROTS}`]: false,
};
