import React from "react";
import {
  CandidateDetailsList,
  CityList,
  CompensationList,
  CountryList,
  DepartmentList,
  DetailListObj,
  GenderList,
  Metadata,
  OfferProcessAplicableList,
  PFApplicability,
  PositionList,
  SkillTypeList,
  StateList,
  StoreBranchList,
  TitleList,
} from "models/transferRequest.data";
import text from "text";
import { TableData } from "./type";
import { DatePicker, Input, Popconfirm, Row, Typography } from "antd";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import moment, { Moment } from "moment";
import { dateCodes, dateFormat, defaultClientLeaveEndDate, defaultClientLeaveStartDate } from "date";
import { offerProcessApplicable } from "./constant";
import { FilterParams } from "./type";

const {Text} = Typography;

export const getFilterDate = (filters: FilterParams) => {
  let filterStartDate = filters.startDate;
  let filterEndDate = filters.endDate;
  if (filterStartDate && filterEndDate) {
    if (filterStartDate > filterEndDate) {
      const tempFliterStartDate = filterStartDate.clone();
      filterEndDate = tempFliterStartDate.endOf("year");
    }
  } else if (filterStartDate && !filterEndDate) {
    if (filterStartDate > defaultClientLeaveEndDate) {
      const tempFliterStartDate = filterStartDate.clone();
      filterEndDate = tempFliterStartDate.endOf("year");
    }
  }
  if (filterStartDate) {
    filterStartDate = filterStartDate.startOf("day");
  }
  if (filterEndDate) {
    filterEndDate = filterEndDate.endOf("day");
  }
  const startDate = filterStartDate || defaultClientLeaveStartDate;
  const endDate = filterEndDate || defaultClientLeaveEndDate;
  return { startDate, endDate };
};

export const getSelectedTab = (activeKey: string) => {
  switch (activeKey) {
    case "1":
      return text.RAISE_NEW_REQUEST;
    case "2":
      return text.NEW_PROFILE;
    default:
      return text.REQUESTED_TRANSFERS;
  }
};

export function requestedTransferColumn() {
  return [
    {
      title: text.TITLE,
      key: text.TITLE,
      dataIndex: "title",
      align: "center",
    },
    {
      title:text.FIRST_NAME,
      key: text.FIRST_NAME,
      dataIndex: "firstName",
      align: "center",
      render: (firstName: string, row: TableData, index: number) => {
        return (
          <Row type="flex">{firstName}</Row>
        );
      },
    },
    {
      title: text.LAST_NAME,
      key: text.LAST_NAME,
      dataIndex: "lastName",
      align: "center",
      render: (lastName: string, row: TableData, index: number) => {
        return (
          <Row type="flex">{lastName}</Row>
        );
      },
    },
    {
      title: text.GENDER,
      key: text.GENDER,
      dataIndex: "gender",
      align: "center",
    },
    {
      title: text.POSITION,
      key: text.POSITION,
      dataIndex: "position",
      align: "center",
      render: (position: string, row: TableData, index: number) => {
        return (
          <Row type="flex">{position}</Row>
        );
      },
    },
    {
      title: text.AADHAR_NO,
      key: text.AADHAR_NO,
      dataIndex: "aadharNo",
      align: "center",
      render: (aadharNo: string, row: TableData, index: number) => {
        return (
          <Row type="flex">{aadharNo}</Row>
        );
      },
    },
    {
      title: text.PHONE,
      key: text.PHONE,
      dataIndex: "phone",
      align: "center",
      render: (phone: string, row: TableData, index: number) => {
        return (
          <Row type="flex">{phone}</Row>
        );
      },
    },
    {
      title: text.EMAIL,
      key: text.EMAIL,
      align: "center",
      dataIndex: "email",
      render: (email: string, row: TableData, index: number) => {
        return (
          <Row type="flex">{email}</Row>
        );
      },
    },
    {
      title:text.DOB,
      key: text.DOB,
      dataIndex: "dateOfBirth",
      align: "center",
      render: (dateOfBirth: string, row: TableData, index: number) => {
        return (
          <Row type="flex">{dateOfBirth}</Row>
        );
      },
    },
    {
      title:text.COUNTRY,
      key: text.COUNTRY,
      dataIndex: "country",
      align: "center",
    },
    {
      title:text.STATE,
      key: text.STATE,
      dataIndex: "state",
      align: "center",
      render: (state: string, row: TableData, index: number) => {
        return (
          <Row type="flex">{state}</Row>
        );
      },
    },
    {
      title:text.CITY,
      key: text.CITY,
      dataIndex: "city",
      align: "center",
      render: (city: string, row: TableData, index: number) => {
        return (
          <Row type="flex">{city}</Row>
        );
      },
    },
    {
      title: text.DEPARTMENT,
      key: text.DEPARTMENT,
      dataIndex: "department",
      align: "center",
    },
    {
      title: text.STORE_BRANCH,
      key: text.STORE_BRANCH,
      dataIndex: "storeBranch",
      align: "center",
      render: (storeBranch: string, row: TableData, index: number) => {
        return (
          <Row type="flex">{storeBranch}</Row>
        );
      },
    },
    {
      title: text.SKILL_TYPE,
      key: text.SKILL_TYPE,
      align: "center",
      dataIndex: "skillType",
    },
    {
      title: text.PF_APPLICABILITY,
      key: text.PF_APPLICABILITY,
      dataIndex: "pfApplicability",
      align: "center",
      render: (pfApplicability: string, row: TableData, index: number) => {
        return (
          <Row type="flex">{pfApplicability}</Row>
        );
      },
    },
    {
      title: text.EXPECTED_DOJ,
      key: text.EXPECTED_DOJ,
      dataIndex: "expectedDOJ",
      align: "center",
    },
    {
      title: text.CLIENT_COMPENSATION,
      key: text.CLIENT_COMPENSATION,
      dataIndex: "clientCompensation",
      align: "center",
    },
    {
      title: text.COMPENSATION_UOM,
      key: text.COMPENSATION_UOM,
      dataIndex: "compensationUOM",
      align: "center",
    },
    {
      title: text.OFFER_CLIENT_APPROVAL,
      key: text.OFFER_CLIENT_APPROVAL,
      dataIndex: "offerClientApproval",
      align: "center",
    },
    {
      title: text.OFFER_PROCESS_APPLICABLE,
      key: text.OFFER_PROCESS_APPLICABLE,
      dataIndex: "offerProcessApplicable",
      align: "center",
    },
    {
      title: text.CONTRACT_START_DATE,
      key: text.CONTRACT_START_DATE,
      dataIndex: "contractStartDate",
      align: "center",
    },
    {
      title: text.CONTRACT_END_DATE,
      key: text.CONTRACT_END_DATE,
      dataIndex: "contractEndDate",
      align: "center",
    },
  ];
}

export function newRequestColumn(
  onChangeData: (
    inputValue: string,
    key: string,
    row: TableData,
  ) => void,
  metadata: Metadata,
  handleDelete: (index: number) => void,
  currentTab: string,
  isSaveLoading: boolean,
) {
  const fixed = window.innerWidth > 600 ? "right" : "";
  const columns = [
    {
      title: text.TITLE,
      key: "title",
      dataIndex: "title",
      align: "center",
      render: (title: string, row: TableData, index: number) => {
        const selectTitle = (data: TitleList) => {
          onChangeData(data.titleCode, "title", row);
        };
        return (
          <SearchableDropdown<TitleList>
            list={metadata.data.titleList || []}
            onSelect={(i) => selectTitle(i as TitleList)}
            optionKeyExtractor={(item) => item.titleCode}
            optionLableExtractor={(item) => item.titleDescription}
            value={title}
            width={100}
          />
        );
      },
    },
    {
      title: text.FIRST_NAME,
      key: "firstName",
      dataIndex: "firstName",
      align: "center",
      render: (firstName: string, row: TableData, index: number) => {
        return (
          <Input
            disabled={currentTab === text.NEW_PROFILE}
            value={firstName}
            onChange={e => {
              onChangeData(e.target.value, "firstName", row);
            }}
          />
        );
      },
    },
    {
      title: text.LAST_NAME,
      key: "lastName",
      dataIndex: "lastName",
      align: "center",
      render: (lastName: string, row: TableData, index: number) => {
        return (
          <Input
            disabled={currentTab === text.NEW_PROFILE}
            value={lastName}
            onChange={e => {
              onChangeData(e.target.value, "lastName", row);
            }}
          />
        );
      },
    },
    {
      title: text.GENDER,
      key: "gender",
      dataIndex: "gender",
      align: "center",
      render: (gender: string, row: TableData, index: number) => {
        const selectGender = (data: GenderList) => {
          onChangeData(data.genderCode, "gender", row);
        };
        return (
          <SearchableDropdown<GenderList>
            list={metadata.data.genderList || []}
            onSelect={(i) => selectGender(i as GenderList)}
            optionKeyExtractor={(item) => item.genderCode}
            optionLableExtractor={(item) => item.genderDescription}
            value={gender}
          />
        );
      },
    },
    {
      title: text.POSITION,
      key: "position",
      dataIndex: "position",
      align: "center",
      render: (position: string, row: TableData, index: number) => {
        const selectPosition = (data: PositionList) => {
          onChangeData(data.positionCode, "position", row);
        };
        return (
          <SearchableDropdown<PositionList>
            isDisabled={currentTab === text.NEW_PROFILE}
            list={metadata.data.positionList || []}
            onSelect={(i) => selectPosition(i as PositionList)}
            optionKeyExtractor={(item) => item.positionCode}
            optionLableExtractor={(item) => item.positionDescription}
            value={position}
          />
        );
      },
    },
    {
      title: text.AADHAR_NO,
      key: text.AADHAR_NO,
      dataIndex: "aadharNo",
      align: "center",
      render: (aadharNo: string, row: TableData, index: number) => {
        return (
          <Input
            value={aadharNo}
            onChange={e => {
              onChangeData(e.target.value, "aadharNo", row);
            }}
          />
        );
      },
    },
    {
      title: text.PHONE,
      key: "phone",
      dataIndex: "phone",
      align: "center",
      render: (phone: string, row: TableData, index: number) => {
        return (
          <Input
            disabled={currentTab === text.NEW_PROFILE}
            value={phone}
            onChange={e => {
              onChangeData(e.target.value, "phone", row);
            }}
          />
        );
      },
    },
    {
      title: text.EMAIL,
      key: "email",
      dataIndex: "email",
      align: "center",
      render: (email: string, row: TableData, index: number) => {
        return (
          <Input
            disabled={currentTab === text.NEW_PROFILE}
            value={email}
            onChange={e => {
              onChangeData(e.target.value, "email", row);
            }}
          />
        );
      },
    },
    {
      title: text.DOB,
      key: "dateOfBirth",
      dataIndex: "dateOfBirth",
      align: "center",
      render: (dob: string, row: TableData, index: number) => {
        const onDateChange = (date: Moment | null, dateString: string) => {
          onChangeData(date ? date.format(dateCodes.TO_ISO_8601) : "", "dateOfBirth", row);
        };
        const isDisabled = (date: Moment | null) => {
          if (date && date >= moment()) return true;
          return false;
        };
        return (
          <DatePicker
            format={"DD-MM-YYYY"}
            disabledDate={isDisabled}
            value={dob ? moment(dob): null}
            onChange={onDateChange}
          />
        );
      },
    },
    {
      title: text.COUNTRY,
      key: text.COUNTRY,
      dataIndex: "country",
      align: "center",
      render: (country: string, row: TableData, index: number) => {
        const selectCountry = (data: CountryList) => {
          if (row.state || row.city) {
            row.state = "";
            row.city = "";
          }
          onChangeData(data.countryCode, "country", row);
        };
        return (
          <SearchableDropdown<CountryList>
            list={metadata.data.countryList || []}
            onSelect={(i) => selectCountry(i as CountryList)}
            optionKeyExtractor={(item) => item.countryCode}
            optionLableExtractor={(item) => item.countryDescription}
            value={country}
          />
        );
      },
    },
    {
      title: text.STATE,
      key: text.STATE,
      dataIndex: "state",
      align: "center",
      render: (state: string, row: TableData, index: number) => {
        const selectState = (data: any) => {
          if (row.city) {
            row.city = "";
          }
          onChangeData(data.stateCode, "state", row);
        };
        const getStateList = () => {
          return metadata.data.countryList.find(item => item.countryCode === row.country)?.stateList;
        };
        return (
          <SearchableDropdown<StateList>
            list={getStateList() || []}
            onSelect={(i) => selectState(i as StateList)}
            optionKeyExtractor={(item) => item.stateCode}
            optionLableExtractor={(item) => item.stateDescription}
            value={state}
            isDisabled={!row.country}
          />
        );
      },
    },
    {
      title: text.CITY,
      key: text.CITY,
      dataIndex: "city",
      align: "center",
      render: (city: string, row: TableData, index: number) => {
        const selectCity = (data: any) => {
          onChangeData(data.cityCode, "city", row);
        };
        const getCityList = () => {
          return metadata.data.countryList.find(item => item.countryCode === row.country)
            ?.stateList.find(state => state.stateCode === row.state)?.cityList;
        };
        return (
          <SearchableDropdown<CityList>
            list={getCityList() || []}
            onSelect={(i) => selectCity(i as CityList)}
            optionKeyExtractor={(item) => item.cityCode}
            optionLableExtractor={(item) => item.cityDescription}
            value={city}
            isDisabled={!row.country || !row.state}
          />
        );
      },
    },
    {
      title: text.DEPARTMENT,
      key: text.DEPARTMENT,
      dataIndex: "department",
      align: "center",
      render: (department: string, row: TableData, index: number) => {
        const selectDepartment = (data: DepartmentList) => {
          onChangeData(data.divisionCode, "department", row);
        };
        return (
          <SearchableDropdown<DepartmentList>
            list={metadata.data.departmentList || []}
            onSelect={(i) => selectDepartment(i as DepartmentList)}
            optionKeyExtractor={(item) => item.divisionCode}
            optionLableExtractor={(item) => item.divisionName}
            value={department}
          />
        );
      },
    },
    {
      title: text.STORE_BRANCH,
      key: "storeBranch",
      dataIndex: "storeBranch",
      align: "center",
      render: (storeBranch: string, row: TableData, index: number) => {
        const selectBranch = (data: StoreBranchList) => {
          onChangeData(data.storeBranchCode, "storeBranch", row);
        };
        return (
          <SearchableDropdown<StoreBranchList>
            list={metadata.data.storeBranchList || []}
            onSelect={(i) => selectBranch(i as StoreBranchList)}
            optionKeyExtractor={(item) => item.storeBranchCode}
            optionLableExtractor={(item) => item.storeBranchDescription}
            value={storeBranch}
          />
        );
      },
    },
    {
      title: text.SKILL_TYPE,
      key: text.SKILL_TYPE,
      dataIndex: "skillType",
      align: "center",
      render: (skillType: string, row: TableData, index: number) => {
        const selectSkillType = (data: SkillTypeList) => {
          onChangeData(data.skillTypeCode, "skillType", row);
        };
        return (
          <SearchableDropdown<SkillTypeList>
            list={metadata.data.skillTypeList || []}
            onSelect={(i) => selectSkillType(i as SkillTypeList)}
            optionKeyExtractor={(item) => item.skillTypeCode}
            optionLableExtractor={(item) => item.skillTypeDescription}
            value={skillType}
          />
        );
      },
    },
    {
      title: text.PF_APPLICABILITY,
      key: text.PF_APPLICABILITY,
      dataIndex: "pfApplicability",
      align: "center",
      render: (pf: string, row: TableData, index: number) => {
        const selectSBU = (data: PFApplicability) => {
          onChangeData(data.pfApplicabilityCode, "pfApplicability", row);
        };
        return (
          <SearchableDropdown<PFApplicability>
            list={metadata.data.pfApplicabilityList || []}
            onSelect={(i) => selectSBU(i as PFApplicability)}
            optionKeyExtractor={(item) => item.pfApplicabilityCode}
            optionLableExtractor={(item) => item.pfApplicability}
            value={pf}
          />
        );
      },
    },
    {
      title: text.EXPECTED_DOJ,
      key: text.EXPECTED_DOJ,
      dataIndex: "expectedDOJ",
      align: "center",
      render: (expectedDOJ: string, row: TableData, index: number) => {
        const onDateChange = (date: Moment | null, dateString: string) => {
          onChangeData(date ? date.format(dateCodes.TO_ISO_8601) : "", "expectedDOJ", row);
        };
        const isDisabled = (date: Moment | null) => {
          if (date && date < moment()) return true;
          return false;
        };
        return (
          <DatePicker
            format={"DD-MM-YYYY"}
            disabledDate={isDisabled}
            value={expectedDOJ ? moment(expectedDOJ): null}
            onChange={onDateChange}
          />
        );
      },
    },
    {
      title: text.CLIENT_COMPENSATION,
      key: text.CLIENT_COMPENSATION,
      dataIndex: "clientCompensation",
      align: "center",
      render: (clientCompensation: string, row: TableData, index: number) => {
        return (
          <Input
            value={clientCompensation}
            onChange={e => {
              const reg = /^-?\d*(\.\d*)?$/;
              if (!isNaN(Number(e.target.value)) && reg.test(e.target.value))
                onChangeData(e.target.value, "clientCompensation", row);
            }}
          />
        );
      },
    },
    {
      title: text.COMPENSATION_UOM,
      key: text.COMPENSATION_UOM,
      dataIndex: "compensationUOM",
      align: "center",
      render: (compensationUOM: string, row: TableData, index: number) => {
        const selectCompensationUOM = (data: CompensationList) => {
          onChangeData(data.compensationUOMCode, "compensationUOM", row);
        };
        return (
          <SearchableDropdown<CompensationList>
            list={metadata.data.compensationUOMList || []}
            onSelect={(i) => selectCompensationUOM(i as CompensationList)}
            optionKeyExtractor={(item) => item.compensationUOMCode}
            optionLableExtractor={(item) => item.compensationUOMDescription}
            value={compensationUOM}
          />
        );
      },
    },
    {
      title: text.OFFER_CLIENT_APPROVAL,
      key: text.OFFER_CLIENT_APPROVAL,
      dataIndex: "offerClientApproval",
      align: "center",
      render: (clientApproval: string, row: TableData, index: number) => {
        const selectClientApproval = (data: OfferProcessAplicableList) => {
          onChangeData(data.code, "offerClientApproval", row);
        };
        return (
          <SearchableDropdown<OfferProcessAplicableList>
            list={offerProcessApplicable || []}
            onSelect={(i) => selectClientApproval(i as OfferProcessAplicableList)}
            optionKeyExtractor={(item) => item.code}
            optionLableExtractor={(item) => item.value}
            value={clientApproval}
            width={150}
          />
        );
      },
    },
    {
      title: text.OFFER_PROCESS_APPLICABLE,
      key: text.OFFER_PROCESS_APPLICABLE,
      dataIndex: "offerProcessApplicable",
      align: "center",
      render: (processAplicable: string, row: TableData, index: number) => {
        const selectProcessApplicable = (data: OfferProcessAplicableList) => {
          onChangeData(data.code, "offerProcessApplicable", row);
        };
        return (
          <SearchableDropdown<OfferProcessAplicableList>
            list={offerProcessApplicable || []}
            onSelect={(i) => selectProcessApplicable(i as OfferProcessAplicableList)}
            optionKeyExtractor={(item) => item.code}
            optionLableExtractor={(item) => item.value}
            value={processAplicable}
            width={150}
          />
        );
      },
    },
    {
      title: text.CONTRACT_START_DATE,
      key: text.CONTRACT_START_DATE,
      dataIndex: "contractStartDate",
      align: "center",
      render: (contractStartDate: string, row: TableData, index: number) => {
        const onDateChange = (date: Moment | null, dateString: string) => {
          onChangeData(date ? date.format(dateCodes.TO_ISO_8601) : "", "contractStartDate", row);
        };
        return (
          <DatePicker
            format={"DD-MM-YYYY"}
            value={contractStartDate? moment(contractStartDate): null}
            onChange={onDateChange}
          />
        );
      },
    },
    {
      title: text.CONTRACT_END_DATE,
      key: text.CONTRACT_END_DATE,
      dataIndex: "contractEndDate",
      align: "center",
      fixed: "",
      render: (contractEndDate: string, row: TableData, index: number) => {
        const onDateChange = (date: Moment | null, dateString: string) => {
          onChangeData(date ? date.format(dateCodes.TO_ISO_8601) : "", "contractEndDate", row);
        };
        return (
          <DatePicker
            format={"DD-MM-YYYY"}
            value={contractEndDate? moment(contractEndDate): null}
            onChange={onDateChange}
          />
        );
      },
    },
  ];
  if (currentTab === text.RAISE_NEW_REQUEST)
    columns.push({
      title: "",
      dataIndex: "delete",
      key: "delete",
      align: "center",
      fixed,
      render: (del: string, row: TableData, index: number) => {
        return (
          <Popconfirm title="Sure to delete?" onConfirm={() => handleDelete(index)} disabled={isSaveLoading}>
            <a>{text.DELETE}</a>
          </Popconfirm>
        );
      },
    });
  return columns;
}

export function getDescription(
  inputCode: string,
  inputTypeList: any[],
  code: string,
  desc: string,
) {
  const input = inputTypeList.find(item => item[code] === inputCode);
  return input ? input[desc] : "";
}

export const getCode = (
  value: string,
  inputList: any[],
  code: string,
  desc: string,
) => {
  const input = inputList.find(item => item[desc] === value);
  return input ? input[code] : "";
};

export const getColumnName = (num: number) => {
  for (var ret = "", a = 1, b = 26; (num -= a) >= 0; a = b, b *= 26) {
    ret = String.fromCharCode((num % b) / a + 65) + ret;
  }
  return ret;
};

export const getDownloadExcelData = (
  data: TableData[],
  metadata: Metadata,
) => {
  const validationArray: {
    col: string;
    validation: {};
  }[] = [];
  const dataColumns: {
    header: string;
    key: string;
    dataIndex: string;
  }[] = [];
  dataColumns.push(
    {
      header: text.TITLE,
      key: text.TITLE,
      dataIndex: "title",
    },
    {
      header: text.FIRST_NAME,
      key: text.FIRST_NAME,
      dataIndex: "firstName",
    },
    {
      header: text.LAST_NAME,
      key: text.LAST_NAME,
      dataIndex: "lastName",
    },
    {
      header: text.GENDER,
      key: text.GENDER,
      dataIndex: "gender",
    },
    {
      header: text.POSITION,
      key: text.POSITION,
      dataIndex: "position",
    },
    {
      header: text.AADHAR_NO,
      key: text.AADHAR_NO,
      dataIndex: "aadharNo",
    },
    {
      header: text.PHONE,
      key: text.PHONE,
      dataIndex: "phone",
    },
    {
      header: text.EMAIL,
      key: text.EMAIL,
      dataIndex: "email",
    },
    {
      header: text.DOB,
      key: text.DOB,
      dataIndex: "dateOfBirth",
    },
    {
      header: text.COUNTRY,
      key: text.COUNTRY,
      dataIndex: "country",
    },
    {
      header: text.STATE,
      key: text.STATE,
      dataIndex: "state",
    },
    {
      header: text.CITY,
      key: text.CITY,
      dataIndex: "city",
    },
    {
      header: text.DEPARTMENT,
      key: text.DEPARTMENT,
      dataIndex: "department",
    },
    {
      header: text.STORE_BRANCH,
      key: text.STORE_BRANCH,
      dataIndex: "storeBranch",
    },
    {
      header: text.SKILL_TYPE,
      key: text.SKILL_TYPE,
      dataIndex: "skillType",
    },
    {
      header: text.PF_APPLICABILITY,
      key: text.PF_APPLICABILITY,
      dataIndex: "pfApplicability",
    },
    {
      header: text.EXPECTED_DOJ,
      key: text.EXPECTED_DOJ,
      dataIndex: "expectedDOJ",
    },
    {
      header: text.CLIENT_COMPENSATION,
      key: text.CLIENT_COMPENSATION,
      dataIndex: "clientCompensation",
    },
    {
      header: text.COMPENSATION_UOM,
      key: text.COMPENSATION_UOM,
      dataIndex: "compensationUOM",
    },
    {
      header: text.OFFER_CLIENT_APPROVAL,
      key: text.OFFER_CLIENT_APPROVAL,
      dataIndex: "offerClientApproval",
    },
    {
      header: text.OFFER_PROCESS_APPLICABLE,
      key: text.OFFER_PROCESS_APPLICABLE,
      dataIndex: "offerProcessApplicable",
    },
    {
      header: text.CONTRACT_START_DATE,
      key: text.CONTRACT_START_DATE,
      dataIndex: "contractStartDate",
    },
    {
      header: text.CONTRACT_END_DATE,
      key: text.CONTRACT_END_DATE,
      dataIndex: "contractEndDate",
    },
  );
  const titleList: string[] = getValidationList(metadata);
  const validateTitleList = {
    col: getColumnName(1),
    validation: {
      type: "list",
      allowBlank: false,
      showErrorMessage: true,
      errorStyle: "error",
      errorTitle: text.SELECT_CORRECT_VALUE,
      formulae: [`$CD$100:$CD${titleList.length + 100}`],
    },
  };
  validationArray.push(validateTitleList);
  const genderList: string[] = getGenderList(metadata);
  const validateGenderList = {
    col: getColumnName(4),
    validation: {
      type: "list",
      allowBlank: false,
      showErrorMessage: true,
      errorStyle: "error",
      errorTitle: text.SELECT_CORRECT_VALUE,
      formulae: [`$GH$100:$GH${genderList.length + 100}`],
    },
  };
  validationArray.push(validateGenderList);
  const positionList: string[] = getPositionList(metadata);
  const validatePositionList = {
    col: getColumnName(5),
    validation: {
      type: "list",
      allowBlank: false,
      showErrorMessage: true,
      errorStyle: "error",
      errorTitle: text.SELECT_CORRECT_VALUE,
      formulae: [`$EF$100:$EF${positionList.length + 100}`],
    },
  };
  validationArray.push(validatePositionList);
  const countryList: string[] = getCountryList(metadata);
  const validateCountryList = {
    col: getColumnName(10),
    validation: {
      type: "list",
      allowBlank: false,
      showErrorMessage: true,
      errorStyle: "error",
      errorTitle: text.SELECT_CORRECT_VALUE,
      formulae: [`$IJ$100:$IJ${countryList.length + 100}`],
    },
  };
  validationArray.push(validateCountryList);
  const departmentList: string[] = getDepartmentList(metadata);
  const validateDepartmentList = {
    col: getColumnName(13),
    validation: {
      type: "list",
      allowBlank: false,
      showErrorMessage: true,
      errorStyle: "error",
      errorTitle: text.SELECT_CORRECT_VALUE,
      formulae: [`$AB$100:$AB${departmentList.length + 100}`],
    },
  };
  validationArray.push(validateDepartmentList);
  const storeBranchList: string[] = getStoreBranchList(metadata);
  const validateStoreBranchList = {
    col: getColumnName(14),
    validation: {
      type: "list",
      allowBlank: false,
      showErrorMessage: true,
      errorStyle: "error",
      errorTitle: text.SELECT_CORRECT_VALUE,
      formulae: [`$KL$100:$KL${storeBranchList.length + 100}`],
    },
  };
  validationArray.push(validateStoreBranchList);
  const skillTypeList: string[] = getSkillTypeList(metadata);
  const validateSkillTypeList = {
    col: getColumnName(15),
    validation: {
      type: "list",
      allowBlank: false,
      showErrorMessage: true,
      errorStyle: "error",
      errorTitle: text.SELECT_CORRECT_VALUE,
      formulae: [`$MN$100:$MN${skillTypeList.length + 100}`],
    },
  };
  validationArray.push(validateSkillTypeList);
  const pfList: string[] = getPfApplicabilityList(metadata);
  const validatePFList = {
    col: getColumnName(16),
    validation: {
      type: "list",
      allowBlank: false,
      showErrorMessage: true,
      errorStyle: "error",
      errorTitle: text.SELECT_CORRECT_VALUE,
      formulae: [`$QR$100:$QR${pfList.length + 100}`],
    },
  };
  validationArray.push(validatePFList);
  const compensationUOMList: string[] = getCompensationUOMList(metadata);
  const validateCompensationUOMList = {
    col: getColumnName(19),
    validation: {
      type: "list",
      allowBlank: false,
      showErrorMessage: true,
      errorStyle: "error",
      errorTitle: text.SELECT_CORRECT_VALUE,
      formulae: [`$UV$100:$UV${compensationUOMList.length + 100}`],
    },
  };
  validationArray.push(validateCompensationUOMList);
  const offerProcessApplicableList: string[] = getProcessApplicabilityList();
  const validateOfferClientApprovalList = {
    col: getColumnName(20),
    validation: {
      type: "list",
      allowBlank: false,
      showErrorMessage: true,
      errorStyle: "error",
      errorTitle: text.SELECT_CORRECT_VALUE,
      formulae: [`$YZ$100:$YZ${offerProcessApplicableList.length + 100}`],
    },
  };
  validationArray.push(validateOfferClientApprovalList);
  const validateOfferProcessApplicableList = {
    col: getColumnName(21),
    validation: {
      type: "list",
      allowBlank: false,
      showErrorMessage: true,
      errorStyle: "error",
      errorTitle: text.SELECT_CORRECT_VALUE,
      formulae: [`$YZ$100:$YZ${offerProcessApplicableList.length + 100}`],
    },
  };
  validationArray.push(validateOfferProcessApplicableList);
  const excelData: (string | number | undefined)[][] = [];
  data?.map(item => {
    const eachRecord = [];
    const record: any = item;
    eachRecord.push(
      getDescription(record[`title`], metadata.data.titleList, "titleCode", "titleDescription"));
    eachRecord.push(item.firstName);
    eachRecord.push(item.lastName);
    eachRecord.push(
      getDescription(record[`gender`], metadata.data.genderList, "genderCode", "genderDescription"));
    eachRecord.push(
      getDescription(record[`position`], metadata.data.positionList, "positionCode", "positionDescription"));
    eachRecord.push(item.aadharNo);
    eachRecord.push(item.phone);
    eachRecord.push(item.email);
    eachRecord.push(item.dateOfBirth);
    eachRecord.push(
      getDescription(record[`country`], metadata.data.countryList, "countryCode", "countryDescription"));
    eachRecord.push(
      getDescription(record[`state`],
        metadata.data.countryList.find(i => i.countryCode === item.country)?.stateList || [],
        "stateCode", "stateDescription"));
    eachRecord.push(
      getDescription(record[`city`],
        metadata.data.countryList.find(i => i.countryCode === item.country)?.stateList.find(
          state => state.stateCode === item.state)?.cityList || [],
          "cityCode", "cityDescription"));
    eachRecord.push(getDescription(
      record[`department`],
      metadata.data.departmentList,
      "divisionCode",
      "divisionName"));
    eachRecord.push(
      getDescription(
        record[`storeBranch`],
        metadata.data.storeBranchList,
        "storeBranchCode",
        "storeBranchDescription"));
    eachRecord.push(
      getDescription(record[`skillType`], metadata.data.skillTypeList, "skillTypeCode", "skillTypeDescription"));
    eachRecord.push(
      getDescription(record[`pfApplicability`], metadata.data.pfApplicabilityList, "pfApplicabilityCode", "pfApplicability"));
    eachRecord.push(item.expectedDOJ);
    eachRecord.push(item.clientCompensation);
    eachRecord.push(
      getDescription(record[`compensationUOM`], metadata.data.compensationUOMList, "compensationUOMCode", "compensationUOMDescription"));
    eachRecord.push(
      getDescription(record[`offerProcessApplicable`], offerProcessApplicable, "code", "value"));
    eachRecord.push(
      getDescription(record[`offerClientApproval`], offerProcessApplicable, "code", "value"));
    eachRecord.push(item.contractStartDate);
    eachRecord.push(item.contractEndDate);
    excelData.push(eachRecord);
  });
  return {
    validation: validationArray,
    column: dataColumns,
    excelData,
  };
};

export const getValidationList = (
  data: Metadata,
) => {
  const list: string[] = [];
  data.data.titleList.map((item: TitleList) => {
    list.push(item.titleDescription || "");
  });
  return list;
};

export const getPositionList = (data: Metadata) => {
  const list: string[] = [];
  data.data.positionList.map((item: PositionList) => {
    list.push(item.positionDescription || "");
  });
  return list;
};

export const getCountryList = (data: Metadata) => {
  const list: string[] = [];
  data.data.countryList.map((item: CountryList) => {
    list.push(item.countryDescription || "");
  });
  return list;
};

export const getGenderList = (data: Metadata) => {
  const list: string[] = [];
  data.data.genderList.map((item: GenderList) => {
    list.push(item.genderDescription || "");
  });
  return list;
};

export const getDepartmentList = (data: Metadata) => {
  const list: string[] = [];
  data.data.departmentList.map((item: DepartmentList) => {
    list.push(item.divisionName || "");
  });
  return list;
};

export const getStoreBranchList = (
  data: Metadata,
) => {
  const list: string[] = [];
  data.data.storeBranchList.map((item: StoreBranchList) => {
    list.push(item.storeBranchDescription || "");
  });
  return list;
};

export const getSkillTypeList = (data: Metadata) => {
  const list: string[] = [];
  data.data.skillTypeList.map((item: SkillTypeList) => {
    list.push(item.skillTypeDescription || "");
  });
  return list;
};

export const getPfApplicabilityList = (data: Metadata) => {
  const list: string[] = [];
  data.data.pfApplicabilityList.map((item: PFApplicability) => {
    list.push(item.pfApplicability || "");
  });
  return list;
};

export const getCompensationUOMList = (data: Metadata) => {
  const list: string[] = [];
  data.data.compensationUOMList.map((item: CompensationList) => {
    list.push(item.compensationUOMDescription || "");
  });
  return list;
};

export const getSBUList = (data: Metadata) => {
  const list: string[] = [];
  data.data.departmentList.map((item: DepartmentList) => {
    list.push(item.sBUName || "");
  });
  return list;
};

export const getProcessApplicabilityList = () => {
  const list: string[] = [];
  offerProcessApplicable.map((item: OfferProcessAplicableList) => {
    list.push(item.value || "");
  });
  return list;
};

export const getManipulatedData = (data: DetailListObj) => {
  const updateTableData: TableData[] = [];
  data.candidateList.map((item) =>
    updateTableData.push({
      id: item.id,
      applicantId: item.applicantID,
      title: item.titleDescription || text.NOT_APPLICABLE,
      firstName: item.firstName || text.NOT_APPLICABLE,
      lastName: item.lastName || text.NOT_APPLICABLE,
      position: item.positionDescription || text.NOT_APPLICABLE,
      email: item.emailID || text.NOT_APPLICABLE,
      address: item.addressLine1 || text.NOT_APPLICABLE,
      phone: item.contactNumber1 || text.NOT_APPLICABLE,
      dateOfBirth: moment(item.dateOfBirth).format(dateCodes.STD_DATE),
      aadharNo: item.identificationNumber1 || text.NOT_APPLICABLE,
      gender: item.genderDescription,
      storeBranch: item.storeBranchDescription,
      country: item.countryDescription,
      state: item.stateDescription,
      city: item.cityDescription,
      skillType: item.skillTypeDescription,
      sBu: item.sBUName,
      pfApplicability: item.pfApplicability,
      expectedDOJ: moment(item.expectedDOJ).format(dateCodes.STD_DATE),
      requiredBy: moment(item.requiredBy).format(dateCodes.STD_DATE),
      clientCompensation: item.clientCompensation,
      clientCompensationCurrency: item.clientCompensationCurrency,
      offerClientApproval: item.offerClientApprovalMandatory,
      offerProcessApplicable: item.offerProcessApplicable,
      compensationUOM: item.compensationUOMDescription,
      contactType: item.contactTypeDescription,
      department: item.departmentDescription,
      contractStartDate: moment(item.contractStartDate).format(dateCodes.STD_DATE),
      contractEndDate: moment(item.contractEndDate).format(dateCodes.STD_DATE),
      statusCode: item.currentStatusCode,
      batchId: item.batchCode,
      RFHId: item.rfhNumber,
    }),
  );
  return updateTableData;
};

export const isValidExcel = (value: any) => {
  const records = value.filter(
    (item: any) => item.pfApplicability === undefined &&
      item.compensationUOM === undefined &&
      item.storeBranch === undefined);
  return records.length ? true : false;
};

export const getDuplicateRecords = (value: any) => {
  const duplicateRecords: string[] = [];
  const uniqueRecords = new Map<string, number>();
  value.map((item: any) => {
    if(item.email) {
      if (!uniqueRecords.has(item.email)) {
        uniqueRecords.set(item.email, 1);
      } else {
        duplicateRecords.push(item.email);
      }
    }
  });
  return duplicateRecords;
};

export const getSaveRequestedParams = (
  newRequest: TableData[],
  selectedRowKey: string[],
  currentTab: string,
): CandidateDetailsList[] => {
  const requestedList: CandidateDetailsList[] = [];
  const key = currentTab === text.NEW_PROFILE ? "applicantId" : "id";
  newRequest.forEach(row => {
    selectedRowKey.filter(
      data => data === row[key],
    ).forEach(item => {
      const requestedObj: CandidateDetailsList = {
        actionType: currentTab === text.NEW_PROFILE ? "Maintain" : "Create",
        cityCode: row.city,
        clientCompensation: row.clientCompensation,
        clientCompensationCurrency: "INR",
        compensationUOMCode: row.compensationUOM,
        contactNumber1: row.phone,
        contactTypeCode: "C",
        contractStartDate: moment(row.contractStartDate).format(dateFormat),
        contractEndDate: moment(row.contractEndDate).format(dateFormat),
        countryCode: row.country,
        dateOfBirth: moment(row.dateOfBirth).format(dateFormat),
        departmentCode: row.department,
        emailID: row.email,
        expectedDOJ: moment(row.expectedDOJ).format(dateFormat),
        firstName: row.firstName,
        genderCode: row.gender,
        identificationNumber1: row.aadharNo,
        lastName: row.lastName,
        offerClientApprovalMandatoryCode: row.offerClientApproval,
        offerProcessApplicableCode: row.offerProcessApplicable,
        pfApplicabilityCode: row.pfApplicability,
        positionCode: row.position,
        requiredBy: row.requiredBy || moment(row.expectedDOJ).format(dateFormat),
        sBUCode: "NA",
        skillTypeCode: row.skillType,
        stateCode: row.state,
        storeBranchCode: row.storeBranch,
        titleCode: row.title,
        staffingTypeCode: "SOUR",
        // For action type maintain
        batchCode: row.batchId,
        rfhNumber: row.RFHId,
        applicantID: row.applicantId,
      };
      requestedList.push(requestedObj);
    });
  });
  return requestedList;
};

export const getNewProfileData = (data: DetailListObj) => {
  const updateTableData: TableData[] = [];
  data.candidateList.filter(item => item.currentStatusCode === "NP").map((item, index) =>
    updateTableData.push({
      id: index,
      applicantId: item.applicantID,
      title: item.titleCode || text.NOT_APPLICABLE,
      firstName: item.firstName || text.NOT_APPLICABLE,
      lastName: item.lastName || text.NOT_APPLICABLE,
      position: item.positionCode || text.NOT_APPLICABLE,
      email: item.emailID || text.NOT_APPLICABLE,
      address: item.addressLine1 || text.NOT_APPLICABLE,
      phone: item.contactNumber1 || text.NOT_APPLICABLE,
      dateOfBirth: item.dateOfBirth,
      aadharNo: item.identificationNumber1 || text.NOT_APPLICABLE,
      gender: item.genderCode,
      storeBranch: item.storeBranchCode,
      country: item.countryCode,
      state: item.stateCode,
      city: item.cityCode,
      skillType: item.skillTypeCode,
      sBu: item.sBUCode,
      pfApplicability: item.pfApplicabilityCode,
      expectedDOJ: item.expectedDOJ,
      requiredBy: item.requiredBy,
      clientCompensation: item.clientCompensation,
      clientCompensationCurrency: item.clientCompensationCurrency,
      offerClientApproval: item.offerClientApprovalMandatoryCode,
      offerProcessApplicable: item.offerProcessApplicableCode,
      compensationUOM: item.compensationUOMCode,
      contactType: item.contactTypeCode,
      department: item.departmentCode,
      contractStartDate: item.contractStartDate,
      contractEndDate: item.contractEndDate,
      statusCode: item.currentStatusCode,
      batchId: item.batchCode,
      RFHId: item.rfhNumber,
    }),
  );
  return updateTableData;
};

export const getUpdatedValue = (value: any) => {
  return value.filter((item: any) =>
    item.aadharNo ||
    item.city ||
    item.clientCompensation ||
    item.compensationUOM ||
    item.contractEndDate ||
    item.contractStartDate ||
    item.country ||
    item.dateOfBirth ||
    item.department ||
    item.email ||
    item.expectedDOJ ||
    item.firstName ||
    item.gender ||
    item.lastName ||
    item.offerClientApproval ||
    item.offerProcessApplicable ||
    item.pfApplicability ||
    item.phone ||
    item.position ||
    item.skillType ||
    item.state ||
    item.storeBranch ||
    item.title,
  );
};