import React, { useEffect, useState } from "react";
import { Button, Checkbox, Col, Empty, Row } from "antd";
import { addImage, crossImage, lineManagerImage } from "AssetHelper";
import LineManagerCard from "components/LineManagerCard/LineManagerCard";
import LineManagerFilter from "components/LineManagerFilter/LineManagerFilter";
import Loader from "components/Loader";
import Search from "components/Search";
import { showNotification } from "components/ShowNotification/ShowNotification";
import _ from "lodash";
import {
  EmployeeList,
  ExistingLineManagerList,
  LineManagerDetailsResponse,
  LineManagerMetadataResponse,
  NewLineManagerList,
  SaveEmployeeList,
  SaveLineManagerRequestBody,
} from "models/lineManager.data";
import { style } from "styles/Fonts";
import text from "text";
import {
  CLMGR_INTERNAL_ROLE,
  defaultLineManagerMetadata,
  defaultLineManagersAssociateList,
  initialFilterValues,
} from "./constant";
import LineManagerService from "./service";
import {
  DetailsType,
  FilterParams,
  MetadataTYpe,
  SaveRequestParams,
} from "./type";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/RootReducer";
import { ModuleType } from "screens/home/store/home/constants";

export default function LineManager() {
  const [lineManagerMetadata, setLineMangerMetadata] = useState<MetadataTYpe>({
    isLoading: true,
    metadata: defaultLineManagerMetadata,
  });

  const [
    lineManagerAssociateDetails,
    setLineManagerAssociateDetails,
  ] = useState<DetailsType>({
    isLoading: false,
    details: defaultLineManagersAssociateList,
  });

  const [filterValue, setFilterValue] = useState<FilterParams>(
    initialFilterValues
  );

  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(new Array(0).fill({}));
  const [selectedItems, setSelectedItems] = useState({});

  const { contextStore } = useSelector((state: ApplicationState) => state);

  useEffect(() => {
    getLineManagerMetadata();
  }, []);

  const getLineManagerMetadata = async () => {
    let metadata: LineManagerMetadataResponse = defaultLineManagerMetadata;
    try {
      setLineMangerMetadata({
        isLoading: true,
        metadata,
      });
      metadata = await LineManagerService.getLineMangerMetadata(
        CLMGR_INTERNAL_ROLE
      );
      setLineMangerMetadata({
        isLoading: false,
        metadata,
      });
    } catch (err) {
    } finally {
      setLineMangerMetadata({
        isLoading: false,
        metadata,
      });
    }
  };

  const getLineManagersAssociateList = async (value: string) => {
    let associateList: LineManagerDetailsResponse = defaultLineManagersAssociateList;
    try {
      setLineManagerAssociateDetails({
        isLoading: true,
        details: associateList,
      });
      associateList = await LineManagerService.getLineMangersAssociateData({
        internalRole: CLMGR_INTERNAL_ROLE,
        existingLineManagerCode: value,
      });
      associateList.data.employeeList?.map(
        (item: EmployeeList, index: number) => {
          item.id = index;
          return item;
        }
      );
      setLineManagerAssociateDetails({
        isLoading: false,
        details: associateList,
      });
    } catch (err) {
    } finally {
      setLineManagerAssociateDetails({
        isLoading: false,
        details: associateList,
      });
    }
  };

  const onChangeExistingLineManager = async (
    value: ExistingLineManagerList
  ) => {
    setFilterValue({
      newLineManager: "",
      effectiveFromDate: "",
      existingLineManager: value.existingLineManagerCode as string,
    });
    setSearchText("");
    getLineManagersAssociateList(value.existingLineManagerCode as string);
    setSelectedItems({});
    setSelectedRecord([]);
  };

  const onChangeNewLineManager = (value: NewLineManagerList) => {
    setFilterValue({
      ...filterValue,
      newLineManager: value.newLineManagerCode as string,
    });
    const updatedlineManagerAssociateDetails = _.cloneDeep(
      lineManagerAssociateDetails
    );
    const selectedItem: any = selectedItems;
    updatedlineManagerAssociateDetails?.details.data.employeeList
      ?.filter(item => selectedItem[item.id as number])
      .map((item: EmployeeList) => {
        item.lineManager = value.newLineManagerCode;
      });
    setLineManagerAssociateDetails(updatedlineManagerAssociateDetails);
  };

  const onChangeEffectiveFromDate = (value: string) => {
    setFilterValue({
      ...filterValue,
      effectiveFromDate: value,
    });
    const updatedlineManagerAssociateDetails = _.cloneDeep(
      lineManagerAssociateDetails
    );
    const selectedItem: any = selectedItems;
    updatedlineManagerAssociateDetails?.details.data.employeeList
      ?.filter(item => selectedItem[item.id as number])
      .map((item: EmployeeList) => {
        item.effectiveFromDate = value;
      });
    setLineManagerAssociateDetails(updatedlineManagerAssociateDetails);
  };

  const getSearchedRecords = () => {
    const associateList =
      lineManagerAssociateDetails.details.data.employeeList || [];
    if (!searchText) return associateList;
    return associateList.filter(item => {
      return item.employeeName
        ?.toLowerCase()
        .includes(searchText.toLowerCase());
    });
  };

  const selectAll = () => {
    const addRecords: SaveRequestParams[] = [];
    setSelectedItems({});
    if (
      lineManagerAssociateDetails?.details?.data.employeeList?.length !==
      selectedRecord.length
    ) {
      const selectedItem: any = selectedItems;
      const updatedEmployeeList = _.cloneDeep(getSearchedRecords());
      if (filterValue.newLineManager || filterValue.effectiveFromDate) {
        updatedEmployeeList?.map((item: EmployeeList) => {
          item.lineManager =
            filterValue.newLineManager || filterValue.existingLineManager;
          item.effectiveFromDate =
            filterValue.effectiveFromDate || item.effectiveFromDate;
        });
      }
      updatedEmployeeList?.map(data => {
        addRecords.push({
          id: data.id as number,
          employeeCode: data.employeeCode as string,
          newEffectiveFromDate: data.effectiveFromDate as string,
          newLineManagerCode: data.lineManager as string,
        });
        selectedItem[data.id as number] = true;
        setSelectedItems(selectedItem);
        lineManagerAssociateDetails.details.data.employeeList = _.cloneDeep(
          updatedEmployeeList
        );
        setLineManagerAssociateDetails(lineManagerAssociateDetails);
      });
    } else {
      setSelectedItems({});
      setSelectedRecord([]);
    }
    setSelectedRecord(addRecords);
  };

  const toggleSelection = (values: SaveRequestParams) => {
    const selectedItem: any = selectedItems;
    if (!selectedItem[values.id]) {
      if (!selectedRecord.find(item => item.id === values.id)) {
        const data = [...selectedRecord];
        data.push(values);
        selectedItem[values.id] = true;
        if (filterValue.newLineManager || filterValue.effectiveFromDate) {
          const updatedAsssociateDetails = _.cloneDeep(
            lineManagerAssociateDetails
          );
          updatedAsssociateDetails?.details.data.employeeList
           ?.map((item: EmployeeList) => {
             if(item.id === values.id){
              item.lineManager =
                filterValue.newLineManager || filterValue.existingLineManager;
              item.effectiveFromDate =
                filterValue.effectiveFromDate || item.effectiveFromDate;
             }
            });
          setLineManagerAssociateDetails(updatedAsssociateDetails);
        }
        setSelectedRecord(data);
      }
    } else {
      const index = selectedRecord.indexOf(
        selectedRecord.find(item => values.id === item.id)
      );
      const updatedData = [...selectedRecord];
      updatedData.splice(index, 1);
      delete selectedItem[values.id];
      setSelectedRecord(updatedData);
    }
  };

  const onAssociateLineManagerChange = (value: string, empCode: string) => {
    const updatedlineManagerAssociateDetails = _.cloneDeep(
      lineManagerAssociateDetails
    );
    updatedlineManagerAssociateDetails?.details.data.employeeList
      ?.filter((item: EmployeeList) => item.employeeCode === empCode)
      .map((item: EmployeeList) => {
        item.lineManager = value;
      });
    setLineManagerAssociateDetails(updatedlineManagerAssociateDetails);
  };

  const onAssociateEffectiveFromDateChange = (
    value: string,
    empCode: string
  ) => {
    const updatedlineManagerAssociateDetails = _.cloneDeep(
      lineManagerAssociateDetails
    );
    updatedlineManagerAssociateDetails?.details.data.employeeList
      ?.filter((item: EmployeeList) => item.employeeCode === empCode)
      .map((item: EmployeeList) => {
        item.effectiveFromDate = value;
      });
    setLineManagerAssociateDetails(updatedlineManagerAssociateDetails);
  };

  const submit = async () => {
    const employeeList: SaveEmployeeList[] = [];
    lineManagerAssociateDetails?.details.data.employeeList?.map(row => {
      selectedRecord
        .filter(item => item.employeeCode === row.employeeCode)
        .map(() => {
          employeeList.push({
            employeeCode: row.employeeCode,
            newEffectiveFromDate: row.effectiveFromDate,
            newLineManagerCode:
              row.lineManager || filterValue.existingLineManager,
          });
        });
    });
    const postLineManagerRequestParams: SaveLineManagerRequestBody = {
      existingLineManagerCode: filterValue.existingLineManager,
      internalRole: CLMGR_INTERNAL_ROLE,
      employeeList,
    };
    setIsSaveLoading(true);
    try {
      await LineManagerService.postLineManagerData(
        postLineManagerRequestParams
      );
      getLineManagersAssociateList(filterValue.existingLineManager);
      setSelectedRecord([]);
      setSelectedItems({});
      showNotification({
        message: text.SUCCESS,
        description: text.SUBMIT_SUCCESSFULLY,
        type: text.SUCCESS,
      });
      setFilterValue({
        ...filterValue,
        newLineManager: "",
        effectiveFromDate: "",
      });
      setSearchText("");
      setIsSaveLoading(false);
    } catch (err) {
    } finally {
      setIsSaveLoading(false);
    }
  };

  const getAssociateList = () => {
    return lineManagerAssociateDetails?.details.data.employeeList?.length !==
      0 ? (
      <Row type="flex" gutter={[24, 24]}>
        {getSearchedRecords().map((item, index) => {
          return (
            <Col key={index} sm={{ span: 24 }} lg={{ span: 8 }}>
              <LineManagerCard
                data={item}
                lineManagerList={
                  lineManagerMetadata.metadata.data.newLineManagerList || []
                }
                select={toggleSelection}
                selectedItems={selectedItems}
                onLineManagerChange={onAssociateLineManagerChange}
                onEffectiveFromDateChnage={onAssociateEffectiveFromDateChange}
                selectedFilter={filterValue}
                lineManagerValue={filterValue.newLineManager}
              />
            </Col>
          );
        })}
      </Row>
    ) : (
      <Row className="bg-white w-100">
        {lineManagerAssociateDetails.isLoading ? (
          <Row className="m-9">
            <Loader />
          </Row>
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}
      </Row>
    );
  };

  const isSelectAllChecked = () => {
    const employeeList = getSearchedRecords();
    return (
      employeeList.length !== 0 && employeeList.length === selectedRecord.length
    );
  };

  const onSearchTextChange = (text: string) => {
    setSearchText(text);
    setSelectedItems({});
    setSelectedRecord([]);
  };

  if (!contextStore.allowedActivities[ModuleType.LINE_MANAGER_CR])
    return <p>{text.UNAUTHORIZED_VIEW}</p>;

  return (
    <Row>
      {lineManagerMetadata.isLoading ? (
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="min-vh-content">
          <Loader />
        </Row>
      ) : (
        <Row className="p-5">
          <Row className="pb-2 ff-secondary" style={style.large} type="flex">
            <img src={lineManagerImage} className="pr-3 h-6" />
            <Row>{text.LINE_MANAGER_CHANGE_REQUEST}</Row>
          </Row>
          <LineManagerFilter
            list={lineManagerMetadata?.metadata.data}
            onExistingLineManagerChange={onChangeExistingLineManager}
            onNewLineManagerChange={onChangeNewLineManager}
            onEffectiveFromDateChange={onChangeEffectiveFromDate}
            selectedValues={filterValue}
            onSubmit={submit}
            isLoading={isSaveLoading}
            selectedRecordLength={selectedRecord.length}
          />
          <Row type="flex" align="middle" justify="space-between">
            <Search
              placeholder={text.SEARCH_ASSOCIATES_NAME}
              data={[]}
              width={200}
              value={searchText}
              getSearchedValue={onSearchTextChange}
            />
            <Row type="flex">
              <Button style={{ border: "1px solid #31b549" }}>
                <a
                  href="http://ats.adecco.in/"
                  target="_blank"
                  style={{ color: "#31b549" }}>
                  <img
                    src={addImage}
                    style={{ height: "0.8rem", paddingRight: "2px" }}
                  />{" "}
                  {text.ADD_NEW_LINE_MANAGER}
                </a>
              </Button>
              <Button
                className="c-primary ml-8"
                style={{ border: "1px solid red" }}>
                <a href="http://ats.adecco.in/" target="_blank">
                  <img src={crossImage} className="h-4" />{" "}
                  {text.REMOVE_LINE_MANAGER}
                </a>
              </Button>
            </Row>
          </Row>
          {filterValue.newLineManager &&
          <Row className="mt-2">{text.ASSOCIATE_TO_BE_TRANSFERRED}</Row>}
          <Checkbox
            onChange={selectAll}
            className="fw-bold my-3"
            disabled={
              !lineManagerAssociateDetails?.details.data.employeeList?.length
            }
            checked={isSelectAllChecked()}>
            {text.SELECT_ALL}
          </Checkbox>
          {getAssociateList()}
        </Row>
      )}
    </Row>
  );
}
