import {
  leaveImage,
  timesheetImage,
  dashboardApproveImage,
  dashboardPendingImage,
  dashboardTotalActionsImage,
  dashboardTotalImage,
  dashboardTakenImage,
} from "../../AssetHelper";
import text from "text";

export interface DashboardList {
  feature: string;
  image: string;
  data: CardItem[];
}

export interface CardItem {
  title: string;
  value: string;
  icon: string;
  color: string;
}

const totalRequestColor = "#00b6ef";
const pendingColor = "#fbb517";
const actionTakenColor = "#02af9b";

export let leaveData: CardItem[] = [
  {
    title: text.TOTAL_REQUESTS,
    value: text.ALL,
    icon: dashboardTotalActionsImage,
    color: totalRequestColor,
  },
  {
    title: text.PENDING,
    value: text.PENDING_CODE,
    icon: dashboardPendingImage,
    color: pendingColor,
  },
  {
    title: text.ACTION_TAKEN,
    value: text.ACTION_TAKEN_CODE,
    icon: dashboardApproveImage,
    color: actionTakenColor,
  },
];

export let reimbursementData: CardItem[] = [
  {
    title: text.TOTAL_REQUESTS,
    value: text.ALL.toUpperCase(),
    icon: dashboardTotalImage,
    color: totalRequestColor,
  },
  {
    title: text.PENDING,
    value: text.PENDING_CODE,
    icon: dashboardPendingImage,
    color: pendingColor,
  },
  {
    title: text.ACTION_TAKEN,
    value: text.ACTION_TAKEN_CODE,
    icon: dashboardApproveImage,
    color: actionTakenColor,
  },
];

export let timesheetData: CardItem[] = [
  {
    title: text.TOTAL_REQUESTS,
    value: text.ALL,
    icon: dashboardTotalImage,
    color: totalRequestColor,
  },
  {
    title: text.PENDING,
    value: text.PENDING_CODE,
    icon: dashboardPendingImage,
    color: pendingColor,
  },
  {
    title: text.ACTION_TAKEN,
    value: text.ACTION_TAKEN_CODE,
    icon: dashboardApproveImage,
    color: actionTakenColor,
  },
];

export let dashboardList: DashboardList[] = [
  {
    feature: text.LEAVE,
    image: leaveImage,
    data: leaveData,
  },
  {
    feature: text.TIMESHEET,
    image: timesheetImage,
    data: timesheetData,
  },
];

export interface DashboardList {
  feature: string;
  image: string;
  data: CardItem[];
}

export const employeeInternalCode = text.APR;

export const CLMGR_INTERNAL_CODE = "CLMGR";