export default {
  APPROVE: "Approve",
  REJECTED: "Rejected",
  APPROVED: "Approved",
  PENDING: "Pending",
  REIMBURSEMENT: "Reimbursement",
  TIMESHEET: "Timesheet",
  ATTENDANCE: "Attendance",
  ATTENDANCES: "Attendances",
  LEAVE: "Leave",
  AUTHORIZE: "Authorize",
  REJECT: "Reject",
  SELECT: "Select",
  AUTHORIZED: "Authorized",
  ALL: "All",
  ARE_YOU_SURE: "Are you sure?",
  TIMESHEETS: `Timesheets`,
  REMBUIRSEMENTS: `Rembuirsements`,
  LEAVES: "Leaves",
  REJECTED_MESSAGE: `You have Rejected`,
  APPROVED_MESSAGE: `You have Approved`,
  REJECTED_REIMBURSEMENT: `You have Rejected Reimbursement`,
  APPROVED_REIMBURSEMENT: `You have Approved Reimbursement`,
  ACTION_TAKEN: "Action Taken",
  ASSOCIATE_NAME: "Associate Name",
  LEAVE_TYPE: "Leave Type",
  FROM_DATE: "From Date",
  TO_DATE: " To Date",
  NO_OF_DAYS: "No of days",
  EXECPTION: "Exception",
  REMARKS: "Remarks",
  STATUS: "Status",
  ACTION: "Action",
  TOTAL_REQUESTS: "Total Requests",
  ACTION_PENDING: "Action Pending",
  FIRST_SESSION: "MSES",
  SECOND_SESSION: "NSES",
  WHOLE_DAY: "WDAY",
  APR: "APR",
  IN_TIME: "In Time",
  OUT_TIME: "Out Time",
  TOTAL_HOURS_CLOCKED: "Total Hrs Clocked",
  BREAK_IN_MINS: "Break (in Min)",
  REJECT_TIMESHEET: "rej_tmsht",
  APPROVE_TIMESHEET: "aprv_tmsht",
  GET_TIMESHEET_ACTION: "get_aprv_res_dtls",
  EXPENSE_ID: "Expense Id",
  EXPENSE_DESCRIPTION: "Expense Description",
  DATE: "Date",
  REQUESTED_AMOUNT: "Requested Amount",
  AUTHORIZED_AMOUNT: "Authorized Amount",
  PENDING_CODE: "PEND",
  ACTION_TAKEN_CODE: "ACTD",
  RUPEE_SYMBOL: "\u20B9",
  FIRST_SESSION_DESC: "First Session",
  SECOND_SESSION_DESC: "Second Session",
  WHOLE_DAY_DESC: "Whole Day",
  UNAUTHORIZED_VIEW: "You are not authorized to View this page...",
  FILE_ATTACHMENT: "File Attachment",
  DOWNLOAD: "Click to Download",
  LOADING_DOWNLOAD: "DOWNLOADING DOCUMENT...",
  CLIENT_RELATIVEPATH_KEY: "USERPIC",
  LEAVE_RELATIVEPATH_KEY: "LVMGT",
  REIMBURSEMENT_RELATIVEPATH_KEY: "REIMEXP",
  ASSOCIATE_RELATIVEPATH_KEY: "EIONBREC",
  ALERT: "Alert",
  NO_FILE: "File is not Available",
  NOT_ABLE_TO_DOWNLOAD: "Not able to download the file.",
  NA: "Not Applicable",
  NOT_AVAILABLE: "Not Available",
  BULK_TIMESHEET: "Bulk Timesheet",
  PRESENT: "P - Present",
  LOSS_OF_PAY: "LOP - Loss Of Pay",
  SICK_LEAVE: "SL - Sick Leave",
  EARNED_LEAVE: "EL - Earned Leave",
  CASUAL_LEAVE: "CL - Casual Leave",
  COMP_OFF: "Comp-off - Compensatory Leave",
  ADD_ASSOSIATE: "+ Add Associate",
  GET_DETAILS: "Get Details",
  PAYROLL_PERIOD: "PayRoll Period",
  ATTENDANCE_CYCLE: "Attendance Cycle",
  ATTENDANCE_TYPE: "Attendance type",
  CHOOSE_LINE_MANAGER: "Choose Line Manager",
  SELECT_ASSOCIATE: "Select Associate",
  S_NO: "S.NO",
  EMPLOYEE_CODE: "Employee Code",
  EMPLOYEE_NAME: "Employee Name",
  VALUE: "Value",
  DOCUMENT_ATTACHMENT: "Document Attachment",
  SUBMIT: "Submit",
  OTHER_INPUTS: "Other Inputs",
  REQUESTED: "Requested ",
  PROCESSED: "Processed ",
  INVOICED: "Invoiced ",
  DOWNLOAD_EXCEL: "Download Excel",
  UPLOAD_EXCEL: "Upload Excel",
  ADD: "Add",
  INPUT_TYPE: "Input Type",
  PROCESS_PERIOD: "Process Period",
  ADD_NEW: "Add New ",
  SUBMIT_SUCCESSFULLY: "Submitted Successfully",
  INVOICED_COUNT: "invoicedCount",
  NEW_COUNT: "newCount",
  PROCESSED_COUNT: "processedCount",
  REQUESTED_COUNT: "requestedCount",
  ATTACH_DOCUMENT: "Attach Document",
  REQUIRED_FIELD: "Please select ",
  BULK_PAYROLL_MODULE_CODE: "BULKNONREC",
  UPLOAD_FILE: "Upload fie",
  DOWNLOAD_ATTACHMENT: "Download Attachment",
  UPLOADING_DOCUMENT: "UPLOADING DOCUMENT...",
  SUCCESS: "Success",
  EXCEL: "Excel",
  FILE: "File",
  ERROR: "Error",
  ERROR_MESSAGE_FOR_500: "Oops, something went wrong!",
  ERROR_MESSAGE_FOR_401: "You are not authorised to perform this action.",
  TRY_AGAIN: "Try Again",
  COMMENTS: "Comments",
  CANCELLED: "Cancelled",
  SAVE: "Save",
  SAVE_SUCCESSFULLY: "Saved Successfully",
  DRAFT: "Draft",
  NOT_CONFIRMED: "Non Confirmed",
  CONFIRMED: "Confirmed",
  INPUT_TYPE_REQUIRED: "Please select input type",
  PROCESS_PERIOD_REQUIRED: "Please select process period",
  INPUT_TYPE_AND_PROCESS_PERIOD_REQUIRED:
    "Please select input type and process period",
  WARNING: "Warning",
  PLEASE_SELECT_EXCEL_SHEET: "Please select an excel file",
  SELECT_CORRECT_VALUE: "Select Correct value",
  HOURS_SHOULD_BE_BETWEEN_0_TO_24: "Hours should be between 0 to 24",
  UPLOAD_EXCEL_MESSAGE:
    "Table data is updated, Please select the associates to submit.",
  TOTAL_EXPECTED_TO_JOIN: "Expected to Join",
  ONBOARDING_INITIATED: "Initiated",
  ONBOARDING_COMPLETED: "Completed",
  JOINED: "Joined",
  BGV: "BGV",
  ONBOARDING_DETAILS: "Onboarding",
  CANDIDATE_NAME: "Candidate Name",
  CANDIDATE_PROFILE_ID: "Candidate ProfileID",
  EMAIL: "Email",
  CANCEL: "Cancel",
  DOWNLOAD_MODAL_TABLE_DATA: "Download",
  START_MONTH: "Start Month",
  END_MONTH: "End Month",
  IN_PROGRESS: "In Progress",
  COMPLETED: "Completed",
  UPLOAD_SUCCESSFULL: "File Uploaded Successfully",
  UPLOAD_FAILED: "Failed to Upload File",
  DUPLICATE_ENTRY_MESSAGE:
    "has duplicate records. Please remove duplicate records and try again.",
  INVOICES: "Invoices",
  INVOICE_ID: "Invoice ID",
  INVOICE_ID_NUMBER: "Invoice ID / Number",
  INVOICE_DATE: "Invoive Date",
  INVOIVE_VALUE: "Invoive Value",
  MAIL_SENT: "Mail Sent",
  HARD_COPY_SENT: "Hard Copy Sent",
  POD_DETAILS: "POD Details",
  PAYMENT_RECIEVED: "Payment Recieved",
  SUPPORTING_DOCUMENTS: "Supporting Documents",
  PAYSLIP_RELATIVEPATH_KEY: "PAYSLIP",
  INVOICE_TYPE: "Invoice Type",
  DEPARTMENT: "Department",
  PO_NUMBER: "PO Number",
  ADVANCED_FILTER: "Advanced Filter",
  COUDENT_LOAD_IMAGES: "Coudn't load documents",
  PREVIEW_INVOICES: "Preview Invoices",
  DOWNLOAD_DOCUMENTS: "Download Documents",
  LINE_MANAGER_CHANGE_REQUEST: "Line Manager Change Request",
  LINE_MANAGER: "Line Manager",
  ADD_NEW_LINE_MANAGER: "Add New Line Manager",
  REMOVE_LINE_MANAGER: "Remove Line Manager",
  UPDATE: "Update",
  EXISTING_LINE_MANAGER: "Existing Line Manager",
  NEW_LINE_MANAGER: "New Line Manager",
  TRANSFER_TO: "Transfer To",
  ASSOCIATE_EMAIL_ID: "Associate Email Id",
  ASSOCIATE_CODE: "Associate Code",
  EFFECTIVE_FROM: "Effective From",
  SEARCH_ASSOCIATES_NAME: "Search Associates Name",
  SELECT_ALL: "Select All",
  SELECT_LINE_MANAGER: "Select Line Manager",
  NOT_APPLICABLE: "N/A",
  LOCATION: "Location",
  IMAGE: "Image",
  VIEW_IMAGE: "View Image",
  ATTENDANCE_RELATIVEPATH_KEY: "TIMEMGT",
  LOG_IN_IMAGE: "Log In Image",
  LOG_OUT_IMAGE: "Log Out Image",
  NET_PAY: "Net Pay",
  DETAILS: "Details",
  REFERENCE_NUMBER: "Reference Number",
  NET_PAY_DATE: "Net Pay Date",
  NET_PAY_VALUE: "Net Pay Value",
  EMPLOYEE_COUNT: "Employee Count",
  PROCESS_STATUS_DESCRIPTION: "Process Status Description",
  PROCESS_REMARKS: "Process Remarks",
  APPROVE_ALL: "Approve All",
  REJECT_ALL: "Reject All",
  EMPLOYEE_DETAILS: "Employee Details",
  DOWNLOAD_REPORT: "Download Report",
  DOWNLOAD_REPORT_PLACEHOLDER: "Select Report to Download",
  SELECT_MONTH: "Select Month",
  SELECT_YEAR: "Select Year",
  NO_DATA: "No Data Found for this Month, try with some other Month",
  NO_YEAR_DATA: "No Data Found for this Year, try with some other Year",
  INFO: "Info",
  NO_PREVIEW_IS_AVAILABLE: "No Preview is available.",
  CLICK_HERE_DOWNLOAD: "Click here to download",
  NO: "NO",
  CONTRACT_EXPIRED: "Contract Expired",
  ASSOCIATE_DETAILS: "Associate",
  CONTRACT_DETAILS: "Contract",
  CLIENT_CLRA_STATUS: "CLRA STATUS",
  ASSOCIATE_CONTRACT: "Associate Contract",
  CLIENT_CONTRACT: "Client Contract",
  APPROVE_ITEM: "APPROVE",
  REJECT_ITEM: "REJECT",
  TRANSFER_REQUEST: "Transfer Request",
  TRANSFER_ONBOARDING_INITIATED: "Transfer Onboarding Initiated",
  TRANSFER_ONBOARDING_IN_PROGRESS: "Transfer Onboarding In Progress",
  TRANSFER_ONBOARDING_COMPLETED: "Transfer Onboarding Completed",
  NEW_PROFILE: "New Profie",
  STATUS_TYPE: "Status Type",
  SEARCH: "Search",
  START_DATE: "Start Date",
  END_DATE: "End Date",
  ADD_NEW_DESIGNATION: "Add New Designation",
  RAISE_NEW_REQUEST: "Raise New Request",
  REQUESTED_TRANSFERS: "Requested Transfers",
  DOWNLOAD_TABLE: "Download Table",
  UPLOAD_INFO_DATA: "Upload Info Data",
  ADD_NEW_ROW: "Add New Row",
  TITLE: "Title",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  AADHAR_NO: "Aadhar No.",
  PHONE: "Phone",
  DOB: "DOB",
  ADDRESS: "Address",
  DELETE: "Delete",
  POSITION: "Position",
  GENDER: "Gender",
  STORE_BRANCH: "Store/Branch",
  COUNTRY: "Country",
  STATE: "State",
  CITY: "City",
  SKILL_TYPE: "Skill Type",
  SBU: "SBU",
  PF_APPLICABILITY: "PF Applicability",
  EXPECTED_DOJ: "Expected DOJ",
  CLIENT_COMPENSATION: "Client Compensation",
  CLIENT_COMPENSATION_CURRENCY: "Client Compensation Currency",
  COMPENSATION_UOM: "CTC/Gross/Net",
  CONTACT_TYPE: "Contact Type",
  OFFER_PROCESS_APPLICABLE: "Offer Process Applicable",
  OFFER_CLIENT_APPROVAL: "Approver Applicability",
  CONTRACT_START_DATE: "Contract Start Date",
  CONTRACT_END_DATE: "Contract End Date",
  VALID: "Valid",
  INVALID: "Invalid",
  BOTH: "Both",
  VAL: "VAL",
  INVAL: "INVAL",
  BOTH_VAL_INVAL: "BOTH",
  LEAVE_BALANCE: "Leave Balance",
  PAYOUT: "Payouts",
  INVOICE: "Invoice",
  PAYROLL_PROCESSED: "Payroll Processed",
  PAYROLL_REGULAR_DETAILS: "Payroll Regular Details",
  ADVANCE_SALARY: "Advance Salary",
  SANCTIONED: "Sanctioned",
  APPLIED_FEW_DAYS_AGO: "Applied Few Days Ago",
  APPROVE_AND_REJECT_FOR_NO_OF_INSTALLMENTS:
    "Approve and Reject for No of Installments",
  NO_OF_INSTALLMENTS: "No of Installments",
  AMOUNT_REQUESTED: "Amount Requested",
  YES: "Yes",
  RESIGNATION: "Resignation",
  SYSTEM_GENERATED_DATE: "System Generated Last Working Date",
  REQUESTED_LAST_WORKING_DATE: "Requested Last Working Date",
  NOTICE_PERIOD_WAIVER: "Notice Period Waiver",
  REASON_FOR_RESIGNATION: "Reason For Resignation",
  DOCUMENTS:"Documents",
  APPLIED: "Applied",
  AGO: "Ago",
  TOTAL_HEAD_COUNTS: "Head Counts",
  ADDED: "New Additions",
  RESIGNED_EXITED: "Exits",
  PAYROLL_DETAILS: "Payroll",
  DAYS_TO_CLOSURE: "days to closure",
  GETTING_EXPIRED: "getting expired",
  DOWNLOADING: "Downloading",
  CHOOSE_EMPLOYER_REASON: "Employer Reason",
  NO_DUE_CONFIRMATION: "No Due Confirmation",
  REQUIRED_NOTICE_PERIOD_WAVIER: "Please select notice period wavier",
  REASON_FOR_REJECTION: "Reason For Rejection",
  REQUIRED_BY: "Required By",
  UPLOAD_VALID_EXCEL: "Please upload a valid file",
  ATTENDANCE_RECEIVED: "Attendance Received",
  NET_PAY_GENERATED: "Net Pay Generated",
  PAYOUTS_COMPLETED: "Payouts Completed",
  INVOICE_GENERATED: "Invoice Generated",
  ASSOCIATE_TO_BE_TRANSFERRED: "Please select associates that needs to be transferred.",
};
