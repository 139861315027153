import { Col, List, Modal, Row, Typography } from "antd";
import Loader from "components/Loader";
import { dateCodes } from "date";
import { InvoiceList } from "models/invoices.data";
import moment from "moment";
import React, { ReactNode } from "react";
import text from "text";
import DocumentList from "./DocumentList";

const { Text } = Typography;

export const ListItem = (items: { label: string; value: ReactNode }) => (
  <List.Item>
    <Col span={12}>
      <Text type="secondary">{items.label}</Text>
    </Col>
    <Col span={12}>
      <Text strong>{items.value}</Text>
    </Col>
  </List.Item>
);

export const renderDownloadModal = (isLoading : boolean) => (
    <Modal visible={isLoading} footer={null} closable={false} >
      <Loader />
      <Row className="c-primary" type="flex" justify="center">
        {text.LOADING_DOWNLOAD}
      </Row>
    </Modal>
);

export const getObjectList = (invoiceDetails: InvoiceList) => {
  return [
    {
      label: text.INVOICE_ID_NUMBER,
      value: invoiceDetails.invoiceNumber
        ? invoiceDetails.invoiceNumber
        : "-",
    },
    {
      label: text.INVOICE_DATE,
      value: invoiceDetails.invoiceDate
        ? moment(invoiceDetails.invoiceDate).format(dateCodes.STD_DATE)
        : "-",
    },
    {
      label: text.INVOIVE_VALUE,
      value: `${invoiceDetails.invoiceCurrency} ${invoiceDetails.invoiceValue}`,
    },
    {
      label: text.SUPPORTING_DOCUMENTS,
      value: <DocumentList data={invoiceDetails.documentList || []} />,
    },
    {
      label: text.MAIL_SENT,
      value: invoiceDetails.emailSentToID ? invoiceDetails.emailSentToID : text.NO,
    },
    {
      label: text.HARD_COPY_SENT,
      value: invoiceDetails.hardCopySent ? invoiceDetails.hardCopySent : "-",
    },
    {
      label: text.POD_DETAILS,
      value: invoiceDetails.podDetails ? invoiceDetails.podDetails : "-",
    },
    {
      label: text.PAYMENT_RECIEVED,
      value: invoiceDetails.paymentReceived ? invoiceDetails.paymentReceived : "-",
    },
  ];
};