import { Button, Col, DatePicker, Row } from "antd";
import SearchableDropdown from "components/SearchableDropdown/SearchableDropdown";
import { dateCodes } from "date";
import { InvoiceTypeList, StatusList } from "models/invoices.data";
import { Moment } from "moment";
import React from "react";
import { fieldKeys } from "screens/Invoices/constants";
import text from "text";
import { GET_DETAILS_BUTTON_COLOR, GET_DETAILS_DISABLED_BUTTON } from "./constant";
import { InvoicesFilterProps } from "./type";

function InvoicesFilter(props: InvoicesFilterProps) {
  const {
    selectedValues,
    invoiceList,
    statusList,
    isGetListDisabled,
    onGetList,
    isLoading,
    onChangeDate,
    onChangeInvoiceType,
    onChnageStatus,
  } = props;

  const onFromDateChange = (date: Moment | null, dateString: string) => {
    onChangeDate(fieldKeys.FROM_DATE, date);
  };

  const onToDateChange = (date: Moment | null, dateString: string) => {
    onChangeDate(fieldKeys.TO_DATE, date);
  };

  const isStartDateDisabled = (date: Moment | null) => {
    const { invoiceToDate } = selectedValues;
    if (date && invoiceToDate && date > invoiceToDate) return true;
    return false;
  };

  const isEndDateDisabled = (date: Moment | null) => {
    const { invoiceFromDate } = selectedValues;
    if (date && invoiceFromDate && date < invoiceFromDate) return true;
    return false;
  };

  return (
    <Row type="flex" className="my-1" align="middle">
        <Col>
        <Row type="flex">
          <Col className="mr-2">
            <SearchableDropdown<InvoiceTypeList>
              list={invoiceList}
              label={text.INVOICE_TYPE}
              value={selectedValues.invoiceTypeCode}
              optionKeyExtractor={item => item.invoiceTypeCode}
              optionLableExtractor={item => item.invoiceType}
              onSelect={i => onChangeInvoiceType(i as InvoiceTypeList)}
            />
          </Col>
          <Col className="mr-2">
            <Row type="flex" className="m-md-0 my-1 mr-1">
              <div className="w-100 w-md-auto my-2 mr-2">
                {text.FROM_DATE} :
              </div>
              <DatePicker
                value={selectedValues.invoiceFromDate}
                onChange={onFromDateChange}
                disabledDate={isStartDateDisabled}
                format={dateCodes.STD_DATE}
              />
            </Row>
          </Col>
          <Col className="mr-2">
            <Row type="flex" className="m-md-0 my-1 mr-1">
              <div className="w-100 w-md-auto my-2 mr-2">{text.TO_DATE} :</div>
              <DatePicker
                value={selectedValues.invoiceToDate}
                onChange={onToDateChange}
                disabledDate={isEndDateDisabled}
                disabled={!selectedValues.invoiceFromDate}
                format={dateCodes.STD_DATE}
              />
            </Row>
          </Col>
          <Col className="mr-2">
            <SearchableDropdown<StatusList>
              list={statusList}
              label={text.STATUS}
              value={selectedValues.statusCode}
              optionKeyExtractor={item => item.statusCode}
              optionLableExtractor={item => item.statusDescription}
              onSelect={i => onChnageStatus(i as StatusList)}
              allowClear
            />
          </Col>
        </Row>
      </Col>
      <Button
        type="primary"
        className="mx-3 my-2 c-white"
        onClick={onGetList}
        disabled={isGetListDisabled || isLoading}
        style={{
          backgroundColor: isGetListDisabled || isLoading
            ? GET_DETAILS_DISABLED_BUTTON
            : GET_DETAILS_BUTTON_COLOR,
          borderColor: isGetListDisabled || isLoading
            ? GET_DETAILS_DISABLED_BUTTON
            : GET_DETAILS_BUTTON_COLOR,
        }}
      >
        {text.GET_DETAILS}
      </Button>
    </Row>
  );
}

export default InvoicesFilter;
