import React from "react";
import { Spin, Row, Icon } from "antd";

export default function Loader() {
  return (
    <Row type="flex" justify="center">
      <Spin size="large" indicator={<Icon type="loading" />} />
    </Row>
  );
}
