/**
 * Contains interfaces for all redux states
 */
import { AuthResponse } from "./Auth.data";

export enum AUTH_STATUS {
  PENDING,
  SUCCESS,
  FAIL,
}
export interface AuthState {
  loading: boolean;
  error: Error | null;
  authDetails: AuthResponse | undefined;
  authStatus: AUTH_STATUS;
  userProjects: string[];
}
