import React from "react";
import { downloadFile } from "AssetHelper";
import { IDownloadExcelProps } from "./type";
import { downloadExcel } from "./helper";
import { noop } from "lodash";

const DownloadSheet = (props: IDownloadExcelProps) => {
  return (
    <div
      className={
        props.isDisable ? "m-2 cursor-not-allowed" : "m-2 cursor-pointer"
      }
      onClick={
        props.isDisable
          ? noop
          : () =>
              props.data.length &&
              downloadExcel(
                props.downloadData.column,
                props.data,
                props.fileNameWithoutExtension,
                props.downloadData.excelData,
                props.downloadData.validation,
                props.inputList,
                props.positionList,
                props.genderList,
                props.storeBranchList,
                props.countryList,
                props.skillTypeList,
                props.pfApplicabilityList,
                props.compensationUOMList,
                props.processApplicabilityList,
                props.departmentList,
              )
        }
    >
      <img src={downloadFile} className="mr-2 h-7" alt="associate-icon" />
      <span className="align-middle c-black">{props.lable}</span>
    </div>
  );
};

export default DownloadSheet;
