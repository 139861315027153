import { LeaveList, Approverrequest } from "models/leave.data";
import { TableData } from "./type";

export function createPostObject(
  value: string,
  data:TableData|LeaveList,
  reason?: string,
) {
  const requestData: Approverrequest[] = [
    {
      Actiontype: value,
      Leaveapplicationnosearch:
        data.leaveApplicationNo,
      Requestoremployeecode:
        data.requestorEmployeeCode,
      Requestoremployeename:
        data.requestorEmployeeName,
      Leavefromdate: data.leaveFromDate,
      Leavefromsessioncode:
        data.leaveFromSessionCode,
      Leavefromsessiondescription:
        data.leaveFromSessionDescription,
      Leavetodate: data.LeaveToDate,
      Leavetosessioncode:
        data.leaveToSessionCode,
      Leavetosessiondescription:
        data.leaveToSessionDescription,
      Leavetypecode: data.leaveTypeCode,
      Leavetypedescription:
        data.leaveTypeDescription,
      Reasonforexception: reason,
      Rejectionremarks: reason,
    },
  ];
  return requestData;
}
