import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";
import { BulkTimesheetMetaDataParms, GetBulkAttendanceParams } from "./type";
import {
  BulkAttendanceMetaData,
  BulkAttendanceList,
  PostAttendanceRequestBody,
  PostAttendanceResponse,
} from "models/bulkAttendance.data";

export class BulkTimesheetService {
  public getBulkTimesheetMetaData(
    requestData: BulkTimesheetMetaDataParms
  ): Promise<BulkAttendanceMetaData> | BulkAttendanceMetaData {
    const service: ApiService = new ApiService(
      EndPoints.BULK_TIMESHEET_METADATA
    );
    return service.get({
      dynamicQueryParams: [{ internalRole: requestData.internalRole }],
    });
  }

  public getBulkAttendanceList(
    requestData: GetBulkAttendanceParams,
    statusCodeSearch: string,
    associateList?: string
  ): Promise<BulkAttendanceList> | BulkAttendanceList {
    const service: ApiService = new ApiService(EndPoints.BULK_TIMESHEET_DATA);
    return service.get({
      dynamicQueryParams: [
        { attTypeCodeSearch: requestData.attendanceType },
        { cycleFromDate: requestData.cycleFromDate },
        { cycleToDate: requestData.cycleToDate },
        { payrollCalendarCode: requestData.payrollCalendarCode },
        { payrollCode: requestData.payrollCode },
        { payrollLocationCode: requestData.payrollLocationCode },
        { paysetCode: requestData.paysetCode },
        { processPeriodCode: requestData.processPeriodCode },
        { processPeriodNumber: requestData.processPeriodNumber },
        { internalRole: requestData.internalRole },
        { employeeCodeSearch: requestData.employeeCode },
        { statusCodeSearch: statusCodeSearch },
        { lineManagerCodeSearch: requestData.lineManagerCode },
        { customTextInput4: associateList ? associateList : "" },
      ],
    });
  }

  public postAttendanceData(
    requestData: PostAttendanceRequestBody
  ): Promise<PostAttendanceResponse> | PostAttendanceResponse {
    const service: ApiService = new ApiService(
      EndPoints.POST_BULK_ATTENDANCE_DATA
    );
    return service.post({}, requestData);
  }
}

export default new BulkTimesheetService();
