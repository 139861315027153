import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "antd";
import { size } from "styles/Fonts";
import Loader from "components/Loader";

export default function DashboardCard(props: {
  title: string;
  value: number | string;
  hoverable?: boolean;
  titleStyle?: {};
  valueStyle?: {};
  alternateText?: string;
  valueColor?: string;
  icon: string;
}) {
  const [inFocus, setInFocus] = useState(false);
  const defaultTitleStyle = { fontSize: size.small, color: "#877a77" };
  const defaultValueStyle = {
    fontSize: size.h1,
    fontWeight: 550,
    color: props.valueColor,
  };
  const defaultCardStyle = {
    borderColor: inFocus ? "red" : "white",
  };

  return (
    <div
      className="cursor-pointer"
      onMouseOver={() => {
        setInFocus(true);
      }}
      onMouseOut={() => {
        setInFocus(false);
      }}>
      <Card className="m-1" style={defaultCardStyle}>
        <Row type="flex">
          <Col>
            <img
              src={props.icon}
              alt={props.alternateText}
              width="40"
              className="my-1"></img>
          </Col>
          <Col className="pl-3 pr-5">
            <Row
              style={props.valueStyle ? props.valueStyle : defaultValueStyle}>
              {props.value ? props.value : 0}
            </Row>
            <Row
              style={props.titleStyle ? props.titleStyle : defaultTitleStyle}
              className="ff-secondary">
              {props.title}
            </Row>
          </Col>
        </Row>
      </Card>
    </div>
  );
}
