import { all } from "redux-saga/effects";
import AuthSaga from "./auth/AuthSaga";
import LeaveSagas from "screens/leave/sagas";
import { ProfileSagas } from "../screens/profile/store";
import TimesheetSagas from "screens/timesheet/sagas";
import ReimbursementSagas from "screens/reimbursement/sagas";
import { DashboardSagas } from "../screens/dashboard";
import { ContextSagas } from "../screens/home/store";
import { AssociateSagas } from "screens/associate";

export default function* rootSaga() {
  yield all([
    ContextSagas(),
    AuthSaga(),
    LeaveSagas(),
    AssociateSagas(),
    ProfileSagas(),
    TimesheetSagas(),
    ReimbursementSagas(),
    DashboardSagas(),
  ]);
}
