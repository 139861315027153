import {
  InvoiceDocumentsRequestBody,
  InvoiceFilterCriteriaRequestBody,
  InvoiceListRequestBody,
  InvoiceMetaDataResponse,
  InvoiceMetaDataRequestBody,
  InvoiceFilterCriteriaResponse,
  InvoiceListResponse,
  InvoiceDocumentResponse,
} from "models/invoices.data";
import ApiService from "service/RootApiService";
import { EndPoints } from "store/model/ApiConfig.data";

class InvoiceServices {
  public getMetadata(
    params: InvoiceMetaDataRequestBody,
  ): Promise<InvoiceMetaDataResponse> | InvoiceMetaDataResponse {
    const service: ApiService = new ApiService(EndPoints.GET_INVOICES_METADATA);
    return service.get({ dynamicQueryParams: [{ ...params }] });
  }

  public getAdvancedMetadata(
    params: InvoiceFilterCriteriaRequestBody,
  ): Promise<InvoiceFilterCriteriaResponse> | InvoiceFilterCriteriaResponse {
    const service: ApiService = new ApiService(
      EndPoints.GET_INVOICES_ADVANCED_METADATA,
    );
    return service.get({ dynamicQueryParams: [{ ...params }] });
  }

  public getInvoiceList(
    params: InvoiceListRequestBody,
  ): Promise<InvoiceListResponse> | InvoiceListResponse {
    const service: ApiService = new ApiService(EndPoints.GET_INVOICES_LIST);
    return service.get({ dynamicQueryParams: [{ ...params }] });
  }

  public getInvoiceDocumentList(
    params: InvoiceDocumentsRequestBody,
  ): Promise<InvoiceDocumentResponse> | InvoiceDocumentResponse {
    const service: ApiService = new ApiService(
      EndPoints.GET_INVOICES_DOCUMENTS,
    );
    return service.get({ dynamicQueryParams: [{ ...params }] });
  }
}

export default new InvoiceServices();
