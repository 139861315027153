import {
  ReporteesList,
  RepoteesListStateType,
  Reportees,
} from "models/associate.data";
import { reducerWithInitialState } from "typescript-fsa-reducers";
import { AssociateActions } from "./actions";

const initialState: RepoteesListStateType = {
  loading: false,
  error: null,
  repoteesListDetails: [],
};

const associateReducer = reducerWithInitialState(initialState)
  .cases(
    [AssociateActions.getReporteesListList.started],
    (state: RepoteesListStateType) => ({
      ...state,
      error: null,
      loading: true,
    }),
  )
  .case(
    AssociateActions.getReporteesListList.done,
    (state: RepoteesListStateType, payload: { result: Reportees[] }) => ({
      ...state,
      error: null,
      loading: false,
      repoteesListDetails: payload.result,
    }),
  )
  .default(state => {
    return state;
  });

export default associateReducer;
