import React, { Fragment } from "react";
import moment from "moment";
import { Table, Row, Col } from "antd";
import { family_columns } from "screens/profile/constants/constants";
import { CardLayout } from "screens/profile/components/ProfileCard/CardLayout";
import {
  PersonalInformationDetails,
  ContactInformationListResponse,
  FamilyMembersInformationType,
} from "models/profile.data";
import { useSelector } from "react-redux";
import { style } from "styles/Fonts";
import { transferImage, callImage } from "AssetHelper";

export default function PersonalInfo() {
  const personalInformation: PersonalInformationDetails = useSelector(
    (state: any) => {
      return state.profileStore.personalInformation.data[0];
    },
  );

  const contacts: ContactInformationListResponse[] = useSelector(
    (state: any) => {
      return state.profileStore.contacts.data.contactInformationListResponse;
    },
  );

  const familyMembers: FamilyMembersInformationType[] = useSelector(
    (state: any) => {
      return state.profileStore.familyMembers.data.familyMembersInformation;
    },
  );

  const ProfileDetails = (profileData: any) => {
    return (
      <CardLayout>
        <Row type="flex" className="pb-1" gutter={[8, 16]}>
          <img alt="img" src={transferImage}></img>
          <span className="ml-2">
            <div style={style.normal}>
              {profileData.employeeName ||
                `${profileData.firstName} ${profileData.lastName}` ||
                "Name Not Available"}
            </div>
            <div style={style.xsmall} className="c-disabled">
              {profileData.titleDescription || "-"}
            </div>
          </span>
        </Row>
        <Row
          gutter={[32, 8]}
          type="flex"
          className="mt-3 pt-2"
          style={{ borderTop: "1px solid rgba(0, 0, 0, 0.05)" }}>
          <Col xs={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
            <div>
              <div style={style.xsmall} className="c-disabled">
                Date of Birth
              </div>
              {moment(profileData.dateofBirth).format("Do MMM YYYY") || "--"}
            </div>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
            <div>
              <div style={style.xsmall} className="c-disabled">
                Pan Number
              </div>
              {profileData.panNumber || "--"}
            </div>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
            <div>
              <div style={style.xsmall} className="c-disabled">
                Marital Status
              </div>
              {profileData.maritalDescription || "--"}
            </div>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
            <div>
              <div style={style.xsmall} className="c-disabled">
                Gender
              </div>
              {profileData.genderDescription || "--"}
            </div>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
            <div>
              <div style={style.xsmall} className="c-disabled">
                Nationality
              </div>
              {profileData.nationalityDescription || "--"}
            </div>
          </Col>
          <Col xs={{ span: 12 }} md={{ span: 8 }} lg={{ span: 4 }}>
            <div>
              <div style={style.xsmall} className="c-disabled">
                Ethinicity
              </div>
              {profileData.ethnicityDescription || "--"}
            </div>
          </Col>
        </Row>
      </CardLayout>
    );
  };

  const ContactDetails = (data: any) => {
    return (
      <Col xs={{ span: 24 }} md={{ span: 12 }} lg={{ span: 8 }}>
        <CardLayout>
          <Row type="flex" className="pb-1" gutter={[8, 16]}>
            <Col span={20}>
              <div style={style.normal}>{data.ContactTypeDescription}</div>
              <div style={style.xsmall} className="c-disabled mt-2">
                {data.addressLine1}
              </div>
              <div style={style.xsmall} className="c-disabled">
                {data.addressLine2}
              </div>
              <div style={style.xsmall} className="c-disabled">
                {data.stateDescription} (
                {data.countryDescription || data.isoCountryCode})
              </div>
              <div style={style.xsmall} className="c-disabled">
                {data.zipPostalCode}
              </div>
            </Col>
          </Row>
          <Row>
            <div
              className="mt-2 pt-1 c-disabled"
              style={{ borderTop: "1px solid rgba(0, 0, 0, 0.05)" }}>
              <span>
                <img width={12} alt="call-img" src={callImage} />{" "}
              </span>
              {data.mobile}
            </div>
          </Row>
        </CardLayout>
      </Col>
    );
  };

  const familyTableData = (data: FamilyMembersInformationType[]) => {
    const tableData = data.map(
      (item: FamilyMembersInformationType, index: Number) => {
        return {
          key: index,
          member_code: item.memberID,
          record_status: item.recordStatusDesc,
          first_name: item.firstName,
          middle_name: item.middleName,
          relationship: item.relationshipDescription,
          gender: item.gender,
          date_of_birth: item.dateofBirth,
          dependent: item.dependent,
        };
      },
    );
    return tableData;
  };

  return (
    <Fragment>
      <div>
        <p>Profile Details</p>

        {personalInformation && ProfileDetails(personalInformation)}
      </div>
      <div>
        <p className="mt-7">Contact Details</p>

        <Row type="flex" gutter={[8, 8]}>
          {contacts &&
            contacts.map(item => {
              return ContactDetails(item);
            })}
        </Row>
      </div>
      {/* <div>
                <p className="mt-7">Family Info</p>
                <Table
                    style={{
                        borderRadius: "3px",
                        boxShadow: "0 2px 8px 0 rgba(0, 0, 0, 0.12)",
                    }}
                    className="bg-white"
                    scroll={{ x: "auto" }}
                    pagination={false}
                    dataSource={familyMembers && familyMembers.length ? familyTableData(familyMembers) : []}
                    columns={family_columns}
                />
            </div> */}
    </Fragment>
  );
}
