import { size } from "styles/Fonts";
export const TextColorStyle = {
  color: "grey",
};

export const InputFieldStyle = {
  border: "1px Solid lightgrey",
  borderRadius: "0.4em",
};

export const employeeNameStyle = {
  fontSize: size.h3,
  fontWeight: 700,
}

export const appliedSalaryTimeStyle = {
  fontSize: size.medium,
}
