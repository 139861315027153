import {
  CandidatesList,
  OnboardingTableDetails,
  ContractList,
  EmployeeList,
  StoreBranchList,
  OnboardingMetaDataDetails,
  PayrollEmployeeList,
  AttendanceEmployeeList,
  NetpayList,
  InvoiceList,
} from "models/analytics.data";
import moment from "moment";
import { dateFormat } from "components/DashboardScreenDownloadReport/constant";

export function dataList(
  columnData: OnboardingMetaDataDetails,
  tableData: OnboardingTableDetails,
) {
  let excelData: any = [];
  let dateColumn: any = [];
  if (tableData?.candidatesList) {
    columnData?.data.apiFieldList.map(columnFields => {
      if (columnFields.parameterDataType.toLowerCase() === "date")
        dateColumn.push(columnFields.apiParameterName);
    });
    tableData?.candidatesList.map(
      (item: CandidatesList) => (
        dateColumn.map((dateTypeField: string) => {
          //@ts-ignore
          item[`${dateTypeField}`] = moment(item[`${dateTypeField}`]).format(
            dateFormat
          );
        }),
        excelData.push(item)
      )
    );
  } else if (tableData?.employeeList) {
    columnData?.data.apiFieldList.map(columnFields => {
      if (columnFields.parameterDataType.toLowerCase() === "date")
        dateColumn.push(columnFields.apiParameterName);
    });
    tableData?.employeeList.map(
      (item: EmployeeList) => (
        dateColumn.map((dateTypeField: string) => {
          //@ts-ignore
          item[`${dateTypeField}`] = moment(item[`${dateTypeField}`]).format(
            dateFormat
          );
        }),
        excelData.push(item)
      )
    );
  } else if (tableData?.storeBranchList) {
    columnData?.data.apiFieldList.map(columnFields => {
      if (columnFields.parameterDataType.toLowerCase() === "date")
        dateColumn.push(columnFields.apiParameterName);
    });
    tableData?.storeBranchList.map(
      (item: StoreBranchList) => (
        dateColumn.map((dateTypeField: string) => {
          //@ts-ignore
          item[`${dateTypeField}`] = moment(item[`${dateTypeField}`]).format(
            dateFormat
          );
        }),
        excelData.push(item)
      )
    );
  }
   else if (tableData?.contractList) {
    columnData?.data.apiFieldList.map(columnFields => {
      if (columnFields.parameterDataType.toLowerCase() === "date")
        dateColumn.push(columnFields.apiParameterName);
    });
    tableData?.contractList.map(
      (item: ContractList) => (
        dateColumn.map((dateTypeField: string) => {
          //@ts-ignore
          item[`${dateTypeField}`] = moment(item[`${dateTypeField}`]).format(
            "DD MMMM, YYYY"
          );
        }),
        excelData.push(item)
      )
    );
  } else if (tableData?.payrollEmployeeList) {
    columnData?.data.apiFieldList.map(columnFields => {
      if (columnFields.parameterDataType.toLowerCase() === "date")
        dateColumn.push(columnFields.apiParameterName);
    });
    tableData?.payrollEmployeeList.map(
      (item: PayrollEmployeeList) => (
        dateColumn.map((dateTypeField: string) => {
          //@ts-ignore
          item[`${dateTypeField}`] = moment(item[`${dateTypeField}`]).format(
            dateFormat
          );
        }),
        excelData.push(item)
      )
    );
  } else if (tableData?.attendanceEmployeeList) {
    columnData?.data.apiFieldList.map(columnFields => {
      if (columnFields.parameterDataType.toLowerCase() === "date")
        dateColumn.push(columnFields.apiParameterName);
    });
    tableData?.attendanceEmployeeList.map(
      (item: AttendanceEmployeeList) => (
        dateColumn.map((dateTypeField: string) => {
          //@ts-ignore
          item[`${dateTypeField}`] = moment(item[`${dateTypeField}`]).format(
            dateFormat
          );
        }),
        excelData.push(item)
      )
    );
  } else if (tableData?.netPayRegisterList) {
    columnData?.data.apiFieldList.map(columnFields => {
      if (columnFields.parameterDataType.toLowerCase() === "date")
        dateColumn.push(columnFields.apiParameterName);
    });
    tableData?.netPayRegisterList.map(
      (item: NetpayList) => (
        dateColumn.map((dateTypeField: string) => {
          //@ts-ignore
          item[`${dateTypeField}`] = moment(item[`${dateTypeField}`]).format(
            dateFormat
          );
        }),
        excelData.push(item)
      )
    );
  } else if (tableData?.invoiceList) {
    columnData?.data.apiFieldList.map(columnFields => {
      if (columnFields.parameterDataType.toLowerCase() === "date")
        dateColumn.push(columnFields.apiParameterName);
    });
    tableData?.invoiceList.map(
      (item: InvoiceList) => (
        dateColumn.map((dateTypeField: string) => {
          //@ts-ignore
          item[`${dateTypeField}`] = moment(item[`${dateTypeField}`]).format(
            dateFormat
          );
        }),
        excelData.push(item)
      )
    );
  }
  return excelData;
}
