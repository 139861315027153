import React, { Fragment } from "react";
import { Tabs, Row, Button } from "antd";
import { ITabMenu } from "screens/bulkAttendance/type";
import { IBulkTimesheetTabsProps } from "./type";
import BulkTimesheetTable from "components/BulkTimesheetTable/BulkTimesheetTable";
import text from "text";
import { actionTypeCodes } from "screens/bulkAttendance/constant";

function BulkTimesheetTab(props: IBulkTimesheetTabsProps) {
  const {
    tabMenu,
    detailList,
    attendanceType,
    inputType,
    listLoading,
    actionType,
    onTabChange,
    selectedRowKey,
    onChangeSelectedRow,
    onChangeData,
    tabDisabled,
    onUpload,
    onSubmit,
    currentPage,
    onPageChange,
    saveTimesheet,
    payrollAccess,
    selectedTab
  } = props;

  const { TabPane } = Tabs;
  const renderTabContent = () => {
    return tabMenu.map((item: ITabMenu, index: number) => {
      return (
        <TabPane
          key={item.key}
          tab={<span className="mr-1">{item.tabName}</span>}
          disabled={item.key !== "1" ? tabDisabled : false}></TabPane>
      );
    });
  };

  const submit = () => {
    onSubmit(text.SUBMIT);
  };

  const save = () => {
    onSubmit(text.SAVE);
  };

  return (
    <Row>
      <Tabs
        defaultActiveKey="1"
        activeKey={selectedTab ? selectedTab : "1"}
        className="c-secondary"
        animated={false}
        onChange={(activeKey: string) => onTabChange(activeKey)}>
        {renderTabContent()}
      </Tabs>
      <BulkTimesheetTable
        detailList={detailList}
        onUpload={onUpload}
        attendanceType={attendanceType}
        inputType={inputType}
        onChangeData={onChangeData}
        listLoading={listLoading}
        selectedRowKey={selectedRowKey}
        onChangeSelectedRow={onChangeSelectedRow}
        actionType={actionType}
        currentPage={currentPage}
        onPageChange={onPageChange}
        payrollAccess={payrollAccess}
      />
      {(detailList.empAttendanceList.length > 0 &&!(
        actionType === actionTypeCodes.CONFIRMED)) && (
        <Row className="py-5 w-100" type="flex" justify="end">
          <Button
            onClick={save}
            className={
              !(selectedRowKey.length > 0)
                ? "px-3 h-9 fw-bold mx-5"
                : "c-primary px-3 h-9 fw-bold mx-5"
            }
            loading={saveTimesheet.saveLoading}
            disabled={!(selectedRowKey.length > 0)}>
            {text.SAVE}
          </Button>
          <Button
            onClick={submit}
            type="primary"
            className={
              !(selectedRowKey.length > 0)
                ? "px-3 h-9 fw-bold"
                : "c-white px-3 h-9 fw-bold"
            }
            loading={saveTimesheet.submitLoading}
            disabled={!(selectedRowKey.length > 0)}>
            {text.SUBMIT}
          </Button>
        </Row>
      )}
    </Row>
  );
}

export default React.memo(
  BulkTimesheetTab,
  (prevState, nextState) => prevState === nextState
);
