import React from "react";
import "./styles/app.less";
import AppLayout from "common/layout/Layout";
import Home from "./screens/home/container/index";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { store, persistor } from "./store/Store";
import { PersistGate } from "redux-persist/es/integration/react";
import Loader from "./components/Loader";
import { ReactQueryConfigProvider } from "react-query";

const queryConfig = {
  queries: {
    retry: false,
    cacheTime: 10000,
    refetchOnWindowFocus: false,
  },
};

function App() {
  return (
    <Provider store={store}>
      <ReactQueryConfigProvider config={queryConfig}>
        <PersistGate loading={<Loader />} persistor={persistor}>
          <Router>
            <IntlProvider locale="en">
              <Switch>
                <Route path="/" exact redirectTo="/login" component={Home} />
                <Route path="/home" component={AppLayout} />
              </Switch>
            </IntlProvider>
          </Router>
        </PersistGate>
      </ReactQueryConfigProvider>
    </Provider>
  );
}

export default App;
