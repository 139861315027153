/**
 * This file will include settings for ENV, Test configs etc.
 */

enum BuildType {
  DEV = "dev",
  PROD = "prod",
}

export function getCurrentBaseUrl() {
  return CURRENT_BUILD === BuildType.DEV ? BASE_URLS.DEV : BASE_URLS.PROD;
}

export function getAuthRedirectUrl() {
  return process.env.REACT_APP_AUTH_REDIRECT_URL;
}

// TODO - Add to ENV file
const BASE_URLS = {
  DEV: process.env.REACT_APP_BASE_URL,
  PROD: "",
};

// TODO - Add to ENV file
const CURRENT_BUILD = BuildType.DEV;

const AUTH_TOKEN = process.env.REACT_APP_AUTH_TOKEN;
