import { Empty, List, Row } from "antd";
import React, { useEffect, useState } from "react";
import text from "text";
import { style } from "styles/Fonts";
import InvoicesFilter from "components/InvoicesFilters/InvoicesFilter";
import {
  AdvancedMetadata,
  FilterValues,
  InvoiceDetailsType,
  MetadataType,
} from "./type";
import {
  CLI_INTERNAL_ROLE,
  CLMGR_INTERNAL_ROLE,
  defaultAdvancedMetadata,
  defaultInvoiceDetails,
  defaultMetadata,
  initialFilterValues,
  QBC,
} from "./constants";
import InvoicesAdvancedFilter from "components/InvoicesFilters/InvoicesAdvancedFilter";
import {
  ClientPOList,
  DepartmentList,
  InvoiceFilterCriteriaRequestBody,
  InvoiceFilterCriteriaResponse,
  InvoiceListRequestBody,
  InvoiceListResponse,
  InvoiceMetaDataResponse,
  InvoiceTypeList,
  LineManagerList,
  StatusList,
} from "models/invoices.data";
import InvoiceServices from "./service";
import Loader from "components/Loader";
import InvoiceCard from "components/InvoiceCard/InvoiceCard";
import { invoceImage } from "AssetHelper";
import { useSelector } from "react-redux";
import { ApplicationState } from "store/RootReducer";
import { Moment } from "moment";
import { dateCodes } from "date";
import { ModuleType } from "screens/home/store/home/constants";

function Invoices() {
  const [filterValues, setFilterValues] = useState<FilterValues>(
    initialFilterValues
  );

  const [metadata, setMetadata] = useState<MetadataType>({
    isLoading: true,
    data: defaultMetadata,
  });

  const [advanceMetadata, setAdvancedMetadata] = useState<AdvancedMetadata>({
    isLoading: false,
    data: defaultAdvancedMetadata,
  });

  const [invoiceDetails, setInvoiceDetails] = useState<InvoiceDetailsType>({
    isLoading: false,
    data: defaultInvoiceDetails,
  });

  const { contextStore } = useSelector((state: ApplicationState) => state);

  const isInternalRoleClient = contextStore.userDetails.data.find(
    (item: any) => item.internalRole === CLI_INTERNAL_ROLE,
  )
    ? true
    : false;

  useEffect(() => {
    getMetaData();
  }, []);

  const getMetaData = async () => {
    let metaDataResponse: InvoiceMetaDataResponse = {
      data: defaultMetadata,
      message: "",
    };
    try {
      setMetadata({
        ...metadata,
        isLoading: true,
      });
      metaDataResponse = await InvoiceServices.getMetadata({
        internalRole: isInternalRoleClient
        ? CLI_INTERNAL_ROLE
        : CLMGR_INTERNAL_ROLE,
      });
      setMetadata({
        isLoading: false,
        data: metaDataResponse.data,
      });
    } catch (e) {
    } finally {
      setMetadata({
        data: metaDataResponse.data,
        isLoading: false,
      });
    }
  };

  const getInvoiceAdvancedMetaData = async () => {
    let advancedMetadataResponse: InvoiceFilterCriteriaResponse = {
      message: "",
      data: defaultAdvancedMetadata,
    };
    try {
      setAdvancedMetadata({
        ...advanceMetadata,
        isLoading: true,
      });
      const { invoiceFromDate, invoiceToDate } = filterValues;
      const advancedMetadataRequestBody: InvoiceFilterCriteriaRequestBody = {
        invoiceTypeCode: filterValues.invoiceTypeCode,
        internalRole: isInternalRoleClient
        ? CLI_INTERNAL_ROLE
        : CLMGR_INTERNAL_ROLE,
        invoiceFromDate: invoiceFromDate
          ? invoiceFromDate.format(dateCodes.TO_ISO_8601)
          : "",
        invoiceToDate: invoiceToDate
          ? invoiceToDate.format(dateCodes.TO_ISO_8601)
          : "",
        statusCode: filterValues.statusCode,
      };
      advancedMetadataResponse = await InvoiceServices.getAdvancedMetadata(
        advancedMetadataRequestBody
      );
      setAdvancedMetadata({
        isLoading: false,
        data: advancedMetadataResponse.data,
      });
    } catch (e) {
    } finally {
      setAdvancedMetadata({
        isLoading: false,
        data: advancedMetadataResponse.data,
      });
    }
  };

  const getInvoiceList = async () => {
    let invoiceListResponse: InvoiceListResponse = {
      data: defaultInvoiceDetails,
      message: "",
    };
    try {
      setInvoiceDetails({
        ...invoiceDetails,
        isLoading: true,
      });
      const { invoiceFromDate, invoiceToDate } = filterValues;
      const invoiceListRequestBody: InvoiceListRequestBody = {
        clientCode: metadata.data.clientCode,
        internalRole: isInternalRoleClient
          ? CLI_INTERNAL_ROLE
          : CLMGR_INTERNAL_ROLE,
        invoiceFromDate: invoiceFromDate
          ? invoiceFromDate?.format(dateCodes.TO_ISO_8601)
          : "",
        invoiceToDate: invoiceToDate
          ? invoiceToDate?.format(dateCodes.TO_ISO_8601)
          : "",
        invoiceTypeCode: filterValues.invoiceTypeCode,
        lineManagerCode: filterValues.lineManagerCode,
        statusCode: filterValues.statusCode,
        departmentCode: filterValues.departmentCode,
        clientPO: filterValues.clientPO,
      };
      invoiceListResponse = await InvoiceServices.getInvoiceList(
        invoiceListRequestBody
      );
      for (const item of invoiceListResponse.data.invoiceList) {
        const invoiceDocumentsResponse = await InvoiceServices.getInvoiceDocumentList(
          {
            clientCode: invoiceListResponse.data.clientCodeOutput,
            internalRole: isInternalRoleClient
              ? CLI_INTERNAL_ROLE
              : CLMGR_INTERNAL_ROLE,
            invoiceNumber: item.invoiceNumber,
          }
        );
        item.documentList = invoiceDocumentsResponse.data.documentList || [];
      }
      setInvoiceDetails({
        data: invoiceListResponse.data,
        isLoading: false,
      });
    } catch (e) {
    } finally {
      setInvoiceDetails({
        data: invoiceListResponse.data,
        isLoading: false,
      });
    }
  };

  const getAdvancedFilterAndInvoiceList = async () => {
    setInvoiceDetails({
      ...invoiceDetails,
      data: defaultInvoiceDetails,
    });
    filterValues.invoiceTypeCategory === QBC &&
      (await getInvoiceAdvancedMetaData());
    setFilterValues({
      ...filterValues,
      isAdvanceFilterVisible: filterValues.invoiceTypeCategory === QBC,
      clientPO: "",
      lineManagerCode: "",
      departmentCode: "",
    });
    await getInvoiceList();
  };

  const onChangeInvoiceType = (value: InvoiceTypeList) => {
    setFilterValues({
      ...filterValues,
      invoiceTypeCode: value.invoiceTypeCode,
      invoiceTypeCategory: value.invoiceCategory,
      departmentCode: "",
      lineManagerCode: "",
      clientPO: "",
    });
  };

  const onChangeStatus = (value: StatusList) => {
    setFilterValues({
      ...filterValues,
      statusCode: value ? value.statusCode : "",
    });
  };

  const onChangeDate = (key: string, value: Moment | null) => {
    const selectedValues = filterValues;
    // @ts-ignore
    selectedValues[key] = value;
    setFilterValues({ ...selectedValues });
    if (!filterValues.invoiceFromDate)
      setFilterValues({
        ...filterValues,
        invoiceToDate: null,
      });
  };

  const onLineManagerChange = (value: LineManagerList) => {
    setFilterValues({
      ...filterValues,
      lineManagerCode: value.lineManagerCode,
    });
  };

  const onDepartmentChange = (value: DepartmentList) => {
    setFilterValues({
      ...filterValues,
      departmentCode: value.departmentCode,
    });
  };

  const onPoNumberChange = (value: ClientPOList) => {
    setFilterValues({
      ...filterValues,
      clientPO: value.clientPO,
    });
  };

  const isGetListDisabled = () => {
    if (
      filterValues.invoiceTypeCode !== "" &&
      filterValues.invoiceFromDate !== null &&
      filterValues.invoiceToDate !== null
    )
      return false;
    return true;
  };

  const isAdvanceFilterSelected = () => {
    if (
      filterValues.lineManagerCode !== "" ||
      filterValues.departmentCode !== "" ||
      filterValues.clientPO !== ""
    )
      return false;
    return true;
  };

  if (!contextStore.allowedActivities[ModuleType.INVOICE])
    return <p>{text.UNAUTHORIZED_VIEW}</p>;

  return (
    <>
      {metadata.isLoading ? (
        <Row
          type="flex"
          justify="center"
          align="middle"
          className="min-vh-content">
          <Loader />
        </Row>
      ) : (
        <Row className="p-6">
          <Row className="pb-2 ff-secondary" style={style.large} type="flex">
            <img src={invoceImage} className="pr-3 h-6" />
            {text.INVOICES}
          </Row>
          <InvoicesFilter
            selectedValues={filterValues}
            invoiceList={metadata.data.invoiceTypeList}
            statusList={metadata.data.statusList}
            onGetList={getAdvancedFilterAndInvoiceList}
            isGetListDisabled={isGetListDisabled()}
            hideGetListButton={false}
            isLoading={advanceMetadata.isLoading || invoiceDetails.isLoading}
            onChangeDate={onChangeDate}
            onChangeInvoiceType={onChangeInvoiceType}
            onChnageStatus={onChangeStatus}
          />
          {filterValues.isAdvanceFilterVisible ? (
            advanceMetadata.isLoading ? (
              <Loader />
            ) : (
              <InvoicesAdvancedFilter
                selectedValues={filterValues}
                lineManagerList={advanceMetadata.data.lineManagerList}
                departmentList={advanceMetadata.data.departmentList}
                poNumberList={advanceMetadata.data.clientPOList}
                onGetList={getInvoiceList}
                isGetListDisabled={
                  isAdvanceFilterSelected() ||
                  invoiceDetails.isLoading ||
                  isGetListDisabled()
                }
                isLoading={advanceMetadata.isLoading}
                onLineManagerChange={onLineManagerChange}
                onDepartmentChange={onDepartmentChange}
                onPoNumberChange={onPoNumberChange}
              />
            )
          ) : null}
          {invoiceDetails.isLoading ? (
            <Row
              type="flex"
              justify="center"
              align="middle"
              className="min-vh-content">
              <Loader />
            </Row>
          ) : invoiceDetails.data.invoiceList.length ? (
            <List
              dataSource={invoiceDetails.data.invoiceList}
              renderItem={item => <InvoiceCard invoiceDetails={item} />}
            />
          ) : (
            <Row className="bg-white w-100">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
          )}
        </Row>
      )}
    </>
  );
}

export default Invoices;
