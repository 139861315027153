import React from "react";
import moment, { Moment } from "moment";
import { Row, Input, Icon, Dropdown, Menu, DatePicker, Button } from "antd";
import calender from "../../assets/Images/calenderIcon.svg";

export interface IFilterParams {
  status: string;
  startDate: Moment | null | undefined;
  endDate: Moment | null | undefined;
}

export interface IFilterProps {
  filterValues: IFilterParams;
  onSearchText: (searchText: string) => void;
  onChangeFilters: (filterValues: IFilterParams) => void;
  searchName: string;
  maxDate?: Moment;
}

const statusTypeList: { [key: string]: string } = {
  All: "All",
  PEND: "Pending",
  ACTD: "Action Taken",
};

export default (props: IFilterProps) => {
  const onFilterChange = (value: any) => {
    props.onChangeFilters({ ...props.filterValues, ...value });
  };

  const onSearchTextChange = (event: any) => {
    props.onSearchText(event.target.value);
  };

  const menu = (
    <Menu
      onClick={e => {
        onFilterChange({ status: e.key });
      }}>
      {Object.keys(statusTypeList).map((item: string) => (
        <Menu.Item key={item}>{statusTypeList[`${item}`]}</Menu.Item>
      ))}
    </Menu>
  );

  const locale = moment.updateLocale("en", {
    weekdaysMin: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"],
    monthsShort: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
  });

  const isStartDateDisabled = (date: Moment | null) => {
    if (props.maxDate && date && date.isAfter(props.maxDate, "day"))
      return true;
    const { startDate, endDate } = props.filterValues;
    if (date && endDate && date > endDate) return true;
    return false;
  };

  const isEndDateDisabled = (date: Moment | null) => {
    if (props.maxDate && date && date.isAfter(props.maxDate, "day"))
      return true;
    const { startDate } = props.filterValues;
    if (startDate && date && date < startDate) return true;
    return false;
  };

  return (
    <Row
      type="flex"
      className="px-2 py-4 py-md-2 justify-content-center justify-content-xl-between">
      <Row type="flex">
        <Row type="flex" justify="center" align="middle" className="m-2">
          <Input
            placeholder={"Search Name"}
            prefix={<Icon type="search" />}
            onInput={onSearchTextChange}
            value={props.searchName}
          />
        </Row>
        <Row type="flex" align="middle" className="m-md-0 m-2">
          <div className="w-100 w-md-auto m-2">Status Type:</div>
          <div className="w-100 w-md-auto">
            <Dropdown overlay={menu}>
              <Button>
                {`${statusTypeList[`${props.filterValues.status}`] || "All"}`}{" "}
                <Icon type="down" />
              </Button>
            </Dropdown>
          </div>
        </Row>
      </Row>
      <Row type="flex">
        <Row type="flex" align="middle" justify="center" className="m-2">
          <div className="m-2 w-100 w-md-auto">{"Start Date"}</div>
          <div className="w-100 w-md-auto">
            <DatePicker
              getCalendarContainer={(t: any) => t.parentNode as HTMLElement}
              dropdownClassName="custom-datepicker-dropdown"
              defaultValue={props.filterValues.startDate}
              value={props.filterValues.startDate}
              showToday={false}
              format={"DD/MM/YY"}
              locale={locale}
              suffixIcon={<img src={calender} />}
              disabledDate={isStartDateDisabled}
              onChange={date => {
                let filterValue: any = { startDate: date };
                if (!date) {
                  filterValue = { ...filterValue, endDate: date };
                }
                onFilterChange(filterValue);
              }}
            />
          </div>
        </Row>
        <Row type="flex" align="middle" justify="center" className="m-2">
          <div className="m-2 w-100 w-md-auto">{"End Date"}</div>
          <div className="w-100 w-md-auto">
            <DatePicker
              getCalendarContainer={(t: any) => t.parentNode as HTMLElement}
              dropdownClassName="custom-datepicker-dropdown"
              defaultValue={props.filterValues.endDate}
              value={props.filterValues.endDate}
              showToday={false}
              format={"DD/MM/YY"}
              locale={locale}
              disabledDate={isEndDateDisabled}
              disabled={!props.filterValues.startDate}
              suffixIcon={<img src={calender} />}
              onChange={date => onFilterChange({ endDate: date })}
            />
          </div>
        </Row>
      </Row>
    </Row>
  );
};
