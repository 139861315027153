import React from "react";
import { Result, Button, Row } from "antd";
import { IDisplayError } from "./type";
import text from "text";
import { getErrorMessage } from "screens/otherInput/helper";

export default (props: IDisplayError) => {
  const { errorCode, errorMsg, onTryAgain } = props;

  return (
    <Result
      status="warning"
      subTitle={
        <Row className="fw-bold c-black">
          {getErrorMessage(errorCode, errorMsg)}
        </Row>
      }
      extra={
        <Button type="link" onClick={onTryAgain}>
          {text.TRY_AGAIN}
        </Button>
      }
    />
  );
};
